import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
// @ts-ignore
import Helmet from 'react-helmet'
import { animated, useTransition } from 'react-spring'

import LandingPage from '../pages/landing/Landing'
import LoginPage from '../pages/login/Login'
import VerifyEmailPage from '../pages/verify-email/VerifyEmail'
import ResetPasswordPage from '../pages/reset-password/ResetPassword'
import NewPasswordPage from '../pages/new-password/NewPassword'
import UpdatePasswordPage from '../pages/update-password/UpdatePassword'
import Dashboard from '../pages/dashboard/Dashboard'
import RegisterPage from '../pages/register/Register'
import BackupCode from '../pages/backup-codes/BackupCode'
import ShareCard from '../pages/share-card/ShareCard'
import { Footer, TopLoadingBar, UpdateEmail } from '../components'
import JoinRequest from '../pages/join-request/JoinRequest'
import Authorize from '../pages/authorize/Authorize'
import ContextWrapper from '../utils/ContextWrapper'
import Decision from '../pages/decision/Decision'
import { StyleSheetManager } from 'styled-components'
import rtlPlugin from 'stylis-plugin-rtl'
import AppHeader from '../components/AppHeader/AppHeader'
import { StickyContainer } from 'react-sticky'
import VerificationCodePage from '../pages/verification-code/VerificationCodePage'
import { deleteFromStorage, writeStorage } from '@rehooks/local-storage'
import { useIntl } from 'react-intl'
import useSWR from 'swr'
import { apiService } from '../service/api/apiService'
import MandatoryFiedls from '../pages/mandatoryFields/MandatoryFields'
import { NoAuthRoute } from './NoAuthRoute'
import { AuthRoute } from './AuthRoute'

const Routing = () => {
  const intl = useIntl()
  const location = useLocation()

  const dir = intl.locale === 'ar' ? 'rtl' : 'ltr'
  const { data, error } = useSWR('validate', () => apiService.user.validate())
  useEffect(() => {
    if (data) {
      writeStorage('user', data)
    } else if (error) {
      deleteFromStorage('user')
      deleteFromStorage('token')
    }
  }, [data, error])

  const transitions = useTransition(location, location.pathname, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    document.body.style.direction = dir
  }, [dir])

  if (!data && !error) {
    return null
  }

  return (
    <StickyContainer>
      <StyleSheetManager stylisPlugins={dir === 'rtl' ? [rtlPlugin] : []}>
        <ContextWrapper>
          {transitions.map(({ item, props, key }) => (
            <animated.div key={key} style={props}>
              <>
                <Helmet>
                  <title>{intl.formatMessage({ id: 'pageInfo.title' })}</title>
                  <meta
                    property='og:title'
                    content={intl.formatMessage({ id: 'pageInfo.title' })}
                  />
                  <meta
                    property='og:description'
                    content={intl.formatMessage({ id: 'pageInfo.description' })}
                  />
                </Helmet>
                <TopLoadingBar />
                <AppHeader />
                <main>
                  <Switch location={item}>
                    <Route exact path='/' component={LandingPage} />
                    <Route path='/authorize' component={Authorize} />
                    <AuthRoute path='/decision' component={Decision} />
                    <AuthRoute
                      exact
                      path='/welcome'
                      render={() => <Dashboard isWelcome />}
                    />
                    <AuthRoute exact path='/dashboard' component={Dashboard} />
                    <AuthRoute
                      path='/mandatory-fields'
                      component={MandatoryFiedls}
                    />
                    <NoAuthRoute
                      path='/emailverify'
                      component={VerifyEmailPage}
                    />
                    <AuthRoute
                      path='/joinRequest/:id'
                      component={JoinRequest}
                    />
                    <NoAuthRoute
                      path='/passwordreset'
                      component={NewPasswordPage}
                    />
                    <AuthRoute
                      path='/updatepassword'
                      component={UpdatePasswordPage}
                    />
                    <AuthRoute path='/updatemail' component={UpdateEmail} />
                    <AuthRoute path='/qr-code' component={ShareCard} />
                    <NoAuthRoute
                      path='/verification-code'
                      render={() => <VerificationCodePage redirect />}
                    />
                    <NoAuthRoute
                      path='/reset-password'
                      component={ResetPasswordPage}
                    />
                    <NoAuthRoute path='/reset-tfa' component={BackupCode} />
                    <NoAuthRoute path='/login' component={LoginPage} />
                    <NoAuthRoute path='/register' component={RegisterPage} />
                    <Route
                      path='/.well-known/apple-app-site-association'
                      onEnter={() => window.location.reload()}
                    />
                    <Route
                      path='/.well-known/assetlinks.json'
                      onEnter={() => window.location.reload()}
                    />
                    <Redirect to='/' />
                  </Switch>
                </main>
                <Footer />
              </>
            </animated.div>
          ))}
        </ContextWrapper>
      </StyleSheetManager>
    </StickyContainer>
  )
}

export default Routing
