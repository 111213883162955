import { AxiosResponse } from 'axios'
import { axiosNoAuthIdentity, axiosIdentity } from './axiosInstances'

export const decodeUrl = (token: string) => {
  return axiosNoAuthIdentity.post(`/invite/decodeURL`, { token }).then(
    (
      res: AxiosResponse<{
        email: string
        firstName: string
        lastName: string
        expiry: string
      }>,
    ) => res.data,
  )
}

export const inviteMember = (email: string) => {
  return axiosIdentity
    .post(`/invite/`, { email })
    .then((response) => response.data)
}
