import React, { useContext, useEffect, useState } from 'react'
import queryString from 'query-string'
import { Redirect, useLocation } from 'react-router-dom'
import { apiService } from '../../service/api/apiService'
import { AuthFormView } from '../../components'
import { Password } from '../../components/TextInput/Password'
import { openToast } from '../../components/ToastMessage/ToastService'
import { removeLoginData } from '../../service/localStorage/userStorage'
import { AuthDataContext } from '../../utils/ContextWrapper'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { FormattedMessage, useIntl } from 'react-intl'
import { Form, StyledButton, Title } from './styles'

const NewPasswordPage = () => {
  const intl = useIntl()
  const location = useLocation()
  const token = queryString.parse(location.search).token as string
  const [password, setPassword] = useState('')
  const [redirect, setRedirect] = useState('')
  const [passwordStatus, setPasswordStatus] = useState(false)
  const [, setAuthData] = useContext(AuthDataContext)
  const [user] = useLocalStorage<IUser>('user')
  useEffect(() => {
    if (user) {
      setAuthData({ email: '', password: '' })
      apiService.auth.logout()
      removeLoginData()
    }
  }, [setAuthData, user])

  const handleResetPassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    apiService.auth
      .resetPassword(token, password)
      .then(() => {
        openToast({
          message: intl.formatMessage({ id: 'auth.resetPassword.success' }),
          variant: 'success',
        })
        setRedirect('/login')
      })
      .catch((e) => {
        openToast({
          message: intl.formatMessage({ id: 'errors.genericFail' }),
          variant: 'failure',
        })
      })
  }

  const handlePasswordChange = (val: string, status: boolean) => {
    setPassword(val)
    setPasswordStatus(status)
  }

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <AuthFormView>
      <Title>
        <FormattedMessage id='components.newPassword.title' />
      </Title>
      <Form onSubmit={handleResetPassword}>
        <Password
          key='password-reset'
          placeholder={intl.formatMessage({ id: 'auth.placeholder.password' })}
          handlePasswordChange={handlePasswordChange}
          password={password}
          showPasswordStrength
          fluid
        />
        <StyledButton disabled={!passwordStatus}>
          <FormattedMessage id='components.newPassword.btnLabel' />
        </StyledButton>
      </Form>
    </AuthFormView>
  )
}

export default NewPasswordPage
