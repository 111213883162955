import React, { useEffect } from 'react'
import { Container, FormWrapper } from './styles'
import { AuthFormViewInterface } from './types'

const AuthFormView = ({ children }: AuthFormViewInterface) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Container>
      <FormWrapper>{children}</FormWrapper>
    </Container>
  )
}

export { AuthFormView }
