import React from 'react'
import {
  InformationContainer,
  ListItemContainer,
  ListItemDescription,
  Title,
} from './styles'
import { ListItemProps } from './types'

const ListItem = ({ title, Image, description }: ListItemProps) => {
  return (
    <ListItemContainer>
      {Image}
      <InformationContainer>
        {title && <Title>{title}</Title>}
        <ListItemDescription title={description}>
          {description}
        </ListItemDescription>
      </InformationContainer>
    </ListItemContainer>
  )
}

export default ListItem
