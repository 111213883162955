import React from 'react'
import { AuthFormView } from '../../components'
import VerificationCode from '../../components/VerificationCode/VerificationCode'
import { IProps } from './types'

const VerificationCodePage = ({ redirect }: IProps) => {
  return (
    <AuthFormView>
      <VerificationCode redirect={redirect} />
    </AuthFormView>
  )
}

export default VerificationCodePage
