import { parse } from 'social-url'

export const parseSocialMediaUrl = (url: string) => {
  return parse(url)
}

export const validateUrl = (url: string) => {
  // eslint-disable-next-line
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(
    url,
  )
}

export const prefixHttps = (link: string | undefined) => {
  return link && link.indexOf('://') === -1 ? 'https://' + link : link
}
