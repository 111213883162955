import { MenuTriggerButtonProps } from './types'
import React, { useContext } from 'react'
import { OverlayContext } from '../../../utils/ContextWrapper'
import { MenuTrigger } from './styles'
import Hamburger from '../Hamburger'

const MenuTriggerButton = ({ onClick, isOpen }: MenuTriggerButtonProps) => {
  const [isOverlayOpen] = useContext(OverlayContext)

  return (
    <MenuTrigger
      tabIndex={isOverlayOpen ? -1 : 0}
      aria-label='Toggle Navigation Menu'
      onClick={onClick}
    >
      <Hamburger isOpen={isOpen} />
    </MenuTrigger>
  )
}

export default MenuTriggerButton
