import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ConfirmOverlay, SectionHeader } from '../../../components'
import { Menu } from '@material-ui/core'
import OrganisationSection from './OrganisationSection'
import { UppercaseMenuItem } from '../../../components/CustomMenuItem/UppercaseMenuItem'
import { apiService } from '../../../service/api/apiService'
import useSWR from 'swr'
import { openToast } from '../../../components/ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { IOrganisationProps } from './types'

const Organization = ({ organisation }: IOrganisationProps) => {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)
  const [user] = useLocalStorage<IUser>('user')
  const [collapsedOrganisations, setCollapsedOrganisations] = useLocalStorage<
    string[]
  >('collapse', [])
  const isCollapsed = !!collapsedOrganisations?.find(
    (orgId) => orgId === organisation.id,
  )
  const [expanded, setExpanded] = useState(!isCollapsed)
  const { data, mutate } = useSWR(
    !!user ? ['organisations', user.id] : null,
    (key: string, userId: string) => apiService.user.organisations(userId),
  )

  const handleMenuClick = (id: string) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuBubbleClose = () => {
    setAnchorEl(null)
  }

  const toggleExpand = () => {
    setAnchorEl(null)
    if (expanded) {
      setCollapsedOrganisations([
        ...(collapsedOrganisations ?? []),
        organisation.id,
      ])
    } else {
      setCollapsedOrganisations(
        collapsedOrganisations?.filter((orgId) => orgId !== organisation.id) ??
          [],
      )
    }
    setExpanded(!expanded)
  }

  const handleOrganizationDeletion = async () => {
    setIsConfirmOpen(false)
    try {
      await apiService.organisation.delete(organisation.id)
      setCollapsedOrganisations(
        collapsedOrganisations?.filter((orgId) => orgId !== organisation.id) ??
          [],
      )

      mutate(data?.filter((org) => org.id !== organisation.id))
    } catch (e) {
      openToast({
        variant: 'failure',
        message: intl.formatMessage({ id: 'errors.deleteOrganisationFail' }),
      })
    }
    setAnchorEl(null)
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmOpen(false)
  }

  const handleConfirmDialogOpen = () => {
    setIsConfirmOpen(true)
    handleMenuBubbleClose()
  }

  return (
    <>
      <SectionHeader
        title={organisation.name}
        onAction={
          handleMenuClick(organisation.id) as (
            event: React.MouseEvent<HTMLButtonElement> | React.FormEvent,
          ) => void
        }
      />
      <ConfirmOverlay
        title={intl.formatMessage({ id: 'components.menu.deleteOrganisation' })}
        onConfirm={handleOrganizationDeletion}
        onClose={handleConfirmDialogClose}
        question={intl.formatMessage({ id: 'components.menu.confirmDeletion' })}
        open={isConfirmOpen}
      />
      <Menu
        id='organisation-collapse-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuBubbleClose}
      >
        <UppercaseMenuItem onClick={toggleExpand}>
          <FormattedMessage
            id={
              expanded ? 'components.menu.collapse' : 'components.menu.expand'
            }
          />
        </UppercaseMenuItem>
        <UppercaseMenuItem onClick={handleConfirmDialogOpen}>
          <FormattedMessage id='components.menu.deleteOrganisation' />
        </UppercaseMenuItem>
      </Menu>
      <OrganisationSection expanded={expanded} organisation={organisation} />
    </>
  )
}

export default Organization
