import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
export const SelectContainer = styled.div`
  margin: 0 5px;
  width: 100px;
`
