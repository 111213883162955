import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'
import { ContainerProps } from './types'

export const Container = styled.form<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ hasProgressBar }) =>
    hasProgressBar ? 'calc(100% - 19rem)' : '100%'};
  max-width: 530px;
  margin: auto;

  @media (max-height: 520px) {
    height: calc(100% - 110px);
    justify-content: space-between;
  }

  @media (max-width: 480px) {
    width: 90%;
    height: ${({ hasProgressBar }) =>
      hasProgressBar ? 'calc(100% - 16rem)' : '100%'};
  }
`
export const Content = styled.div`
  width: 100%;
  overflow: auto;
  margin: ${({ theme }) => theme.spacing.lg} 0;
  @media (max-height: 520px) {
    margin: 0;
  }
  display: flex;
  align-items: center;
  flex-direction: column;
`
export const Title = styled.h1`
  color: var(--primary);
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  @media (max-height: 520px) {
    margin-bottom: 0;
  }
`
export const StyledButton = styled(FilledButton)`
  margin-top: ${({ theme }) => theme.spacing.lg};
  @media (max-height: 520px) {
    margin-top: 0;
  }
`
