import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  max-width: 50rem;
  margin: 0 auto;
`

export const Title = styled.h1`
  color: var(--primary);
  font-size: ${({ theme }) => theme.fontSize.xl};
  text-transform: uppercase;
  text-align: center;
  font-weight: normal;
  margin-bottom: 5rem;
  @media (max-width: 480px) {
    margin-bottom: 2rem;
    z-index: 500;
  }
`

export const StyledButton = styled(FilledButton)`
  margin-top: 5rem;
`
