import styled, { css } from 'styled-components'
import { FilledButton } from '../../../../components'

export const QRImage = styled.img<{ invert?: boolean }>`
  margin: ${({ theme }) => theme.spacing.lg};
  filter: ${({ invert }) => (invert ? 'invert(1)' : '')};
`
const linkStyles = css`
  color: var(--primary);
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
`
export const Link = styled.a`
  ${linkStyles}
`
export const LinkBtn = styled.span`
  ${linkStyles}
`
export const CodeContainer = styled.div`
  display: flex;
  color: var(--primary);
  border: 1px solid var(--primary);
  width: 50%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: ${({ theme }) => theme.spacing.lg} 0rem;
  height: 5rem;
  @media only screen and (max-width: 480px) {
    width: 80%;
  }
`
export const IconButton = styled(FilledButton)`
  border: none;
  border-left: 1px solid var(--primary);
  padding: 0;
  margin: 0;
  max-width: 5rem;
  min-width: 5rem;
`
export const CodeBox = styled.div`
  overflow: auto;
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  margin: 0 ${({ theme }) => theme.spacing.xs};
`
export const PasswordContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.lg};
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 480px) {
    max-width: 350px;
    margin: 0 5px;
  }
`
export const Caption = styled.div`
  color: var(--primary);
  font-size: ${({ theme }) => theme.fontSize.md};
  text-align: center;
  @media only screen and (max-width: 480px) {
    max-width: 100%;
  }
`
export const CodeInputContainer = styled.div`
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
  display: flex;
  justify-content: center;
  height: 64px;
  margin: 12px 0;
`
export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: bold;
`
