import React from 'react'
import { HamburgerIcon, HamburgerIconContainer } from './styles'
import { IsOpenProps } from './types'

const Hamburger = ({ isOpen }: IsOpenProps) => {
  return (
    <HamburgerIconContainer isOpen={isOpen}>
      <HamburgerIcon isOpen={isOpen} />
    </HamburgerIconContainer>
  )
}

export default Hamburger
