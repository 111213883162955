import React, { useContext, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'

import { IconLabelBtn } from '../'
import { ReactComponent as Checkmark } from '../../assets/icons/other/icon_check.svg'
import { AppContext } from '../../utils/ContextWrapper'
import { IProgressFieldsProps } from './types'
import {
  ActiveField,
  CircleContainer,
  CustomField,
  Done,
  FieldLabel,
  FieldsContainer,
  InnerContainer,
  Pending,
  Percentage,
  ProgressLineEnd,
  ProgressLineStart,
  ProgressOuter,
} from './progressFieldsStyles'

const ProgressFields = ({
  type = 'profile',
  setCurrentIndex,
  currentStepIndex,
  steps,
}: IProgressFieldsProps) => {
  const [{ authorizeData, mandatoryFields }] = useContext(AppContext)
  const isAuthorizeFlow = !!authorizeData.appId

  useEffect(() => {
    document
      .getElementById('fields-scroll-target')
      ?.scrollIntoView({ inline: 'center', behavior: 'smooth' })
  }, [currentStepIndex])

  const fields =
    steps
      ?.map((step, index) => ({ ...step, stepIndex: index }))
      .filter(
        (step) =>
          step.name !== 'email' &&
          step.name !== 'password' &&
          (!isAuthorizeFlow ||
            (step.name && mandatoryFields.includes(step.name))),
      ) ?? []
  const percentage = Math.round(
    ((fields ? fields.filter((field) => field.completed).length : 1) /
      (fields ? fields.length : 1)) *
      100,
  )

  const handleFieldClick = (i: number) => () => setCurrentIndex(i)
  return (
    <ProgressOuter>
      <Percentage>
        {isAuthorizeFlow ? (
          <FormattedMessage id='components.progressFields.mandatory' />
        ) : (
          <FormattedMessage id={`components.progressFields.${type}`} />
        )}
        <FormattedMessage id='components.progressFields.completed' />{' '}
        {(percentage ?? 100) + '%'}
      </Percentage>
      <FieldsContainer>
        {fields?.map((item, index) => {
          const prevItem = index > 0 ? fields[index - 1] : null
          const nextItem = fields?.length > index + 1 ? fields[index + 1] : null
          if (item.stepIndex === currentStepIndex) {
            return (
              <ActiveField key={index} id='fields-scroll-target'>
                <ProgressLineStart
                  current
                  done={item.completed && prevItem?.completed}
                />
                <IconLabelBtn
                  aria-disabled
                  completed={item.completed}
                  key={index}
                  disabled
                  hasCheckIcon={false}
                  iconFileName={item.iconName}
                />
                <FieldLabel>
                  <FormattedMessage id={item.label} />
                </FieldLabel>
                <ProgressLineEnd
                  current
                  done={item.completed && nextItem?.completed}
                />
              </ActiveField>
            )
          } else {
            return (
              <CustomField
                aria-label={`Go to ${item.name}`}
                key={index}
                onClick={handleFieldClick(item.stepIndex)}
              >
                <ProgressLineStart
                  done={item.completed && prevItem?.completed}
                />
                <InnerContainer>
                  <CircleContainer>
                    {item.completed ? (
                      <Done title='Done'>
                        <Checkmark />
                      </Done>
                    ) : (
                      <Pending />
                    )}
                  </CircleContainer>
                  <FieldLabel>
                    <FormattedMessage id={item.label} />
                  </FieldLabel>
                </InnerContainer>

                <ProgressLineEnd done={item.completed && nextItem?.completed} />
              </CustomField>
            )
          }
        })}
      </FieldsContainer>
    </ProgressOuter>
  )
}

export { ProgressFields }
