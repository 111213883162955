export const SOCIAL_MEDIA_LIST = [
  {
    type: 'Snapchat',
    icon: 'social_snapchat.svg',
  },
  {
    type: 'LinkedIn',
    icon: 'social_linkedin2.svg',
  },
  {
    type: 'Facebook',
    icon: 'social_facebook2.svg',
  },
  {
    type: 'Instagram',
    icon: 'social_instagram2.svg',
  },
  {
    type: 'Twitter',
    icon: 'social_twitter2.svg',
  },
]
