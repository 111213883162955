import React, { useContext, useState } from 'react'
import AsyncSelect from 'react-select/async'
import { OptionsType, ValueType } from 'react-select'
import { apiService } from '../../service/api/apiService'
import { useIntl } from 'react-intl'
import { useAutoInputFocus } from '../../utils/hooks/useAutoInputFocus'
import debounce from 'lodash.debounce'
import { ArrowNavigationContext } from '../../utils/ContextWrapper'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { customStyles } from '../Dropdown/styles'
import { ILocationProps } from './types'

const Location = ({ label, change, value, editable, name }: ILocationProps) => {
  const [, setArrowNavigationEnabled] = useContext(ArrowNavigationContext)
  const [isFocused, setIsFocused] = useState(false)
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'
  const defaultVal = value ? { value, label: value } : null
  const [currentVal, setCurrentVal] = useState(defaultVal)

  const getLocations = async (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    try {
      const { data } = await apiService.location.getCities({ searchString })
      const filteredOptions = data.map((city) => {
        const cityName = isRtl && city.arabname ? city.arabname : city.name
        const country =
          isRtl && city.country.ar ? city.country.ar : city.country.en
        const cityWithCountrySuffix = `${cityName}, ${country}`
        return {
          value: cityWithCountrySuffix,
          label: cityWithCountrySuffix,
        }
      })
      callback(filteredOptions)
    } catch (e) {
      callback([])
    }
  }
  const debouncedLocations = debounce(getLocations, 400)
  const loadOptions = (
    searchString: string,
    callback: (options: OptionsType<{ value: string; label: string }>) => void,
  ) => {
    debouncedLocations(searchString, callback)
  }

  const handleChange = (value: { value: string; label: string }) => {
    setCurrentVal(value)
    if (value === null || value === undefined) {
      change('', false)
    } else {
      change(value.value, true)
    }
  }

  useAutoInputFocus(!!value, isFocused, editable)

  const handleFocus = () => {
    setIsFocused(true)
    setArrowNavigationEnabled(false)
  }

  const handleBlur = () => {
    setIsFocused(false)
    setArrowNavigationEnabled(true)
  }

  return editable ? (
    <div>
      <AsyncSelect
        className='focusClass'
        onFocus={handleFocus}
        aria-label={intl.formatMessage({ id: label })}
        onBlur={handleBlur}
        isRtl={isRtl}
        autoFocus={value === ''}
        placeholder={intl.formatMessage({
          id: 'components.location.typePlaceholder',
        })}
        noOptionsMessage={({ inputValue }: { inputValue: string }) =>
          intl.formatMessage({
            id:
              inputValue === ''
                ? 'components.location.noOptions.empty'
                : 'components.location.noOptions.normal',
          })
        }
        value={currentVal}
        menuPortalTarget={document.body}
        styles={customStyles}
        cacheOptions
        onChange={
          handleChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        loadOptions={loadOptions}
        defaultOptions
      />
    </div>
  ) : (
    <ReadOnly value={currentVal?.label} fieldName={label} />
  )
}

export { Location }
