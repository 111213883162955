import styled, { css } from 'styled-components'

export const ProgressOuter = styled.div`
  width: 100vw;
  height: 16rem;
  position: fixed;
  border-top: 1px solid var(--grey);
  bottom: 0;
  display: flex;
  background: var(--background);
  z-index: 200;
  @media (max-width: 700px), (max-height: 520px) {
    height: 11rem;
  }
`
export const CircleContainer = styled.div`
  width: 8rem;
  height: 8rem;
  @media (max-width: 700px), (max-height: 520px) {
    width: 6rem;
    height: 6rem;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`
const Circle = styled.div`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
`
export const Done = styled(Circle)`
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  svg,
  path {
    height: 3rem;
    width: 3rem;
    fill: var(--background);
  }
`
const field = css`
  padding: 0;
  margin: 1rem 0rem;
  height: calc(100% - 2rem);
  position: relative;
  display: flex;
  border: none;
  background: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 15rem;
  cursor: pointer;
  flex-grow: 1;
  @media (max-width: 700px), (max-height: 520px) {
    margin: 0.5rem 0rem;
    height: calc(100% - 1rem);
  }
`
export const ActiveField = styled.div`
  ${field}
`
export const CustomField = styled.button`
  ${field}
`
export const InnerContainer = styled.div`
  margin: 1rem 0rem;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const FieldLabel = styled.div`
  position: absolute;
  top: 9rem;
  color: var(--primary);
  text-transform: uppercase;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: bold;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  margin-top: 1.5rem;
  max-width: 120px;
  @media (max-width: 700px), (max-height: 520px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
    top: 7rem;
  }
  width: 100%;
  text-align: center;
`
export const Pending = styled(Circle)`
  background: var(--darkgrey);
`
export const FieldsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
`
const ProgressLine = styled.div<{ done?: boolean; current?: boolean }>`
  height: 3px;
  background: ${({ done }) => (done ? 'var(--primary)' : 'var(--darkgrey)')};
  width: ${({ current }) => (current ? '35px' : '55px')};
  position: absolute;
  top: 5.2rem;
  @media (max-width: 700px), (max-height: 520px) {
    width: ${({ current }) => (current ? '45px' : '55px')};
    top: 4rem;
  }
`
export const Percentage = styled.span`
  font-weight: bold;
  position: absolute;
  width: 300px;
  left: calc(50% - 150px);
  top: -30px;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  @media (max-height: 520px) {
    visibility: hidden;
  }
`
export const ProgressLineStart = styled(ProgressLine)`
  left: 0rem;
`
export const ProgressLineEnd = styled(ProgressLine)`
  right: 0rem;
`
