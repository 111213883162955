import React from 'react'
import Select, { ValueType } from 'react-select'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { useIntl } from 'react-intl'
import { customStyles, DropdownContainer } from './styles'
import { Props } from './types'

const Dropdown = ({
  label,
  value = '',
  change,
  list,
  hasDefaultOption = true,
  editable,
}: Props) => {
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'

  const onChange = (value?: { value: string; label: string } | null) => {
    change(value?.value || '', true)
  }

  const options = list
    ? [
        ...list.map((item, i) => ({
          value: item.key,
          label: item.value,
        })),
        ...(hasDefaultOption ? [{ value: '', label: 'None' }] : []),
      ]
    : []

  const currentValue =
    options.find(
      (option) => option.value.toLowerCase() === value.toLowerCase(),
    ) ?? null

  const placeholder = intl.formatMessage({
    id: 'components.dropdown.placeholder',
  })

  return editable ? (
    <DropdownContainer>
      <Select
        autoFocus
        aria-label={intl.formatMessage({ id: label })}
        isRtl={isRtl}
        menuPortalTarget={document.body}
        isClearable
        placeholder={placeholder}
        value={currentValue}
        onChange={
          onChange as (
            value: ValueType<{ value: string; label: string }>,
          ) => void
        }
        styles={customStyles}
        options={options}
      />
    </DropdownContainer>
  ) : (
    <ReadOnly fieldName={label} value={currentValue?.label} />
  )
}

export { Dropdown }
