import React, { useContext } from 'react'
import { apiService } from '../../../service/api/apiService'
import { mutate } from 'swr'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { LocaleContext } from '../../../App'
import { Container, DefaultButton } from './styles'
import { langNames, languageCodes } from './constants'

const LanguageSwitch = () => {
  const [locale, setLocale] = useContext(LocaleContext)
  const [user] = useLocalStorage<IUser>('user')
  const isDesktop = useMediaQuery('(min-width:701px)')
  const updateLanguage = (code: string) => async () => {
    setLocale(code ?? 'en')
    if (user) {
      const data = await apiService.user.update(user?.id ?? '', {
        preferredLanguage: code ?? '',
      })
      mutate('user', data, false)
    }
  }

  return (
    <Container>
      {languageCodes.map((code) => (
        <DefaultButton
          fluid={!isDesktop}
          btnType={locale === code ? 'primary' : 'default'}
          onClick={updateLanguage(code)}
          key={code}
        >
          {langNames[code]}
        </DefaultButton>
      ))}
    </Container>
  )
}

export default LanguageSwitch
