import React from 'react'
import { FormattedMessage } from 'react-intl'

import useTheme from '../../../utils/hooks/useTheme'
import { useMediaQuery } from '@material-ui/core'
import { Container, StyledButton } from './styles'
import { themes } from './constants'

const ThemeSwitch = () => {
  const { theme, setTheme } = useTheme()
  const isMobile = useMediaQuery('screen and (max-width: 480px)')
  return (
    <Container>
      {themes.map((t) => (
        <StyledButton
          fluid={isMobile}
          btnType={t === theme ? 'primary' : 'default'}
          onClick={() => setTheme(t)}
          key={t}
        >
          <FormattedMessage id={`settings.theme.${t}`} />
        </StyledButton>
      ))}
    </Container>
  )
}

export default ThemeSwitch
