import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FileCopyOutlined } from '@material-ui/icons'
import { CodeBox, CodeContainer, IconButton } from './styles'
import { CopyCodeProps } from './types'

const CopyCode = ({ code, onCopy }: CopyCodeProps) => {
  return (
    <CodeContainer>
      <CodeBox data-testid='2fa-otp'>{code}</CodeBox>
      <CopyToClipboard text={code} onCopy={onCopy}>
        <IconButton>
          <FileCopyOutlined />
        </IconButton>
      </CopyToClipboard>
    </CodeContainer>
  )
}

export default CopyCode
