import styled, { css } from 'styled-components'

const buttonStyle = () => css`
  height: 5rem;
  min-width: 5rem;
  padding: 0;
  margin: 0;
  margin-bottom: -1px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid var(--border);
  background: var(--background);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    border-right: none;
  }
  &:hover {
    background-color: var(--primary-transparent);
  }
`

export const ButtonLink = styled.a`
  ${buttonStyle}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: var(--primary);
  text-decoration: none;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 2rem;
  font-weight: 600;
  @media only screen and (max-width: 700px) {
    width: 100%;
    margin-top: 2rem;
  }
`

export const DropdownMenu = styled.div<{ isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 20px;
  position: absolute;
  background: var(--background);
  top: 5rem;
  left: 0px;
  right: 4.9rem;
  border: 1px solid var(--border);
  z-index: 10;
  border-top: none;
  &:hover {
    display: flex;
  }
`

export const Button = styled.button`
  ${buttonStyle}
`

export const ThemeBtn = styled(Button)`
  padding: 0;
  background: var(--primary);
  border: none;
  svg {
    overflow: visible;
    stroke-width: 0.6px;
  }
  path {
    fill: var(--background);
    stroke: var(--background);
  }
  @media only screen and (max-width: 700px) {
    max-width: 4rem;
    min-width: 4rem;
    height: 4rem;
  }
  &:hover {
    background-color: var(--primary);
  }
`

export const LanguageBtn = styled(Button)`
  color: var(--primary);
  @media only screen and (max-width: 700px) {
    max-width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-left: 1px solid var(--border);
  }
`

export const ProfileBtn = styled(Button)`
  border-left: none;
  path {
    fill: var(--primary);
  }
  &:hover + ${DropdownMenu} {
    display: flex;
  }
`

export const MenuTrigger = styled.div`
  display: none;
  @media only screen and (max-width: 700px) {
    display: flex;
  }
`
