import styled, { css } from 'styled-components'
import { ReactComponent as Birthday } from '../../assets/icons/personal/personal_dateofbirth.svg'
import { ReactComponent as Location } from '../../assets/icons/personal/personal_location.svg'
import { ReactComponent as Person } from '../../assets/icons/personal/personal_photo.svg'
import { ReactComponent as Email } from '../../assets/icons/personal/personal_email.svg'
import { ReactComponent as Mobile } from '../../assets/icons/personal/personal_mobile.svg'

export const Caption = styled.div`
  margin: 0;
  font-size: $font-small;
  text-align: center;
`
export const QrContainer = styled.div`
  text-align: center;
  width: 100%;
`
export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid var(--darkgrey);
  margin: 2rem auto;
`
export const Title = styled.div`
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem auto;
  font-size: ${({ theme }) => theme.fontSize.md};
`
const iconStyle = css`
  width: 3rem;
  fill: var(--primary);
  height: 3rem;
  margin-right: 2rem;
`
export const StyledBirthday = styled(Birthday)`
  ${iconStyle}
`
export const StyledPerson = styled(Person)`
  ${iconStyle}
`
export const StyledMobile = styled(Mobile)`
  ${iconStyle}
`
export const StyledEmail = styled(Email)`
  ${iconStyle}
`
export const StyledLocation = styled(Location)`
  ${iconStyle}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background: var(--background);
  color: var(--primary);
  padding: 3.5rem 7rem;
  align-items: center;
  overflow: auto;
  max-width: 480px;
  margin-top: 4rem;
  @media only screen and (max-width: 800px) {
    padding: 2rem;
  }
`
