import Bowser from 'bowser'
import { axiosAuth, axiosIdentity } from './api/axiosInstances'
import { IApiCallResult, IClientAccess, IConnectionDataItem } from './types'

function resolveDeviceName(item: IApiCallResult) {
  const navigatorData = JSON.parse(item.userAgent)
  if (navigatorData.os === 'unknown') return navigatorData.browser
  const deviceInfo = Bowser.parse(navigatorData.source)

  const platformType = deviceInfo.platform.type
    ? deviceInfo.platform.type.toUpperCase()
    : 'UNDEFINED'

  return `${platformType} - ${deviceInfo.os.name} ${
    deviceInfo.os.versionName || ''
  }`
}

function parseConnectionResults(data: IApiCallResult[]): IConnectionDataItem[] {
  return data.map((item) => {
    const navigatorData = JSON.parse(item.userAgent)
    const deviceInfo = Bowser.parse(navigatorData.source)
    return {
      ...item,
      navigatorData,
      deviceInfo,
      name: resolveDeviceName(item),
    }
  })
}

export const getSessions = async (): Promise<IConnectionDataItem[]> => {
  const result = await axiosAuth.get('/auth/sessions')
  return parseConnectionResults(result.data)
}

export const removeSession = (id: string) => {
  return axiosAuth.delete(`/auth/sessions/${id}`)
}

export const endAllSessions = async () => {
  await axiosAuth.post('/auth/logoutall', {})
  return true
}

export async function getAllConnectedApps(
  id: string,
): Promise<IConnectionDataItem[]> {
  const result = await axiosIdentity.get(`/connectedapps/user/${id}`)
  return result.data
}

export async function getAllApps(): Promise<IClientAccess[]> {
  const result = await axiosIdentity.get(`/clientapps`)
  return result.data
}

export const deleteConnectedApp = (appId: string) => {
  return axiosIdentity
    .delete(`/clientapps/${appId}`)
    .then((response) => {
      if (response && response.data) {
        return response
      }
    })
    .catch((error: any) => {
      throw error.response.data.errors
    })
}

export async function setAppAccessLevel(
  clientAppID: string,
  linkedid: string,
  linkType: string,
  accessLevel: string,
) {
  const result = await axiosAuth.post(`/connectedapps/set`, {
    clientAppID,
    linkedid,
    linkType: linkType,
    accessLevel,
  })
  return result
}

export async function deleteApp(clientAppID: string) {
  const result = await axiosAuth.delete(`/clientapps/${clientAppID}`)
  return result
}
