import styled, { css } from 'styled-components'
import { ReactComponent as Instagram } from '../../assets/icons/social/social_instagram2.svg'
import { IconStyleProps } from './types'
import { ReactComponent as Facebook } from '../../assets/icons/social/social_facebook2.svg'
import { ReactComponent as LinkedIn } from '../../assets/icons/social/social_linkedin.svg'
import { ReactComponent as Add } from '../../assets/icons/other/icon_plus.svg'
import { ReactComponent as SocialMediaDefault } from '../../assets/icons/personal/personal_socialmedia.svg'
import { ReactComponent as Twitter } from '../../assets/icons/social/social_twitter2.svg'
import { ReactComponent as Snapchat } from '../../assets/icons/social/social_snapchat.svg'
import { IconButton } from '../IconButton/IconButton'

export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  @media (max-width: 480px) {
    width: calc(100% - 2rem);
  }
`
const iconStyle = css<IconStyleProps>`
  width: ${({ isAdornment }) => (isAdornment ? '2rem' : '4rem')};
  height: ${({ isAdornment }) => (isAdornment ? '2rem' : '4rem')};
  fill: var(--primary);
  path,
  g {
    fill: var(--primary);
  }
  @media (max-width: 480px) {
    width: 3rem;
    height: 3rem;
  }
`
export const StyledAdd = styled(Add)`
  ${iconStyle}
`
export const StyledInsta = styled(Instagram)`
  ${iconStyle}
`
export const StyledTwitter = styled(Twitter)`
  ${iconStyle}
`
export const StyledFacebook = styled(Facebook)`
  ${iconStyle}
`
export const StyledLinkedIn = styled(LinkedIn)`
  ${iconStyle}
`
export const StyledSnapchat = styled(Snapchat)`
  ${iconStyle}
`
export const StyledGeneral = styled(SocialMediaDefault)`
  ${iconStyle}
`
export const SocialContainer = styled.div`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: row;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--darkgrey);
  @media (max-width: 480px) {
    padding: 0rem;
    border-bottom: none;
    width: 100%;
    margin: 5px 0;
  }
`
export const UrlContainer = styled.div`
  display: flex;
  width: 80%;
  align-items: center;
  cursor: pointer;
  @media (max-width: 480px) {
    margin: 5px 0;
  }
`
export const Url = styled.a`
  color: var(--primary);
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  div {
    @media screen and (max-width: 480px) {
      input {
        padding: 0;
        padding-left: 5px;
      }
    }
  }
`
export const RemoveButton = styled(IconButton)`
  svg {
    transform: rotate(45deg);
  }
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
`
export const AddButton = styled(IconButton)`
  width: 3rem;
  height: 3rem;
  min-width: 3rem;
  min-height: 3rem;
`
export const ImageContainer = styled.div`
  min-width: 6rem;
  min-height: 6rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 2px solid var(--primary);
  margin: 0rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    path {
      stroke: var(--primary) !important;
      fill: var(--primary) !important;
    }
    fill: var(--primary) !important;
  }
  @media (max-width: 480px) {
    margin: 0rem;
    margin-right: 1rem;
    width: 4rem;
    height: 4rem;
    min-width: 4rem;
    min-height: 4rem;
  }
`
export const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`
