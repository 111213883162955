import React from 'react'

import { SocialNetwork } from 'social-url'
import {
  StyledAdd,
  StyledFacebook,
  StyledGeneral,
  StyledInsta,
  StyledLinkedIn,
  StyledSnapchat,
  StyledTwitter,
} from './styles'

const SocialMediaIcon = ({
  service,
  isAdd,
  isAdornment,
  alt,
}: {
  service: SocialNetwork | string
  isAdd?: boolean
  isAdornment?: boolean
  alt?: string
}) => {
  if (isAdd) {
    return <StyledAdd />
  }
  switch (service) {
    case 'Instagram':
      return (
        <StyledInsta
          aria-label={alt}
          isAdornment={isAdornment}
          title={service}
        />
      )
    case 'Twitter':
      return (
        <StyledTwitter
          aria-label={alt}
          isAdornment={isAdornment}
          title={service}
        />
      )
    case 'Facebook':
      return (
        <StyledFacebook
          aria-label={alt}
          isAdornment={isAdornment}
          title={service}
        />
      )
    case 'LinkedIn':
      return (
        <StyledLinkedIn
          aria-label={alt}
          isAdornment={isAdornment}
          title={service}
        />
      )
    case 'Snapchat':
      return (
        <StyledSnapchat
          aria-label={alt}
          isAdornment={isAdornment}
          title={service}
        />
      )
    default:
      return (
        <StyledGeneral
          aria-label={alt}
          isAdornment={isAdornment}
          title='Social Media'
        />
      )
  }
}

export default SocialMediaIcon
