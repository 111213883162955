import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: var(--background);
  height: calc(100% - 7rem);
`

export const Title = styled(Container)`
  font-size: 12rem;
  font-weight: 300;
  text-transform: uppercase;
  color: var(--primary);
  text-align: center;
  user-select: none;
  @media only screen and (max-width: 480px) {
    font-size: 5rem;
  }
`
