import React from 'react'
import TextInput from './TextInput'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { IconButton } from '../IconButton/IconButton'
import { validateUrl } from '../SocialMedia/util'
import { ReactComponent as WebsiteIcon } from '../../assets/icons/organization/organization_companywebsite.svg'
import { useIntl } from 'react-intl'
import { WebsiteProps } from './types'
import { Container, StyledLink } from './styles'

const Website = ({ value = '', editable, label, ...rest }: WebsiteProps) => {
  const intl = useIntl()
  const clickUrl = () => {
    if (validateUrl(value.toString())) {
      window.open('//' + value.toString(), '_blank')
    }
  }
  return (
    <Container>
      {editable ? (
        <TextInput
          editable
          value={value}
          showStatusHints
          fluid
          endAdornment={
            <IconButton
              disabled={!validateUrl(value.toString())}
              title={intl.formatMessage({
                id: 'components.companyWebsite.goTo',
              })}
              onClick={clickUrl}
            >
              <WebsiteIcon
                title={intl.formatMessage({
                  id: 'components.companyWebsite.goTo',
                })}
              />
            </IconButton>
          }
          {...rest}
        />
      ) : (
        <ReadOnly
          value={
            value && (
              <StyledLink
                target='_blank'
                rel='noopener noreferrer'
                href={`//${value?.toString().toLowerCase()}`}
              >
                {value}
              </StyledLink>
            )
          }
          fieldName={label}
        />
      )}
    </Container>
  )
}

export { Website }
