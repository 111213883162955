import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
  ConfirmOverlay,
  IconLabelBtn,
  SectionHeader,
} from '../../../components'
import { Collapse, Menu } from '@material-ui/core'
import { UppercaseMenuItem } from '../../../components/CustomMenuItem/UppercaseMenuItem'
import organisationFields from '../../../assets/data/organizationData.json'
import { apiService } from '../../../service/api/apiService'
import { openToast } from '../../../components/ToastMessage/ToastService'
import useSWR from 'swr'
import { Section, SectionItem } from '../Section'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { IPendingRequestProps } from './types'

const PendingRequest = ({ pendingRequest }: IPendingRequestProps) => {
  const [user] = useLocalStorage<IUser>('user')
  const intl = useIntl()
  const { data, mutate } = useSWR(
    !!user ? ['pendingRequests', user.id] : null,
    (key: string, userId) => apiService.user.pendingRequests(userId),
  )
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [collapsedOrganisations, setCollapsedOrganisations] = useLocalStorage<
    string[]
  >('collapse', [])
  const isCollapsed = !!collapsedOrganisations?.find(
    (orgId) => orgId === pendingRequest.id,
  )
  const [expanded, setExpanded] = useState(!isCollapsed)
  const [isConfirmOpen, setIsConfirmOpen] = useState(false)

  const handleMenuClick = (id: string) => (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuBubbleClose = () => {
    setAnchorEl(null)
  }

  const toggleExpand = () => {
    setAnchorEl(null)
    if (expanded) {
      setCollapsedOrganisations([
        ...(collapsedOrganisations ?? []),
        pendingRequest.id,
      ])
    } else {
      setCollapsedOrganisations(
        collapsedOrganisations?.filter(
          (orgId) => orgId !== pendingRequest.id,
        ) ?? [],
      )
    }
    setExpanded(!expanded)
  }

  const handleRequestDeletion = async () => {
    //Delete join request
    setIsConfirmOpen(false)

    if (user && data) {
      try {
        await apiService.user.removePendingRequest(user.id, pendingRequest.id)
        setCollapsedOrganisations(
          collapsedOrganisations?.filter(
            (orgId) => orgId !== pendingRequest.id,
          ) ?? [],
        )

        mutate(
          data.filter((req) => req.id !== pendingRequest.id),
          false,
        )
        openToast({
          message: intl.formatMessage({
            id: 'components.joinRequest.successDelete',
          }),
          variant: 'success',
        })
      } catch (e) {
        openToast({
          message: intl.formatMessage({ id: 'errors.genericFail' }),
          variant: 'success',
        })
      }
    }
  }

  const handleConfirmDialogClose = () => {
    setIsConfirmOpen(false)
  }

  const handleConfirmDialogOpen = () => {
    setIsConfirmOpen(true)
    handleMenuBubbleClose()
  }

  return (
    <>
      <SectionHeader
        pending
        title={pendingRequest.name}
        onAction={
          handleMenuClick(pendingRequest.id) as (
            event: React.MouseEvent<HTMLButtonElement> | React.FormEvent,
          ) => void
        }
      />
      <ConfirmOverlay
        title={intl.formatMessage({
          id: 'components.joinRequest.removeRequest',
        })}
        onConfirm={handleRequestDeletion}
        onClose={handleConfirmDialogClose}
        question={intl.formatMessage({
          id: 'components.joinRequest.confirmDeletion',
        })}
        open={isConfirmOpen}
      />
      <Menu
        id='pendingrequest-collapse-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuBubbleClose}
      >
        <UppercaseMenuItem onClick={toggleExpand}>
          <FormattedMessage
            id={
              expanded ? 'components.menu.collapse' : 'components.menu.expand'
            }
          />
        </UppercaseMenuItem>
        <UppercaseMenuItem onClick={handleConfirmDialogOpen}>
          <FormattedMessage id='components.joinRequest.removeRequest' />
        </UppercaseMenuItem>
      </Menu>
      <Collapse in={expanded} timeout='auto'>
        <Section>
          {organisationFields.map((field, index) => {
            const { label, iconName } = field

            return (
              <SectionItem key={index}>
                <IconLabelBtn
                  disabled
                  iconFileName={iconName}
                  label={intl.formatMessage({ id: `${label}` })}
                />
              </SectionItem>
            )
          })}
        </Section>
      </Collapse>
    </>
  )
}

export default PendingRequest
