import { Status } from './types'

export const getBorderColor = (status?: Status) => {
  switch (status) {
    case 'success':
      return 'var(--green)'
    case 'error':
      return 'var(--red)'
    case 'info':
      return 'var(--accent)'
    case 'warning':
      return 'var(--yellow)'
    default:
      return 'var(--grey)'
  }
}
export const getColor = (hasColor: boolean, score: number) => {
  if (hasColor) {
    switch (score) {
      case 1:
        return 'var(--red)'
      case 2:
        return 'var(--yellow)'
      case 3:
        return 'var(--accent)'
      case 4:
        return 'var(--green)'
    }
  } else {
    return 'var(--grey)'
  }
}
