import React, { useContext, useEffect, useMemo, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { apiService } from '../../service/api/apiService'
import ChipSlide from './ChipSlide'
import { renderErrorToast } from '../../utils/errorToast'
import { ArrowNavigationContext } from '../../utils/ContextWrapper'
import { useIntl } from 'react-intl'
import { chipStyles, Container, InputContainer } from './styles'
import {
  HandleChangeValueType,
  IChipInputProps,
  SelectValueType,
} from './types'

const ChipInput = ({
  name,
  chips = [],
  change,
  label,
  editable,
}: IChipInputProps) => {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const rtl = intl.locale === 'ar'
  const [input, setInput] = useState<string>()
  const [existingTags, setExistingTags] = useState<string[]>([])
  const [selectValue, setSelectValue] = useState<SelectValueType | null>()

  const [, setArrowNavigationEnabled] = useContext(ArrowNavigationContext)

  const selectOptions = useMemo(
    () =>
      existingTags
        .filter((tag) => !chips.includes(tag))
        .map((val) => ({ label: val.toUpperCase(), value: val })),
    [existingTags, chips],
  )

  // set values using value param

  useEffect(() => {
    apiService.tags
      .get()
      .then(({ data }) => {
        setExistingTags(data)
        setLoading(false)
      })
      .catch(({ response }) => {
        setLoading(false)
        renderErrorToast(response, 'components.chip.error', intl)
      })
    setArrowNavigationEnabled(false)
    // eslint-disable-next-line
  }, [])

  const addItem = (item?: string) => {
    if (item) {
      let splitInput
      if (!chips.includes(item) && item.includes('#')) {
        splitInput = item
          .split('#')
          .map((val) => val.trim())
          .filter((val) => val !== '' && !chips.includes(val))
      }
      const changeValue = splitInput
        ? [...chips, ...splitInput]
        : [...chips, item]
      change(changeValue, true)
    }
  }

  const handleInputChange = (value: string) => {
    setInput(value)
  }

  const handleChange = (value: SelectValueType | null) => {
    if (value) {
      addItem(value.value)
    }
    setSelectValue(null)
  }

  return (
    <Container>
      {editable && (
        <InputContainer>
          <CreatableSelect
            id='chipInput'
            aria-label='Select tags'
            isLoading={loading}
            loadingMessage={() =>
              intl.formatMessage({ id: 'components.chip.loading' })
            }
            allowCreateWhileLoading={false}
            multiple
            className='focusClass'
            name={name}
            autoFocus
            isRtl={rtl}
            onFocus={() => setArrowNavigationEnabled(false)}
            onBlur={() => setArrowNavigationEnabled(true)}
            inputValue={input}
            onInputChange={handleInputChange}
            value={selectValue}
            style={{ width: '100%' }}
            styles={chipStyles}
            menuPortalTarget={document.body}
            onChange={handleChange as HandleChangeValueType}
            isClearable
            formatCreateLabel={(inputValue: string) =>
              `${intl.formatMessage({
                id: 'components.chip.createMsg',
              })} "${inputValue.toUpperCase()}"`
            }
            placeholder={intl.formatMessage({
              id: 'components.chip.placeholder',
            })}
            options={selectOptions}
          />
        </InputContainer>
      )}
      <ChipSlide
        label={label}
        editable={editable}
        change={change}
        chips={chips}
      />
    </Container>
  )
}

export { ChipInput }
