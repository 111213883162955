import React from 'react'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { AdornmentContainer, InputContainer, StyledInput } from './styles'
import { IInputProps } from './types'

const CustomInput = ({
  readonly = false,
  label,
  startAdornment,
  endAdornment,
  value,
  status,
  fluid,
  ...props
}: IInputProps) => {
  return readonly ? (
    <ReadOnly value={value} fieldName={label}></ReadOnly>
  ) : (
    <InputContainer fluid={fluid}>
      {startAdornment && (
        <AdornmentContainer status={status}>
          {startAdornment}
        </AdornmentContainer>
      )}
      <StyledInput
        status={status}
        startAdornment={!!startAdornment}
        endAdornment={!!endAdornment}
        value={value}
        {...props}
      />
      {endAdornment && (
        <AdornmentContainer status={status}>{endAdornment}</AdornmentContainer>
      )}
    </InputContainer>
  )
}

export { CustomInput }
