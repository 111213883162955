import React, { useEffect, useState } from 'react'
import { apiService } from '../../service/api/apiService'
import { Dropdown } from '../Dropdown/Dropdown'
import { useIntl } from 'react-intl'
import { INationalityProps } from './types'

const Nationality = ({ editable, change, step, label }: INationalityProps) => {
  const intl = useIntl()
  const [countries, setCountries] = useState<{ key: string; value: string }[]>(
    [],
  )

  useEffect(() => {
    apiService.countries.get().then((countries) => {
      const parsedList = countries.map((c) => ({
        key: c.code,
        value: c[intl.locale as 'en' | 'ar'],
      }))
      setCountries(parsedList)
    })
  }, [intl.locale])
  return (
    <Dropdown
      aria-label='Select nationality'
      editable={editable}
      change={change}
      list={countries}
      label={label}
      value={step.value}
      hasDefaultOption={false}
    />
  )
}

export { Nationality }
