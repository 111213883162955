import { useContext, useEffect } from 'react'
import { ArrowNavigationContext } from '../ContextWrapper'

export const useAutoInputFocus = (
  hasInputValue: boolean,
  isInputFocused: boolean,
  isEditable = true,
  name = '',
) => {
  const [, setArrowNavigationEnabled] = useContext(ArrowNavigationContext)
  useEffect(() => {
    let timer: number
    if (!hasInputValue) {
      // eslint-disable-next-line
      timer = window.setTimeout(() => {
        // @ts-ignore
        const focusElement = document.querySelector<HTMLInputElement>(
          '.focusClass',
        )
        if (focusElement) {
          focusElement.focus()
        }
      }, 200)
      setArrowNavigationEnabled(isEditable)
    } else if (!isInputFocused && hasInputValue) {
      setArrowNavigationEnabled(true)
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line
  }, [name])
}
