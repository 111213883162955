import React from 'react'
import styled from 'styled-components'
import { customStyles } from '../Dropdown/styles'

export const dateStyles = {
  ...customStyles,
  control: (provided: React.CSSProperties, { isSelected }: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid var(--primary)',
    boxShadow: isSelected ? '0 0 0 1px var(--primary)' : '',
    height: 50,
    background: 'var(--background)',
  }),
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  overflow: hidden;
  text-align: center;
`
