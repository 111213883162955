import React from 'react'
import Select, { components } from 'react-select'
import { useIntl } from 'react-intl'
import { dateStyles } from './styles'
import { IYearDropdownProps } from './types'

const { Input } = components

const DateDropdown = ({
  placeholder,
  value,
  isYear,
  options,
  change,
  name,
  label,
}: IYearDropdownProps) => {
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'

  const handleChange = (select: any) => {
    change(select)
  }

  const valueFill = () => {
    if (value) {
      if (value.value && value.value !== '') {
        return value
      }
    } else {
      return null
    }
  }

  //Defines a four-digit limit for years, and a two-digit limit for months and days
  const CustomInput = (props: any) => (
    <Input {...props} maxLength={isYear ? 4 : 2} type='tel' />
  )

  return (
    <div style={{ width: 100, margin: '0px 10px' }} data-cy={name}>
      <Select
        autoFocus={isYear}
        aria-label={label}
        isRtl={isRtl}
        menuPortalTarget={document.body}
        placeholder={placeholder}
        value={valueFill()}
        onChange={handleChange}
        styles={dateStyles}
        options={options}
        components={{ Input: CustomInput }}
      />
    </div>
  )
}

export default DateDropdown
