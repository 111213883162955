import styled from 'styled-components'
import { OuterContainerProps } from './types'

export const OuterContainer = styled.div<OuterContainerProps>`
  display: grid;
  grid-template-columns: auto 42rem;
  grid-template-rows: auto 1fr;
  padding: 3rem 5rem;
  min-height: calc(100vh - 75px);
  margin-top: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '50px' : '75px'};
  box-sizing: border-box;
  @media only screen and (max-width: 960px) {
    grid-template-columns: auto 30rem;
    padding: 3rem 2rem;
  }
  @media only screen and (max-width: 700px) {
    display: flex;
    margin-top: ${({ isNavbarScrolledDown }) =>
      isNavbarScrolledDown ? '90px' : '115px'};
    flex-direction: column;
    padding: 0 0 3rem;
  }
`
const Section = styled.div<{ visible: boolean }>`
  width: 100%;
  padding: 0 4rem;
  box-sizing: border-box;
  @media only screen and (max-width: 1280px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 700px) {
    display: ${(props) => (props.visible ? 'initial' : 'none')};
  }
`
export const ProfileSection = styled(Section)`
  border-right: 1px solid var(--border);
  @media only screen and (max-width: 700px) {
    border-right: none;
  }
  grid-column: 1 / 2;
  grid-row: 1 / 3;
`
export const PlatformsSection = styled(Section)`
  grid-column: 2 / 3;
  grid-row: 1 / 2;
`
export const SettingsSection = styled(Section)`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
`
