import React, { useContext } from 'react'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import LogoImage from '../../assets/icons/other/dff-logo.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMediaQuery } from '@material-ui/core'
import { OverlayContext } from '../../utils/ContextWrapper'
import useColorScheme from '../../utils/hooks/useColorScheme'
import { HeaderLogoProps } from './types'
import {
  BlockAnchor,
  FlexAnchor,
  Logo,
  LogoWrapper,
  ReturnLabel,
} from './styles'

const HeaderLogo = ({ isOpen, isNavbarScrolledDown }: HeaderLogoProps) => {
  const intl = useIntl()
  const isRtl = intl.locale === 'ar'
  const isTablet = useMediaQuery('(max-width: 960px)')
  const isMobile = useMediaQuery('(max-width: 700px)')
  const [isOverlayOpen] = useContext(OverlayContext)
  const colorScheme = useColorScheme()

  if (isOpen && isMobile) {
    return null
  }

  return (
    <LogoWrapper
      isMobile={isMobile}
      applyOffset={!isTablet && !isNavbarScrolledDown}
      isRtl={isRtl}
    >
      <BlockAnchor
        tabIndex={isOverlayOpen ? -1 : 0}
        aria-label='Link to Dubai Future Foundation Website'
        href={process.env.REACT_APP_DFF_REDIRECT_URI}
      >
        <Logo
          alt='Dubai Future Foundation Logo'
          colorScheme={colorScheme}
          src={LogoImage}
          isNavbarScrolledDown={isNavbarScrolledDown}
        />
      </BlockAnchor>
      <FlexAnchor
        tabIndex={isOverlayOpen ? -1 : 0}
        aria-label='Link to Dubai Future Foundation Website'
        href={process.env.REACT_APP_DFF_REDIRECT_URI}
      >
        <ArrowRightAltIcon />
        <ReturnLabel rtl={isRtl} isNavbarScrolledDown={isNavbarScrolledDown}>
          <FormattedMessage id='header.returnToSite' />
        </ReturnLabel>
      </FlexAnchor>
    </LogoWrapper>
  )
}

export default HeaderLogo
