import styled, { css } from 'styled-components'
import { ImageTagProps } from './types'
import { customStyles } from '../Dropdown/styles'
import React from 'react'

export const Description = styled.p`
  margin: 5px;
  text-align: center;
`

export const OuterAddContainer = styled.li`
  display: flex;
  color: var(--primary);
  flex-direction: column;
`

export const AddContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  .actionIconContainer {
    display: flex;
  }
`

export const ActionIconContainer = styled.div`
  display: flex;
`

export const ListItemDescription = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  margin: 5px 0;
`

export const InformationContainer = styled.div`
  color: var(--primary);
  display: grid;
  flex-direction: column;
  align-items: center;
  text-align: start;
  margin: 0 2rem;
`

export const ListItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 60%;
  @media (max-width: 480px) {
    height: 60px;
    width: 100%;
    max-width: 100%;
  }
`

export const Title = styled.h4`
  overflow: hidden;
  text-overflow: ellipsis;
  width: inherit;
  margin: 5px 0;
`

const CircleStyle = css`
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0.5rem;
  box-sizing: border-box;
  border: 2px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
`

export const Image = styled.img`
  ${CircleStyle}
`

export const ImageTag = styled.span<ImageTagProps>`
  font-size: ${({ theme }) => theme.fontSize.lg};
  text-transform: uppercase;
  color: ${({ isAdmin }) => (isAdmin ? 'var(--accent)' : 'var(--yellow)')};
`

export const ImageReplacement = styled.div`
  ${CircleStyle}
`

export const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  padding-top: ${({ theme }) => theme.spacing.lg};
`

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  height: 6rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
`

export const PendingMembersDescription = styled.p`
  text-align: center;
  margin: 5px;
  margin-bottom: 20px;
`
export const memberStyles = {
  ...customStyles,
  control: (provided: React.CSSProperties, { isSelected }: any) => ({
    ...provided,
    borderRadius: 0,
    background: 'var(--background)',
    border: '2px solid var(--primary) !important',
    boxShadow: isSelected ? '0 0 0 1px var(--primary) !important' : '',
    borderColor: 'var(--primary)',
    width: 120,
  }),
  container: (provided: React.CSSProperties, { isSelected }: any) => ({
    margin: '0px 2rem',
  }),
}
