import styled, { keyframes } from 'styled-components'
import { ContainerStyleProps, OverlayProps } from './types'

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    left: 20%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
`
export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100% !important;
  height: ${({ isOpen }) => (isOpen ? '100%' : '0%')};
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  z-index: ${({ zIndex }) => zIndex};
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  background: ${({ transparent }) => transparent && 'color'};
  padding: ${({ transparent, theme }) =>
    transparent &&
    `${theme.spacing.sm} ${theme.spacing.xs} 0 ${theme.spacing.xs}`};
  width: ${({ theme }) => `calc(100% - ${theme.spacing.sm})`};

  li {
    animation: ${fadeInRight} 0.5s ease forwards;
    animation-delay: 0.35s;
  }
`
export const Container = styled.div<ContainerStyleProps>`
  background: var(--background);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: ${({ transparent }) => transparent && 'width: 100%'};
`
export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`
