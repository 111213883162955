import { apiService } from '../../service/api/apiService'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { openToast } from '../../components/ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { removeLoginData } from '../../service/localStorage/userStorage'
import { useIntl } from 'react-intl'
import { AppContext } from '../../utils/ContextWrapper'

export const useAuthorize = () => {
  const history = useHistory()
  const intl = useIntl()
  const [{ authorizeData }, setAppState] = useContext(AppContext)
  const [user] = useLocalStorage<IUser>('user')
  const { appId, next } = authorizeData
  const [redirect, setRedirect] = useState('')

  useEffect(() => {
    if (redirect) {
      history.push(redirect)
    }
  }, [redirect, history])

  const authorize = useCallback(async () => {
    if (appId) {
      const data = await apiService.user.get(user?.id ?? '')
      await apiService.auth
        .getMandatoryProfileFields(appId)
        .then((mandatoryFields) => {
          setAppState((state) => ({ ...state, mandatoryFields }))
          const missingFields =
            data &&
            mandatoryFields.filter((field: string) =>
              Array.isArray(data[field])
                ? data[field].length === 0
                : !data[field],
            )
          if (missingFields.length) {
            setAppState((state) => ({
              ...state,
              authorizeData: {
                ...state.authorizeData,
                appId,
              },
              missingFields,
            }))
            setRedirect('/mandatory-fields')
          } else {
            apiService.auth.authorize(appId, next ?? '').then((url: string) => {
              window.location.assign(url)
            })
          }
        })
        .catch(({ response }) => {
          const showGenericFailureToast = () => {
            openToast({
              message: intl
                .formatMessage({ id: 'errors.genericFail' })
                .toString(),
              variant: 'failure',
            })
          }
          setAppState((state) => ({
            ...state,
            authorizeData: { appId: '', next: '', resetToken: false },
          }))
          if (response?.status === 404) {
            setRedirect('/')
            openToast({
              message: intl
                .formatMessage({ id: 'errors.wrongAppId' })
                .toString(),
              variant: 'failure',
            })
          } else if (response?.status === 401) {
            removeLoginData()
            setRedirect('/login')
            openToast({
              message: intl
                .formatMessage({ id: 'errors.tokenExpired' })
                .toString(),
              variant: 'failure',
            })
          } else {
            setRedirect('/')
            showGenericFailureToast()
          }
        })
    }
  }, [setAppState, user, intl, appId, next])

  return {
    authorize,
    isAuthorizeFlow: !!appId,
  }
}
