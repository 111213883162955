import styled from 'styled-components'
import { FilledButton } from '../../../components'

export const DefaultButton = styled(FilledButton)`
  margin: 1rem;
  min-width: 150px;
  padding: 10px;
`
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`
