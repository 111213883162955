import React, { useState } from 'react'
import { ConfirmOverlay, FilledButton } from '../../../components'
import {
  endAllSessions,
  getSessions,
  removeSession,
} from '../../../service/connections'
import { FormattedMessage, useIntl } from 'react-intl'
import useSWR, { mutate } from 'swr'
import { ListContainer } from '../../../components/Members/StyledComponents'
import Device from './Device'
import { openToast } from '../../../components/ToastMessage/ToastService'
import { renderErrorToast } from '../../../utils/errorToast'
import { DevicesOuterContainer } from './styles'
import { IConnectionDataItem } from '../../../service/types'

const DeviceMenu = () => {
  const { data: devices, mutate: mutateSessions } = useSWR('devices', () =>
    getSessions(),
  )
  const intl = useIntl()
  const [removeAllOverlay, setRemoveAllOverlay] = useState(false)
  const [
    deviceToRemove,
    setDeviceToRemove,
  ] = useState<IConnectionDataItem | null>(null)
  const removeDevice = async (id: string) => {
    devices &&
      mutateSessions(
        devices.filter((device) => device.id !== id),
        false,
      )
    try {
      await removeSession(id)
      openToast({
        message: intl.formatMessage({
          id: 'settings.devices.removeDeviceSuccess',
        }),
        variant: 'success',
      })
      setDeviceToRemove(null)
    } catch ({ response }) {
      renderErrorToast(response, 'settings.devices.removeDeviceFailure', intl)
    }
  }

  const handleRemove = (device: IConnectionDataItem) => () =>
    setDeviceToRemove(device)

  const handleConfirmRemoveAll = async () => {
    await endAllSessions()
    mutate('devices')
    setRemoveAllOverlay(false)
  }

  const handleRemoveConfirm = () => {
    if (deviceToRemove && !removeAllOverlay) {
      removeDevice(deviceToRemove?.id ?? '')
    } else {
      handleConfirmRemoveAll()
    }
  }

  const handleConfirmClose = () => {
    setDeviceToRemove(null)
    setRemoveAllOverlay(false)
  }

  const handleRemoveAll = () => setRemoveAllOverlay(true)

  return (
    <>
      <DevicesOuterContainer>
        <ListContainer>
          {devices?.map((device) => {
            return (
              <Device
                onRemove={handleRemove(device)}
                key={device.id}
                device={device}
              />
            )
          })}
        </ListContainer>
        <ConfirmOverlay
          open={!!deviceToRemove || removeAllOverlay}
          onConfirm={handleRemoveConfirm}
          title={intl.formatMessage({
            id: `devices.${removeAllOverlay ? 'removeAll' : 'removeTitle'}`,
          })}
          question={
            removeAllOverlay
              ? intl.formatMessage({ id: 'devices.removeAllQuestion' })
              : intl.formatMessage(
                  { id: 'devices.removeDevice' },
                  { device: deviceToRemove?.name ?? '' },
                )
          }
          onClose={handleConfirmClose}
        />
      </DevicesOuterContainer>
      <FilledButton onClick={handleRemoveAll}>
        <FormattedMessage id='devices.removeAll' />
      </FilledButton>
    </>
  )
}

export default DeviceMenu
