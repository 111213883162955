import React, { useEffect, useState } from 'react'
import { useSpring, animated } from 'react-spring'
import { ProgressBar } from '../../components/ProgressBar/ProgressBar'
import Div100vh from 'react-div-100vh'
import { Container, Title } from './styles'
import { useLocalStorage } from '@rehooks/local-storage'
import { FormattedMessage } from 'react-intl'
import { Redirect } from 'react-router'

const DURATION = 1500

const LandingPage = () => {
  const [redirect, setRedirect] = useState('')

  const [token] = useLocalStorage('token')
  const spring = useSpring({
    config: {
      duration: DURATION,
    },
    percentage: 100,
    from: {
      percentage: 0,
    },
  })

  useEffect(() => {
    const isLoggedIn = !!token
    const timeout = setTimeout(() => {
      if (isLoggedIn) {
        setRedirect('/dashboard')
      } else {
        setRedirect('/login')
      }
    }, DURATION)
    return () => clearTimeout(timeout)
  }, [token])

  const AnimatedProgressBar = animated(ProgressBar)

  if (redirect) return <Redirect to={redirect} />

  return (
    <Div100vh>
      <Container>
        <Title>
          <FormattedMessage id='landing.title' />
        </Title>
        <AnimatedProgressBar percent={spring.percentage} />
      </Container>
    </Div100vh>
  )
}

export default LandingPage
