import React, { useContext, useEffect, useState, useCallback } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { AuthFormView } from '../../components'
import { isEmailValid } from '../../utils/validation'
import Content from './Content'
import { useAuthorize } from '../authorize/useAuthorize'
import queryString from 'query-string'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../../components/ToastMessage/ToastService'
import {
  AuthDataContext,
  AppContext,
  TopLoadingBarContext,
} from '../../utils/ContextWrapper'
import axios from 'axios'
import RegisterProgress from '../../components/AuthForm/RegisterProgress'
import useDebounce from '../../utils/hooks/useDebounce'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { useIntl } from 'react-intl'
import { LocaleContext } from '../../App'
import { steps } from './constants'

const RegisterPage = () => {
  const location = useLocation()
  const intl = useIntl()
  const { token: inviteToken }: { token?: string } = queryString.parse(
    location.search,
  )
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const [hasRegisterStarted, setHasRegisterStarted] = useState(false)
  const [unusedEmail, setUnusedEmail] = useState(false)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [passwordStatus, setPasswordStatus] = useState(false)
  const [redirect, setRedirect] = useState('')
  const [name, setName] = useState<
    { firstName: string; lastName: string } | undefined
  >()
  const [step, setStep] = useState(steps.REGISTER)
  const [{ authorizeData }, setAppState] = useContext(AppContext)
  const [locale] = useContext(LocaleContext)
  const [{ email, password }, setAuthData] = useContext(AuthDataContext)
  const [user] = useLocalStorage<IUser>('user')
  const [token] = useLocalStorage<string>('token')

  useEffect(() => {
    // redirect if the user and token are set, but the register process has not started yet.
    if (user && token && !hasRegisterStarted) {
      setRedirect('/dashboard')
    }
  }, [user, token, hasRegisterStarted])

  const debouncedEmail = useDebounce(email)

  const stepsList = [
    steps.REGISTER,
    steps.CODE,
    steps.TFA,
    steps.INTRO,
    steps.COMPLETE,
  ]

  const [{ terms }] = useContext(AppContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  useEffect(() => {
    if (inviteToken) {
      apiService.invite
        .decodeUrl(inviteToken)
        .then(({ email, firstName, lastName }) => {
          setAuthData((authData) => ({ ...authData, email }))
          setName({ firstName, lastName })
        })
        .catch((err) => {
          setRedirect('/register')
        })
    }
  }, [inviteToken, setAuthData])

  const checkUnusedEmail = useCallback(
    async (val: string) => {
      try {
        setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
        await apiService.auth.checkEmail(val)
        setUnusedEmail(false)
      } catch (e) {
        setUnusedEmail(true)
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    },
    [setTopLoadingBar],
  )

  useEffect(() => {
    if (isEmailValid(debouncedEmail)) {
      checkUnusedEmail(debouncedEmail)
    }
  }, [debouncedEmail, checkUnusedEmail])

  const getCurrentStepIndex = () => stepsList.findIndex((s) => s === step)

  const isValid = () => {
    return (
      isEmailValid(email) &&
      password !== '' &&
      passwordStatus &&
      terms.accepted &&
      unusedEmail
    )
  }

  const handleNextStep = async (nextStep: steps) => {
    if (email && password && terms.accepted && nextStep === steps.CODE) {
      try {
        setHasRegisterStarted(true)
        setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
        await apiService.auth.register(
          email,
          password,
          authorizeData.appId,
          authorizeData.next,
        )
        setAuthData({ email, password })
        setStep(steps.CODE)
      } catch (e) {
        if (e?.response?.status === 400) {
          openToast({
            message: intl.formatMessage({ id: 'errors.insecurePassword' }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.genericFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    } else if (nextStep === steps.COMPLETE) {
      if (isAuthorizeFlow) {
        setAppState((state) => ({
          ...state,
          authorizeData: { ...state.authorizeData, isRegister: true },
        }))
        authorize()
      } else {
        if (oauth2Url) {
          axios.get(oauth2Url as string).catch((e) => setRedirect('/welcome'))
        } else {
          setRedirect('/welcome')
        }
      }
    } else {
      if (isValid()) {
        setStep(nextStep)
      }
    }
  }

  const handleEmailChange = (val: string, status: boolean) => {
    setAuthData((authData) => ({ ...authData, email: val }))
  }

  const handlePasswordChange = (val: string, status: boolean) => {
    setAuthData((authData) => ({ ...authData, password: val }))
    setPasswordStatus(status)
  }
  const handleVerificationSubmit = async (success: boolean) => {
    if (name && user) {
      const { firstName, lastName } = name
      apiService.user.update(user.id, {
        firstName,
        lastName,
        preferredLanguage: locale,
      })
    } else if (user) {
      apiService.user.update(user.id, {
        preferredLanguage: locale,
      })
    }
    if (success) {
      setStep(steps.TFA)
    }
  }

  const handleEnter = () => {
    handleNextStep(stepsList[getCurrentStepIndex() + 1])
  }

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <AuthFormView>
      <Content
        isEmailDisabled={!!inviteToken && !!email}
        step={step}
        emailExists={!unusedEmail}
        onChangeEmail={handleEmailChange}
        onChangePassword={handlePasswordChange}
        onVerificationSubmit={handleVerificationSubmit}
        onEnter={handleEnter}
        isValid={isValid()}
        currentIndex={getCurrentStepIndex()}
        stepLabels={stepsList}
        onNextStep={handleNextStep}
      />
      <RegisterProgress step={stepsList.findIndex((s) => s === step)} />
    </AuthFormView>
  )
}

export default RegisterPage
