import styled from 'styled-components'
import { ButtonProps } from './types'

export const Button = styled.button<ButtonProps>`
  background-color: var(--background);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 2px solid var(--primary);
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: var(--darkgrey);
  }
  svg,
  path {
    fill: var(--primary);
    width: 2rem;
    height: 2rem;
    padding: 0;
  }
  box-sizing: content-box;
  padding: 0;
`
