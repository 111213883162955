import React, {
  ReactNode,
  useEffect,
  useState,
  useCallback,
  useContext,
} from 'react'
import { FormContainer } from '../../../../components'
import Content from './Content'
import styled from 'styled-components'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../../models/IUser'
import { useIntl } from 'react-intl'
import { apiService } from '../../../../service/api/apiService'
import useSWR from 'swr'
import { openToast } from '../../../../components/ToastMessage/ToastService'
import { AuthDataContext } from '../../../../utils/ContextWrapper'
import { Steps } from './constants'

const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
interface ITwoFactorAuthProps {
  children?: ReactNode
  open: boolean
  onClose: () => void
  isRegister?: boolean
}

const getStepsList = (isEnabled: boolean, isRegister: boolean) => {
  if (isEnabled) {
    return isRegister ? [Steps.BACK] : [Steps.GENERAL, Steps.DISABLE]
  } else {
    return isRegister
      ? [Steps.ENABLE, Steps.TOKEN, Steps.BACK]
      : [Steps.GENERAL, Steps.ENABLE, Steps.TOKEN, Steps.BACK]
  }
}

type TfaState = {
  otp: string
  qr: string
  backupCode: string
  password: string
  isEnabled: boolean
  step: Steps
}
export const TfaContext = React.createContext<
  [TfaState, React.Dispatch<React.SetStateAction<TfaState>>]
>([
  {
    otp: '',
    backupCode: '',
    qr: '',
    password: '',
    isEnabled: false,
    step: Steps.GENERAL,
  },
  () => ({
    otp: '',
    backupCode: '',
    qr: '',
    password: '',
    isEnabled: false,
    step: Steps.GENERAL,
  }),
])

const TwoFactorAuth = ({
  open,
  onClose,
  children,
  isRegister,
}: ITwoFactorAuthProps) => {
  const intl = useIntl()
  const [user] = useLocalStorage<IUser>('user')
  const { data } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )
  const [{ password: registerPassword = '' }] = useContext(AuthDataContext)
  const [tfaState, setTfaState] = useState({
    otp: '',
    qr: '',
    backupCode: '',
    password: isRegister ? registerPassword : '',
    isEnabled: false,
    step: isRegister ? Steps.ENABLE : Steps.GENERAL,
  })
  const stepsList = getStepsList(tfaState.isEnabled, !!isRegister)

  const handleClose = useCallback(() => {
    setTfaState((state) => ({ ...state, step: Steps.GENERAL, password: '' }))
    onClose()
  }, [onClose])

  const getOtp = useCallback(async () => {
    if (user) {
      try {
        const { url } = await apiService.user.getOtp({
          email: user.email,
          password: tfaState.password,
        })
        const otp: string = url.split('secret=')[1]
        const qr = (await apiService.user.getQr(url)) ?? ''
        setTfaState((state) => ({
          ...state,
          step: state.isEnabled ? Steps.DISABLE : Steps.ENABLE,
          otp,
          qr,
        }))
      } catch ({ response }) {
        if (response?.status === 401) {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.tfaPasswordFail' }),
          })
        } else {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.genericFail' }),
          })
        }
      }
    }
  }, [tfaState.password, intl, user])

  useEffect(() => {
    setTfaState((state) => ({
      ...state,
      isEnabled: data ? data.tfaEnabled : false,
    }))
  }, [data])

  const handleNextStep = (nextStep: Steps) => {
    if (tfaState.step === Steps.GENERAL) {
      if (tfaState.isEnabled) {
        setTfaState((state) => ({ ...state, step: Steps.DISABLE }))
      } else {
        getOtp()
      }
    } else if (tfaState.step === Steps.TOKEN) {
      onClose()
    }
  }

  //Make sure that on register, getOtp gets executed.
  useEffect(() => {
    if (isRegister) {
      getOtp()
    }
  }, [isRegister, getOtp])

  const handleEnter = () => {
    handleNextStep(stepsList[stepsList.indexOf(tfaState.step as Steps) + 1])
  }

  /** Show next button if is register and current step is token */
  const showNextStepButton =
    (tfaState.step === Steps.GENERAL ? true : !!isRegister) &&
    tfaState.step !== Steps.TOKEN

  const ContentElement = (
    <Content
      isRegister={isRegister}
      onEnter={handleEnter}
      onCancel={onClose}
      isValid
    />
  )

  return (
    <OuterContainer>
      <TfaContext.Provider value={[tfaState, setTfaState]}>
        {isRegister ? (
          <>{ContentElement}</>
        ) : (
          <FormContainer
            isOpen={open}
            onClose={handleClose}
            title={intl.formatMessage({ id: 'settings.twofactor.title' })}
            onNextStep={handleNextStep}
            showNextButton={showNextStepButton}
            isValid
            stepLabels={stepsList}
            currentIndex={stepsList.indexOf(tfaState.step as Steps)}
          >
            {ContentElement}
          </FormContainer>
        )}
        {children}
      </TfaContext.Provider>
    </OuterContainer>
  )
}

export default TwoFactorAuth
