export enum TutorialSteps {
  PROFILE = 'tutorial-steps-profile',
  ORGANISATION = 'tutorial-steps-organisations',
  PLATFORMS = 'tutorial-steps-platforms',
  SETTINGS = 'tutorial-steps-settings',
  HOWTO = 'tutorial-steps-howto',
}

export enum MobileTutorialSteps {
  PROFILE = 'mobile-tutorial-steps-profile',
  PLATFORMS = 'mobile-tutorial-steps-platforms',
  SETTINGS = 'mobile-tutorial-steps-settings',
}
