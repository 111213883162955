import styled from 'styled-components'
import { FilledButton } from '../../components'

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin-bottom: 5rem;
`
export const Form = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const StyledButton = styled(FilledButton)`
  margin-top: 5rem;
`
