import styled from 'styled-components'

export const StyledReadOnlyImage = styled.img`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing.sm};
`
export const SubHeading = styled.div`
  color: var(--primary);
  font-size: ${({ theme }) => theme.fontSize.md};
  margin-bottom: 5rem;
  text-align: center;
  text-transform: uppercase;
  @media (max-height: 520px) {
    margin: 1rem 0rem;
  }
`
export const Placeholder = styled.div<{ show?: boolean }>`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid var(--primary);
  text-align: center;
  z-index: 1;
  display: ${({ show }) => (show ? 'block' : 'none')};
  svg {
    fill: var(--primary);
    width: 50%;
    margin-top: 25%;
  }
`
export const Preview = styled.div<{ show?: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ show }) => (show ? 'block' : 'none')};
  z-index: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
`
export const Display = styled.div`
  border-radius: 50%;
  position: relative;
  transition: background 0.2s ease-in-out;

  width: 14rem;
  height: 14rem;

  @media (max-height: 520px) {
    height: 10rem;
    width: 10rem;
  }

  input {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }

  @media screen and (min-width: 480px) {
    &:hover {
      margin: auto;
      border-radius: 50%;
      text-align: center;
      background: var(--primary);

      ${Placeholder} {
        display: block;

        svg path {
          fill: var(--background);
        }
      }

      ${Preview} {
        display: none;
      }
    }
  }
`
