import React from 'react'
import { ReactComponent as Remove } from '../../../assets/icons/other/icon_plus.svg'
import { ReactComponent as Desktop } from '../../../assets/icons/settings/settings_computer.svg'
import { ReactComponent as Mobile } from '../../../assets/icons/settings/settings_mobile.svg'
import {
  ActionContainer,
  Container,
  DeleteButton,
} from '../../../components/Members/StyledComponents'
import ListItem from '../../../components/Members/ListItem'
import { DateTime } from 'luxon'
import { useIntl } from 'react-intl'
import { ImageContainer } from './styles'
import { IDeviceProps } from './types'

const Device = ({ device, onRemove }: IDeviceProps) => {
  const intl = useIntl()
  const date = DateTime.fromISO(device.lastSeenAt)
    .toJSDate()
    .toLocaleDateString()
  const time = DateTime.fromISO(device.lastSeenAt).toLocaleString(
    DateTime.TIME_SIMPLE,
  )

  const description = intl.formatMessage(
    { id: 'devices.description' },
    { time, date },
  )

  return (
    <Container>
      <ListItem
        title={device.name}
        Image={
          <ImageContainer>
            {device.deviceInfo.platform.type === 'mobile' ? (
              <Mobile />
            ) : (
              <Desktop />
            )}
          </ImageContainer>
        }
        isAdmin
        description={description.toString()}
      />
      <ActionContainer>
        <DeleteButton title='Remove' onClick={onRemove}>
          <Remove title='Remove' />
        </DeleteButton>
      </ActionContainer>
    </Container>
  )
}

export default Device
