import { Text } from './StyledComponents'
import { FormattedMessage } from 'react-intl'
import React from 'react'
import { RoleDisplayProps } from './types'

export const RoleDisplay = ({ isAdmin }: RoleDisplayProps) =>
  isAdmin ? (
    <Text isAdmin>
      <FormattedMessage id='components.members.admin' />
    </Text>
  ) : (
    <Text>
      <FormattedMessage id='components.members.member' />
    </Text>
  )
