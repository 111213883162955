import { getLanguageCookie } from '../utils/cookies'
import messages_ar from '../translations/translations_flattened_ar.json'
import messages_en from '../translations/translations_flattened_en.json'

export const messages: any = {
  ar: messages_ar,
  en: messages_en,
}
export const styledComponentsTheme = {
  fontSize: {
    xs: '10px',
    sm: '12px',
    md: '16px',
    lg: '24px',
    xl: '28px',
    xxl: '48px',
  },
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '64px',
  },
}
export const language =
  getLanguageCookie() || navigator.language.split(/[-_]/)[0]
