import React from 'react'
import { FormattedMessage } from 'react-intl'

import { ReadOnly } from '../ReadOnly/ReadOnly'
import { StyledTextArea, TextAreaContainer, Words } from './styles'
import { ITextAreaProps } from './types'

const TextArea = ({
  readonly = false,
  maxLength = 200,
  value,
  onChange,
  label,
  placeholder,
  ...props
}: ITextAreaProps) => {
  return readonly ? (
    <ReadOnly value={value} fieldName={label} />
  ) : (
    <TextAreaContainer>
      <StyledTextArea
        autoFocus
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        {...(props as any)}
      />
      <Words>
        {`${value?.toString()?.length ?? 0} | ${maxLength} `}
        <FormattedMessage id='textInput.letters' />
      </Words>
    </TextAreaContainer>
  )
}

export { TextArea }
