import React, { useState } from 'react'
import { apiService } from '../../service/api/apiService'
import { FormContainer } from '../'
import Content from './Content'
import { openToast } from '../ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { useIntl } from 'react-intl'
import { Redirect } from 'react-router'
import { steps } from './constants'

const UpdateEmail = () => {
  const [redirect, setRedirect] = useState('')
  const intl = useIntl()
  const [newEmail, setNewEmail] = useState('')
  const [validEmail, setValidEmail] = useState(false)
  const [step, setStep] = useState<steps>(steps.CHANGE)
  const [token, setToken] = useState<string>()
  const stepsList = [steps.CHANGE, steps.CONFIRM]
  const [user, setUser] = useLocalStorage<IUser>('user')

  const handleUpdateVerificationCode = async (
    code: string,
    status: boolean,
  ) => {
    if (status && token && user) {
      try {
        await apiService.user.changeEmail(user.id, token, code)
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'auth.updateEmail.successMessage',
          }),
        })
        setUser({ ...user, email: newEmail })
        setRedirect('/dashboard')
      } catch (e) {
        openToast({
          variant: 'failure',
          message: intl.formatMessage({ id: 'errors.genericFail' }),
        })
      }
    }
  }

  const handleResend = async () => {
    if (!user) {
      console.error('cant retrieve user')
      return
    }
    const { token } = await apiService.user.changeEmailRequest(
      user.id,
      newEmail,
    )
    openToast({
      variant: 'success',
      message: intl.formatMessage({
        id: 'auth.updateEmail.resendSuccess',
      }),
    })

    setToken(token)
  }

  const handleEmailChange = (val: string, status: boolean) => {
    setNewEmail(val)
    setValidEmail(status)
  }

  const getCurrentStepIndex = () => {
    switch (step) {
      case steps.CHANGE:
        return 0
      case steps.CONFIRM:
        return 1
      default:
        throw new Error('invalid step reached')
    }
  }

  const handleNextStep = async () => {
    switch (getCurrentStepIndex()) {
      case 0: {
        if (!user) {
          console.error('cant retrieve user')
          break
        }
        if (user.email === newEmail) {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.sameEmail' }),
          })
          break
        }
        //Call update email request
        try {
          const { token } = await apiService.user.changeEmailRequest(
            user.id,
            newEmail,
          )
          setToken(token)
          setStep(steps.CONFIRM)
        } catch (e) {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.genericFail' }),
          })
        }
        break
      }

      default:
        break
    }
  }

  const handlePrevStep = () => {
    const prev = stepsList[getCurrentStepIndex() + -1]
    setStep(prev)
  }

  const handleClose = () => setRedirect('/dashboard')

  if (redirect) return <Redirect to={redirect} />

  return (
    <div>
      <FormContainer
        isOpen
        title={intl.formatMessage({ id: step })}
        isValid={step === steps.CHANGE && validEmail}
        onPrevStep={(step: any) => handlePrevStep()}
        onNextStep={(step: any) => handleNextStep()}
        stepLabels={stepsList}
        onClose={handleClose}
        showNextButton={step !== steps.CONFIRM}
        currentIndex={getCurrentStepIndex()}
      >
        <Content
          step={step}
          onResend={handleResend}
          currentEmail={user?.email}
          newEmail={newEmail}
          onChange={handleEmailChange}
          onChangeCode={handleUpdateVerificationCode}
          onEnter={handleNextStep}
        />
      </FormContainer>
    </div>
  )
}
export { UpdateEmail }
