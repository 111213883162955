import React, { useContext } from 'react'
import { ReactComponent as MenuIcon } from '../../assets/icons/other/icon_vertical-menu.svg'
import { useIntl } from 'react-intl'
import { OverlayContext } from '../../utils/ContextWrapper'
import { IActionButtonProps } from './types'
import { ActionBtn, MenuAction } from './styles'

const ActionButton = ({ onAction }: IActionButtonProps) => {
  const [isOverlayOpen] = useContext(OverlayContext)
  const intl = useIntl()
  const rtl = intl.locale === 'ar'
  return (
    <MenuAction rtl={rtl}>
      <ActionBtn
        tabIndex={isOverlayOpen ? -1 : 0}
        aria-label='Toggle Settings Menu'
        onClick={onAction}
      >
        <MenuIcon title='More...' />
      </ActionBtn>
    </MenuAction>
  )
}

export default ActionButton
