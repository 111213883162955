import React, { useState } from 'react'
import { ReactComponent as Visible } from '../../assets/icons/other/icon_eye-open.svg'
import { ReactComponent as Invisible } from '../../assets/icons/other/icon_eye-closed.svg'
import { CustomInput } from './Input'
import { FormattedMessage } from 'react-intl'
import PasswordScale from './PasswordScale'
import { isPasswordValid, checkPassword } from '../../utils/validation'
import {
  PasswordContainer,
  VisibilityButton,
  ErrorTextPassword,
} from './styles'
import { PasswordProps } from './types'

const Password = ({
  fluid = true,
  password,
  showPasswordStrength,
  handlePasswordChange,
  ...props
}: PasswordProps) => {
  const [visible, setVisible] = useState(false)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handlePasswordChange(e.target.value, checkPassword(e.target.value))
  }

  const toggleVisibility = () => setVisible((v) => !v)
  return (
    <PasswordContainer>
      <CustomInput
        aria-label='Password'
        {...props}
        fluid={fluid}
        value={password}
        type={visible ? 'text' : 'password'}
        onChange={handleChange}
        endAdornment={
          <VisibilityButton
            type='button'
            aria-label='Toggle Password Visibility'
            onClick={toggleVisibility}
          >
            {visible ? (
              <Invisible
                title='Hide Password'
                style={{
                  width: 30,
                  height: 30,
                  fill: 'var(--primary)',
                  cursor: 'pointer',
                }}
              />
            ) : (
              <Visible
                title='Show Password'
                style={{
                  width: 30,
                  height: 30,
                  fill: 'var(--primary)',
                  cursor: 'pointer',
                }}
              />
            )}
          </VisibilityButton>
        }
      />
      {password !== '' && !isPasswordValid(password) && (
        <ErrorTextPassword>
          <FormattedMessage id='errors.tfaPasswordFail' />
        </ErrorTextPassword>
      )}
      {showPasswordStrength && (
        <PasswordScale password={isPasswordValid(password) ? password : ''} />
      )}
    </PasswordContainer>
  )
}

export default Password
export { Password }
