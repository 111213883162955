import { customStyles } from '../Dropdown/styles'
import React from 'react'
import styled from 'styled-components'

export const chipStyles = {
  ...customStyles,
  indicatorsContainer: (): React.CSSProperties => ({
    width: 0,
    height: 0,
  }),
  dropdownIndicator: (
    provided: React.CSSProperties,
    { isRtl }: any,
  ): React.CSSProperties => ({
    ...provided,
    visibility: 'hidden',
  }),
}
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 50rem;
  min-height: 61px;
  overflow: hidden auto;

  label {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
`

export const InputContainer = styled.div`
  direction: row;
  display: flex;
  align-items: center;
`
