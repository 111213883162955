import React, { useEffect, useState } from 'react'
import DecisionItem from './DecisionItem'
import queryString from 'query-string'
import { Redirect, useLocation } from 'react-router'
import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import { useIntl } from 'react-intl'
import {
  BtnContainer,
  CompanyProfileIcon,
  Container,
  Description,
  Divider,
  ProfileIcon,
  StyledButton,
  Title,
} from './styles'

const Decision = () => {
  const intl = useIntl()
  const location = useLocation()
  const { y } = useScrollPosition()
  const [redirect, setRedirect] = useState('')

  const isScrolled = y !== 0

  const {
    oauth2_client_name: clientName,
    oauth2_scope: scope,
    transaction_id: transactionId,
  } = queryString.parse(location.search)

  useEffect(() => {
    if (!clientName || !scope || !transactionId) {
      setRedirect('/dashboard')
    }
  }, [clientName, scope, transactionId])

  const scopeList: string[] | undefined = (scope as string | undefined)?.split(
    '+',
  )
  const transformScope = (scopePart: 'user' | 'organisation' | 'openid') =>
    scopeList
      ?.filter((item) => item.includes(scopePart))
      .map((item) => item.split('.')[1])
  const userScope = transformScope('user')
  const organisationScope = transformScope('organisation')

  const handleAccept = () => {
    window.location.href =
      (process.env.REACT_APP_BACKEND_AUTH_URL ?? '') +
      `/api/v1/oauth2/authorize/decision?transaction_id=${transactionId}`
  }

  const handleDeny = () => {
    localStorage.clear()
    setRedirect('/')
  }

  const translate = (id: string) => {
    return intl.formatMessage({ id }).toString()
  }

  if (redirect) return <Redirect to={redirect} />

  return (
    <Container isNavbarScrolledDown={isScrolled}>
      <Title>
        {clientName} {translate('components.decision.title')}
      </Title>
      <Divider />
      <DecisionItem
        Icon={ProfileIcon}
        description={translate('components.decision.profileDescription')}
        claims={userScope}
      />
      <Divider />
      <DecisionItem
        Icon={CompanyProfileIcon}
        description={translate('components.decision.organisationDescription')}
        claims={organisationScope}
      />
      <Divider />
      <Description>
        {translate('components.decision.acceptDescription')}
      </Description>
      <BtnContainer>
        <StyledButton btnType='primary' onClick={handleAccept}>
          {translate('components.decision.accept')}
        </StyledButton>
        <StyledButton onClick={handleDeny}>
          {translate('components.decision.deny')}
        </StyledButton>
      </BtnContainer>
    </Container>
  )
}

export default Decision
