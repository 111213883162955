import React, { useState } from 'react'
import { ReactComponent as Remove } from '../../assets/icons/other/icon_plus.svg'
import { ReactComponent as Check } from '../../assets/icons/other/icon_check.svg'
import { ReactComponent as Resend } from '../../assets/icons/other/icon_redo.svg'
import { useIntl } from 'react-intl'
import { useMediaQuery } from '@material-ui/core'
import RoleSelect from './RoleSelect'
import { apiService } from '../../service/api/apiService'
import { renderErrorToast } from '../../utils/errorToast'
import { openToast } from '../ToastMessage/ToastService'
import { trigger } from 'swr'
import {
  AcceptButton,
  ActionContainer,
  Container,
  DeleteButton,
} from './StyledComponents'
import ListItem from './ListItem'
import MemberImage from './MemberImage'
import useLocalStorage from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { IPendingMemberProps } from './types'

const PendingMember = ({
  name,
  email,
  editable,
  imageSrc,
  requestId,
  isInvitation,
  organisationId,
}: IPendingMemberProps) => {
  const intl = useIntl()
  const [isSelected, setIsSelected] = useState(false)
  const [user] = useLocalStorage<IUser>('user')
  const isMobile = useMediaQuery('(max-width:701px)')
  const [role, setRole] = useState({
    value: 'member',
    label: intl.formatMessage({ id: 'components.members.member' }),
  })

  const handleResend = async () => {
    try {
      await apiService.organisation.invite(organisationId, email)
      openToast({
        message: intl.formatMessage({
          id: 'components.joinRequest.resendSuccess',
        }),
        variant: 'success',
      })
    } catch ({ response }) {
      renderErrorToast(response, 'errors.genericFail', intl)
    }
  }

  const handleDecide = (accept: boolean) => async () => {
    if (isInvitation) {
      try {
        await apiService.organisation.deleteInvitation(
          organisationId,
          requestId,
        )
        openToast({
          message: intl.formatMessage({
            id: 'components.joinRequest.deleteInviteSuccess',
          }),
          variant: 'success',
        })
      } catch ({ response }) {
        renderErrorToast(response, 'errors.genericFail', intl)
      } finally {
        user && trigger(['organisations', user.id])
      }
    } else {
      try {
        await apiService.organisation.decideRequest(
          requestId,
          accept,
          role.value === 'admin',
        )
        openToast({
          message: intl.formatMessage(
            accept
              ? { id: 'components.joinRequest.successAccept' }
              : { id: 'components.joinRequest.successDeny' },
          ),
          variant: 'success',
        })
      } catch ({ response }) {
        renderErrorToast(response, 'errors.genericFail', intl)
      } finally {
        user && trigger(['organisations', user.id])
      }
    }
  }

  const handleRoleChange = (newRole: { value: string; label: string }) => {
    setRole(newRole)
  }

  return (
    <Container onClick={() => setIsSelected((selected) => !selected)}>
      <ListItem
        title={name}
        Image={
          <MemberImage title={name} description={email} imageSrc={imageSrc} />
        }
        description={email}
      />
      {(!isMobile || isSelected) && editable && (
        <ActionContainer>
          {!isInvitation && (
            <RoleSelect role={role} changeRole={handleRoleChange} />
          )}
          <DeleteButton
            type='button'
            title='Reject'
            onClick={handleDecide(false)}
          >
            <Remove title='Reject' />
          </DeleteButton>
          {isInvitation && (
            <AcceptButton
              type='button'
              title='Resend Invitation'
              onClick={handleResend}
            >
              <Resend title='Resend Invitation' />
            </AcceptButton>
          )}
          {!isInvitation && (
            <AcceptButton
              type='button'
              title='Accept'
              onClick={handleDecide(true)}
            >
              <Check title='Accept' />
            </AcceptButton>
          )}
        </ActionContainer>
      )}
    </Container>
  )
}

export default PendingMember
