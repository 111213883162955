import React from 'react'
import { LoginRegisterForm } from '../../components'
import TfaLogin from './TfaLogin'
import { steps } from './constants'
import { IContentProps } from './types'

const Content = ({
  step,
  onChangeEmail,
  onChangePassword,
  onEnter,
  stepLabels,
  isValid,
  currentIndex,
  onNextStep,
}: IContentProps) => {
  if (step === steps.EMAILPASSWORD) {
    return (
      <LoginRegisterForm
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        disabled={!isValid}
        stepLabels={stepLabels}
        currentIndex={currentIndex}
        onNextStep={onNextStep}
        loginForm
      />
    )
  } else if (step === steps.TWOFACTORTOKEN) {
    return <TfaLogin />
  } else {
    return <></>
  }
}

export default Content
