import React, { useState } from 'react'
import { IconLabelBtn, SectionHeader, FormContainer } from '../../../components'
import { ISettingsObj } from '../types'
import Options from '../../../assets/data/settingsOptions.json'
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch'
import DeleteAccount from '../DeleteAccount/DeleteAccount'
import TwoFactorAuth from './TwoFactorAuth/TwoFactorAuth'
import ThemeSwitch from '../ThemeSwitch/ThemeSwitch'
import { Section, SectionItem } from '../../dashboard/Section'
import Devices from '../devices/Devices'
import { useIntl } from 'react-intl'
import { TutorialSteps } from '../../../components/Tutorial/tutorialTypes'
import { SettingsMenuProps } from './types'

const SettingsMenu = ({ restartTutorial }: SettingsMenuProps) => {
  const intl = useIntl()
  const [genericOverlayVisible, setGenericOverlayVisible] = useState(false)
  const [currentStep, setCurrentStep] = useState<ISettingsObj>()
  const [deleteOverlayVisible, setDeleteOverlayVisible] = useState(false)
  const [tfaOverlayVisible, setTfaOverlayVisible] = useState(false)

  const handleClick = (step: ISettingsObj) => {
    if (step.name === 'language') {
      step.value = intl.locale ?? 'en'
    } else if (step.name === 'twoFactorAuthentication') {
      setTfaOverlayVisible(true)
    } else if (step.name === 'deleteAccount') {
      setDeleteOverlayVisible(true)
    } else if (step.name === 'tutorial') {
      restartTutorial()
    } else {
      step.value = ''
    }

    if (
      step.name !== 'twoFactorAuthentication' &&
      step.name !== 'deleteAccount' &&
      step.name !== 'tutorial'
    ) {
      setGenericOverlayVisible(true)
      setCurrentStep(step)
    }
  }

  const getOverlayContent = () => {
    if (!currentStep) {
      return null
    }

    switch (currentStep.name) {
      case 'language':
        return <LanguageSwitch />
      case 'changeTheme':
        return <ThemeSwitch />
      case 'devices':
        return <Devices />
      default:
        return null
    }
  }

  const twoFactorOverlayClose = () => setTfaOverlayVisible(false)

  return (
    <>
      <SectionHeader
        title={intl.formatMessage({ id: 'settings.header' })}
        hideMobile
        id={TutorialSteps.SETTINGS}
      />
      <Section>
        {Options &&
          Options.map((settings: ISettingsObj, i) => {
            return (
              <SectionItem
                key={settings.name}
                className={
                  settings.name === 'tutorial' ? TutorialSteps.HOWTO : ''
                }
                id={settings.name === 'tutorial' ? TutorialSteps.HOWTO : ''}
              >
                <IconLabelBtn
                  completed
                  iconFileName={settings.iconName}
                  label={intl.formatMessage({ id: settings.label || '' })}
                  onClick={() => handleClick(settings)}
                />
              </SectionItem>
            )
          })}
      </Section>

      {currentStep && (
        <FormContainer
          isOpen={genericOverlayVisible}
          title={intl.formatMessage({
            id: currentStep.label,
            defaultMessage: '',
          })}
          isValid
          onClose={() => setGenericOverlayVisible(false)}
          currentIndex={0}
          stepLabels={['']}
          hasActionButtons={false}
        >
          {getOverlayContent()}
        </FormContainer>
      )}

      <TwoFactorAuth open={tfaOverlayVisible} onClose={twoFactorOverlayClose} />
      <DeleteAccount
        open={deleteOverlayVisible}
        handleClose={() => setDeleteOverlayVisible(false)}
      />
    </>
  )
}

export default React.memo(SettingsMenu)
