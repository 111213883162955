import React, { lazy, Suspense } from 'react'
import { useIntl } from 'react-intl'
import { steps } from './constants'
import { ConfirmPasswordsContainer } from './styles'
import { IContentProps } from './types'

const Password = lazy(() => import('../../components/TextInput/Password'))

const Content = ({
  step,
  currentPassword,
  newPassword,
  retypedPassword,
  onChangeCurrent,
  onChangeNewPassword: onChangeNew,
  onChangeRetypedPassword: onChangeRetype,
  onEnter,
}: IContentProps) => {
  const intl = useIntl()
  const handleCurrentPasswordChange = (val: string, status: boolean) => {
    onChangeCurrent(val, status)
  }

  const handleNewPasswordChange = (val: string, status: boolean) => {
    onChangeNew(val, status)
  }

  const handleRetypedPasswordChange = (val: string, status: boolean) => {
    onChangeRetype(val, status)
  }

  if (step === steps.CURRENT) {
    return (
      <Suspense fallback='Loading...'>
        <Password
          fluid
          handlePasswordChange={handleCurrentPasswordChange}
          placeholder={intl.formatMessage({
            id: 'auth.updatePassword.current',
          })}
          password={currentPassword}
        />
      </Suspense>
    )
  } else if (step === steps.NEW) {
    return (
      <ConfirmPasswordsContainer>
        <Password
          fluid
          showPasswordStrength
          handlePasswordChange={handleNewPasswordChange}
          password={newPassword}
          placeholder={intl.formatMessage({ id: 'auth.updatePassword.enter' })}
        />
        <Password
          fluid
          showPasswordStrength
          placeholder={intl.formatMessage({
            id: 'auth.updatePassword.confirm',
          })}
          password={retypedPassword}
          handlePasswordChange={handleRetypedPasswordChange}
        />
      </ConfirmPasswordsContainer>
    )
  }

  return null
}

export default Content
