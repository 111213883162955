import React from 'react'
import { ReactComponent as Female } from '../../assets/icons/personal/personal_female.svg'
import { ReactComponent as Male } from '../../assets/icons/personal/personal_male.svg'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { Props } from './types'
import { Container, StyledButton } from './styles'

const Gender = ({ editable, value = '', change, list, label }: Props) => {
  const handleClick = (key: string) => () => {
    change(key, true)
  }

  return editable ? (
    <Container>
      {list &&
        list.map((listItem, index) => (
          <StyledButton
            autoFocus={index === 0}
            aria-label={listItem.code === 'm' ? 'Male' : 'Female'}
            type='button'
            btnType={value === listItem.code ? 'primary' : 'default'}
            onClick={handleClick(listItem.code)}
            key={listItem.code}
          >
            {listItem.code === 'm' && <Male title='Male' />}
            {listItem.code === 'f' && <Female title='Female' />}
            <span>{listItem.name}</span>
          </StyledButton>
        ))}
    </Container>
  ) : (
    <ReadOnly value={value} fieldName={label} />
  )
}

export { Gender }
