import styled from 'styled-components'
import { IconButton } from '../IconButton/IconButton'

export const AcceptButton = styled(IconButton)`
  svg,
  path {
    fill: var(--primary);
    width: 2rem;
    height: 2rem;
  }
  margin-left: 1rem;
`
export const DeleteButton = styled(AcceptButton)`
  transform: rotate(45deg);
`

export const Container = styled.li`
  width: calc(100% - 2rem);
  display: flex;
  flex-direction: row;
  margin: 1rem 2rem;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid var(--darkgrey);
  padding-bottom: 2rem;
  @media (max-width: 480px) {
    flex-direction: column;
  }
  ${AcceptButton}, ${DeleteButton} {
    opacity: 0;
  }

  &:hover {
    ${AcceptButton}, ${DeleteButton} {
      opacity: 1;
    }
  }
`

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    width: 100%;
    justify-content: space-around;
    margin: 2rem 0rem;
  }
`

export const Text = styled.span<{ isAdmin?: boolean }>`
  text-align: end;
  color: ${({ isAdmin }) => (isAdmin ? 'var(--accent)' : 'var(--yellow)')};
  margin: 0 2rem;
`

export const Divider = styled.div`
  background: var(--darkgrey);
  height: 1px;
  width: calc(100% - 2rem);
  margin: 0rem 1rem;
`

export const ListContainer = styled.ul`
  color: var(--primary);
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50px;
  margin: 0;

  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  @media (max-width: 480px) {
    margin-top: ${({ theme }) => theme.spacing.sm};
  }
`
