import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CustomInput } from './Input'
import { EmailContainer, ErrorText } from './styles'
import { EmailProps } from './types'
import { emailRegex } from './constants'

export const Email = ({
  fluid = true,
  email = '',
  handleEmailChange,
  status,
  disableDebounce,
  error,
  ...props
}: EmailProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleEmailChange(e.target.value, true)
  }

  const isEmailValid = email === '' || emailRegex.test(email)
  return (
    <EmailContainer fluid={fluid}>
      <CustomInput
        aria-label='Email'
        autoFocus
        fluid={fluid}
        value={email}
        status={status}
        type='email'
        onChange={handleChange}
        {...props}
      />
      {email !== '' && (
        <ErrorText>
          {isEmailValid ? error : <FormattedMessage id='errors.emailInvalid' />}
        </ErrorText>
      )}
    </EmailContainer>
  )
}
