import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'

export const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`
export const StyledButton = styled(FilledButton)`
  svg {
    width: 30px;
    height: 30px;
    padding-right: 10px !important;
  }
  margin: 0 ${({ theme }) => theme.spacing.md};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  @media (max-width: 700px) {
    margin: ${({ theme }) => theme.spacing.sm} 0;
  }
`
