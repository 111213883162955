import styled, { css, keyframes } from 'styled-components'
import { FilledButton } from '../../components'
import { Carousel } from 'react-responsive-carousel'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 73px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: bold;
  margin: 0;
  @media (max-width: 700px) {
    font-size: 2.4rem;
  }
`
const fadeInKeyframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`
export const StyledCarousel = styled(Carousel)`
  display: none;
  margin: 5rem 0 3rem;
  width: 80%;
  max-width: 25rem;
  direction: initial;
  li {
    background: transparent !important;
  }
  @media (max-width: 700px) {
    display: flex;
  }
`
export const Steps = styled.div<{ rtl?: boolean }>`
  display: flex;
  flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
  margin: 7rem 0 5rem;
  max-width: 100rem;
  width: 100%;
  @media (max-width: 700px) {
    display: none;
  }
`
export const Step = styled.div<{ index: number; animated?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-basis: 33%;
  align-items: center;
  animation: ${({ animated }) =>
    animated
      ? css`
          ${fadeInKeyframes} 1s backwards
        `
      : 'none'};
  animation-delay: ${({ index }) => `${index++}s`};

  path,
  rect,
  circle {
    fill: var(--primary);
  }
`
export const Label = styled.p`
  margin: 3rem 0 0;
  max-width: 19rem;
  font-weight: bold;
  font-size: 1.6rem;
  direction: ltr;
`
export const SkipButton = styled(FilledButton)`
  max-width: 10rem;
  @media (max-width: 700px) {
    max-width: 28rem;
  }
`
export const Circles = styled.div<{ rtl?: boolean }>`
  display: none;
  @media (max-width: 700px) {
    display: flex;
    flex-direction: ${({ rtl }) => (rtl ? 'row-reverse' : 'row')};
    justify-content: center;
    margin-bottom: 6rem;
  }
`
export const Circle = styled.div<{ active?: boolean }>`
  width: 1rem;
  height: 1rem;
  margin: 0 1rem;
  background: ${({ active }) => (active ? 'var(--primary)' : 'var(--grey)')};
  border-radius: 50%;
  cursor: pointer;
`
