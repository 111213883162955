import React from 'react'
import { Button } from './styles'

const IconButton = ({
  children,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button {...props}>{children}</Button>
)

export { IconButton }
