import React from 'react'
import VCardQrCode from './VCardQrCode'
import { FormattedMessage } from 'react-intl'
import { apiService } from '../../service/api/apiService'
import useSWR from 'swr'
import { OverlayScreen } from '../../components'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import {
  Caption,
  Container,
  Divider,
  QrContainer,
  StyledBirthday,
  StyledEmail,
  StyledLocation,
  StyledMobile,
  StyledPerson,
  Title,
} from './styles'
import { Props } from './types'

const ShareCard = ({ isOpen, onClose }: Props) => {
  const [user] = useLocalStorage<IUser>('user')
  const { data: userData } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )

  return (
    <OverlayScreen isOpen={isOpen} onClose={onClose}>
      <Container>
        <Caption>
          <FormattedMessage id='shareProfile.caption' />
        </Caption>
        {!!userData && (
          <QrContainer>
            <Divider />
            <VCardQrCode user={userData} />
            <Divider />

            <Title>
              <StyledPerson title='Person' /> {userData.firstName}
              {userData.lastName}
            </Title>
            <Title>
              <StyledMobile title='Mobile' /> {userData.mobileNumber}
            </Title>
            <Title>
              <StyledEmail title='Email' /> {userData.email}
            </Title>
            {userData.dob && (
              <Title>
                <StyledBirthday title='Birthday' />
                {new Date(userData.dob).toLocaleDateString()}
              </Title>
            )}
            {userData.location && (
              <Title>
                <StyledLocation title='Location' /> {userData.location}
              </Title>
            )}
          </QrContainer>
        )}
      </Container>
    </OverlayScreen>
  )
}

export default ShareCard
