import React, { useState } from 'react'
import { ReactComponent as Remove } from '../../assets/icons/other/icon_plus.svg'
import SocialMediaIcon from './SocialMediaIcon'
import TextInput from '../TextInput/TextInput'
import { useIntl } from 'react-intl'
import { ReactComponent as Check } from '../../assets/icons/other/icon_check.svg'
import { parseSocialMediaUrl, prefixHttps, validateUrl } from './util'
import { useMediaQuery } from '@material-ui/core'
import {
  AddButton,
  ImageContainer,
  InnerContainer,
  InputContainer,
  RemoveButton,
  SocialContainer,
  Url,
  UrlContainer,
} from './styles'
import { ISocialMediumProps } from './types'

const SocialMedium = ({
  url,
  network,
  onRemove,
  editable,
  onSubmit,
}: ISocialMediumProps) => {
  const intl = useIntl()
  const isMobile = useMediaQuery('(max-width:480px)')

  const [typedUrl, setTypedUrl] = useState('')
  const validTypedUrl = validateUrl(typedUrl)

  const urlWithProtocol = prefixHttps(url)

  const handleSubmit = (item: { type: string; url: string }) => {
    setTypedUrl('')
    onSubmit(item)
  }
  const handleChange = (value: string, valid: boolean) => {
    setTypedUrl(value)
  }
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const socialMedia = parseSocialMediaUrl(typedUrl)

    const validUser = socialMedia.network ? socialMedia.user !== null : true

    if (validTypedUrl && validUser) {
      handleSubmit({ type: socialMedia.network || 'Snapchat', url: typedUrl })
    }
  }

  const placeholder = intl.formatMessage(
    { id: 'components.socialMedia.typeUrl' },
    {
      network: intl.formatMessage({
        id: `components.socialMedia.${network?.toLowerCase()}`,
      }),
    },
  )
  return (
    <SocialContainer>
      <InnerContainer>
        {!isMobile && !url && (
          <ImageContainer>
            <SocialMediaIcon
              alt={network ?? 'Social Medium'}
              service={network || ''}
            />
          </ImageContainer>
        )}
        {url ? (
          <UrlContainer>
            <ImageContainer>
              <SocialMediaIcon
                alt={network ?? 'Social Medium'}
                service={network || ''}
              />
            </ImageContainer>
            <Url
              aria-label={`Click to go to ${network}`}
              href={urlWithProtocol}
              target='_blank'
              rel='noopener noreferrer'
              title={urlWithProtocol}
            >
              {url}
            </Url>
          </UrlContainer>
        ) : (
          <InputContainer>
            <TextInput
              editable
              aria-label={placeholder}
              startAdornment={
                isMobile && (
                  <SocialMediaIcon
                    alt={network ?? 'Social Medium'}
                    isAdornment
                    service={network ?? ''}
                  />
                )
              }
              valid={validateUrl(typedUrl)}
              value={typedUrl}
              placeholder={placeholder}
              change={handleChange}
            />
          </InputContainer>
        )}
        {editable && (
          <>
            {url ? (
              <RemoveButton
                aria-label={`Click to remove ${network}`}
                type='button'
                title='Remove'
                onClick={onRemove}
              >
                <Remove title='Remove' />
              </RemoveButton>
            ) : (
              <AddButton
                type='button'
                aria-label={`Click to add ${network}`}
                title='Add'
                onClick={handleClick}
                disabled={!validTypedUrl}
              >
                <Check />
              </AddButton>
            )}
          </>
        )}
      </InnerContainer>
    </SocialContainer>
  )
}

export default SocialMedium
