import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'

export const Container = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  max-width: 350px;
`
export const Title = styled.h2`
  margin-bottom: 5rem;
  font-size: ${({ theme }) => theme.fontSize.lg};
`
export const CancelButton = styled(FilledButton)`
  margin-bottom: 4rem;
`
export const CodeInputContainer = styled.div`
  margin: ${({ theme }) => theme.spacing.md};
`
