import { AxiosRequestConfig } from 'axios'

export const setAuthHeader = (
  isAuth: boolean,
  config: AxiosRequestConfig,
): AxiosRequestConfig => {
  let token = window.localStorage.getItem('token')
  if (token && isAuth) {
    token = token?.startsWith(`"`)
      ? token.substring(1, token.length - 1)
      : token
    config.headers.Authorization = token
  }
  return { ...config }
}
