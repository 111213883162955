import { BtnType } from './types'

export const getBackground = (
  btnType?: BtnType,
  disabled?: boolean,
  hovering?: boolean,
) => {
  if (hovering && !disabled) {
    return 'var(--background)'
  }
  return btnType === 'primary' ? 'var(--primary)' : 'var(--background)'
}
export const getBorder = (
  btnType?: BtnType,
  disabled?: boolean,
  hovering?: boolean,
) => {
  if (disabled) {
    return 'var(--grey)'
  } else if (hovering) {
    return 'var(--primary)'
  } else {
    return btnType === 'primary' ? 'var(--primary)' : 'var(--grey)'
  }
}
export const getColor = (
  btnType?: BtnType,
  disabled?: boolean,
  hovering?: boolean,
) => {
  if (disabled) {
    return 'var(--grey)'
  } else if (hovering) {
    return 'var(--primary)'
  } else {
    return btnType === 'primary' ? 'var(--background)' : 'var(--primary)'
  }
}
