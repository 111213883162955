import styled from 'styled-components'

export const ConfirmPasswordsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  min-height: 15rem;
`
export const UpdatePasswordPageContainer = styled.div`
  text-align: center;
  position: absolute;
  z-index: 10;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`
