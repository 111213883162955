import React, { useEffect, useContext, useState } from 'react'
import { useLocation, Redirect } from 'react-router-dom'
import { useAuthorize } from '../authorize/useAuthorize'
import queryString from 'query-string'
import Div100vh from 'react-div-100vh'
import { Container, Title } from '../landing/styles'
import { useLocalStorage, deleteFromStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { FormattedMessage } from 'react-intl'
import { AppContext } from '../../utils/ContextWrapper'

const LandingPage = () => {
  const location = useLocation()
  const [redirect, setRedirect] = useState('')
  const [user] = useLocalStorage<IUser>('user')
  const [token] = useLocalStorage('token')
  const [, setAppState] = useContext(AppContext)
  const { authorize } = useAuthorize()
  const params = queryString.parse(location.search)

  useEffect(() => {
    if (params) {
      const {
        app_id: appId = '',
        next = '',
        resetToken,
      }: { app_id?: string; next?: string; resetToken?: string } = params
      if (resetToken) {
        deleteFromStorage('token')
        deleteFromStorage('user')
      }

      setAppState((state) => ({
        ...state,
        authorizeData: {
          appId,
          next,
          resetToken: resetToken === 'true',
        },
      }))
      if (user && token) {
        authorize()
      } else {
        setRedirect('/login')
      }
    } else {
      setRedirect('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token, setAppState, authorize])

  if (redirect) return <Redirect to={redirect} />

  return (
    <Div100vh>
      <Container>
        <Title>
          <FormattedMessage id='landing.title' />
        </Title>
      </Container>
    </Div100vh>
  )
}

export default React.memo(LandingPage)
