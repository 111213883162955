import React, { useContext } from 'react'
import { useLocation } from 'react-router'
import { FormattedMessage } from 'react-intl'
import LogoImage from '../../assets/icons/other/dff-logo.svg'
import { OverlayContext } from '../../utils/ContextWrapper'
import useColorScheme from '../../utils/hooks/useColorScheme'
import {
  Container,
  Copyright,
  DffContainer,
  FooterLink,
  FooterLinks,
  Logo,
} from './styles'

const Footer = () => {
  const [isOverlayOpen] = useContext(OverlayContext)
  const location = useLocation()
  const privacyOrToC =
    location?.pathname?.includes('privacy') ||
    location?.pathname?.includes('terms')

  const colorScheme = useColorScheme()

  return !privacyOrToC ? (
    <Container>
      <a
        tabIndex={isOverlayOpen ? -1 : 0}
        href={process.env.REACT_APP_DFF_REDIRECT_URI}
      >
        <Logo
          alt='Dubai Future Foundation Logo'
          colorScheme={colorScheme}
          src={LogoImage}
        />
      </a>
      <DffContainer>
        <Copyright>
          <FormattedMessage id='footer.copyright' />
        </Copyright>
        <FooterLinks hideMobile>
          <FooterLink
            tabIndex={isOverlayOpen ? -1 : 0}
            target='_blank'
            href='http://dubai.ae'
          >
            <FormattedMessage id='footer.dubaiGovernment' />
          </FooterLink>
          <FooterLink
            tabIndex={isOverlayOpen ? -1 : 0}
            target='_blank'
            href='http://government.ae'
          >
            <FormattedMessage id='footer.UAEGovernment' />
          </FooterLink>
        </FooterLinks>
      </DffContainer>
      <FooterLinks>
        <FooterLink
          tabIndex={isOverlayOpen ? -1 : 0}
          target='_blank'
          href='https://www.dubaifuture.ae/terms-of-service/'
        >
          <FormattedMessage id='footer.termsAndConditions' />
        </FooterLink>
        <FooterLink
          tabIndex={isOverlayOpen ? -1 : 0}
          target='_blank'
          href='https://www.dubaifuture.ae/privacy-policy/'
        >
          <FormattedMessage id='footer.privacy' />
        </FooterLink>
      </FooterLinks>
    </Container>
  ) : null
}

export { Footer }
