import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { AuthFormView } from '../../components'
import { isEmailValid } from '../../utils/validation'
import Content from './Content'
import { useAuthorize } from '../authorize/useAuthorize'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../../components/ToastMessage/ToastService'
import {
  AuthData,
  AuthDataContext,
  TopLoadingBarContext,
  UrlContext,
} from '../../utils/ContextWrapper'
import queryString from 'query-string'
import useLocalStorage, { writeStorage } from '@rehooks/local-storage'
import { useIntl } from 'react-intl'
import { IUser } from '../../models/IUser'
import { setLoggedInCookie } from '../../utils/cookies'
import { stepNumbers, steps } from './constants'

const LoginPage = () => {
  const intl = useIntl()
  const location = useLocation()
  const [redirect, setRedirect] = useState('')
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const [user] = useLocalStorage<IUser>('user')
  const [token] = useLocalStorage<string>('token')
  const [stepIndex, setStepIndex] = useState(0)
  const [hasLoginStarted, setHasLoginStarted] = useState(false)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [url] = useContext(UrlContext)
  const [{ email, password }, setAuthData] = useContext(AuthDataContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  useEffect(() => {
    if (user && token && !oauth2Url) {
      // only redirect when user and token are set, but the login process has not started yet.
      if (!hasLoginStarted) {
        setRedirect('/dashboard')
      }
      // if we are in authorize flow, start it after user and token are set
      else if (isAuthorizeFlow) {
        setLoggedInCookie()
        authorize()
      } else {
        setRedirect(url ?? '/dashboard')
      }
    }
  }, [user, token, hasLoginStarted, isAuthorizeFlow, authorize, url, oauth2Url])

  const stepsList = [
    steps.EMAILPASSWORD,
    steps.LOGIN,
    steps.TWOFACTORTOKEN,
    steps.AUTHENTICATE,
  ]

  const isValid = () => isEmailValid(email) && password !== ''

  const handleNext = async () => {
    const nextStep = stepIndex + 1
    setHasLoginStarted(true)
    if (email && password && nextStep === stepNumbers.LOGIN) {
      try {
        setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
        const { token, user } = await apiService.auth.login(email, password)
        writeStorage('token', token)
        writeStorage('user', user)
        setLoggedInCookie()
        if (isAuthorizeFlow) {
          authorize()
        } else {
          if (oauth2Url) {
            const oauthRedirectUrl =
              (process.env.REACT_APP_BACKEND_AUTH_URL ?? '') + oauth2Url
            window.location.href = oauthRedirectUrl
          } else {
            setRedirect(url ?? '/dashboard')
          }
        }
      } catch ({ response }) {
        if (response?.status === 403) {
          // validation flow
          setRedirect(`verification-code${location.search}`)
        } else if (response?.status === 499) {
          // tfa flow
          setStepIndex(stepNumbers.TWOFACTORTOKEN)
        } else if (response?.status === 401) {
          // unauthorized
          openToast({
            message: intl.formatMessage({ id: 'errors.invalidLogin' }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.loginFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    }
  }

  const handleEmailChange = (val: string, status: boolean) => {
    setAuthData((authData) => ({ ...authData, email: val }))
  }

  const handlePasswordChange = (val: string, status: boolean) =>
    setAuthData((authData: AuthData) => ({ ...authData, password: val }))

  return redirect ? (
    <Redirect to={redirect} />
  ) : (
    <>
      <AuthFormView>
        <Content
          step={stepsList[stepIndex]}
          onChangeEmail={handleEmailChange}
          onChangePassword={handlePasswordChange}
          stepLabels={stepsList}
          isValid={isValid()}
          onNextStep={handleNext}
          currentIndex={stepIndex}
        />
      </AuthFormView>
    </>
  )
}

export default LoginPage
