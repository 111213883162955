import React, { useState } from 'react'
import { ReactComponent as Cloud } from '../../assets/icons/other/icon_upload.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import { openToast } from '../ToastMessage/ToastService'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import {
  Container,
  Display,
  Placeholder,
  Preview,
  StyledReadOnlyImage,
  SubHeading,
} from './styles'
import { IPhotoUploadProps } from './types'

const PhotoUpload = ({
  name,
  value,
  change,
  editable,
  label,
}: IPhotoUploadProps) => {
  const intl = useIntl()
  const [imgSrc, setImgSrc] = useState<string | undefined>(value)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (event.target.files && event.target.files[0]) {
      // eslint-disable-next-line
      const reader = new FileReader()
      const fileSize = event.target.files[0].size
      if (fileSize < 10485760) {
        change(event.target.files[0], true)
        reader.onload = function (e) {
          if (e.target) setImgSrc(e.target.result as string)
        }
        reader.readAsDataURL(event.target.files[0])
      } else {
        openToast({
          message: intl.formatMessage({
            id: 'components.photoUpload.fileSizeExceeds',
          }),
          variant: 'failure',
        })
      }
    }
  }

  const dragEvents = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return editable ? (
    <Container>
      <SubHeading>
        <FormattedMessage id='components.photoUpload.drag' />
      </SubHeading>
      <Display data-cy={imgSrc ? 'photo' : ''}>
        <Preview show={!!imgSrc}>
          {imgSrc && <img src={imgSrc} alt={name} />}
        </Preview>

        <Placeholder show={!imgSrc}>
          <Cloud title='Upload' />
        </Placeholder>
        <input
          autoFocus
          aria-label='Upload photo'
          disabled={!editable}
          type='file'
          data-cy='photo-upload-input'
          id='upload-file-input'
          onChange={onChange}
          onDrag={dragEvents}
          onDragOver={dragEvents}
        />
      </Display>
    </Container>
  ) : (
    <ReadOnly
      value={imgSrc && <StyledReadOnlyImage src={imgSrc} alt={name} />}
      fieldName={label}
    />
  )
}

export { PhotoUpload }
