import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { OverlayScreen } from '..'
import { ProgressFields } from '../ProgressBar/ProgressFields'
import { Container, Content, StyledButton, Title } from './styles'
import { IProps } from './types'

const FormContainer = ({
  title,
  enableProgressBar = false,
  onNextStep,
  onPrevStep,
  isValid,
  children,
  stepLabels = [],
  isOpen,
  onClose,
  currentIndex,
  setCurrentIndex,
  steps = [],
  hasActionButtons = true,
  hasPreviousStep = false,
  showNextButton = true,
  progressType,
}: IProps) => {
  const disabled = !isValid

  const hasPrevStep = hasPreviousStep && currentIndex > 0
  const hasNextStep = currentIndex < stepLabels.length - 1
  const prevStep = hasPrevStep ? stepLabels[currentIndex - 1] : undefined
  const nextStep = hasNextStep ? stepLabels[currentIndex + 1] : undefined

  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (prevStep && onPrevStep) {
      onPrevStep(prevStep)
    }
  }

  const handleNext = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (!disabled && nextStep) {
        onNextStep && onNextStep(nextStep)
      } else if (!disabled && !nextStep) {
        onNextStep && onNextStep(null, true)
      }
    },
    [disabled, nextStep, onNextStep],
  )

  return (
    <OverlayScreen isOpen={isOpen} onClose={onClose}>
      <Container
        hasProgressBar={enableProgressBar}
        onSubmit={handleNext}
        singleItem={!hasActionButtons}
      >
        <Title>{title}</Title>
        <Content>{children}</Content>
        {hasActionButtons && (
          <>
            {hasPrevStep && (
              <StyledButton onClick={handlePrev} disabled={disabled}>
                <FormattedMessage id='common.prev' />
              </StyledButton>
            )}
            {showNextButton && (
              <StyledButton type='submit' disabled={disabled}>
                <FormattedMessage id='common.next' />
              </StyledButton>
            )}
          </>
        )}
      </Container>
      {enableProgressBar && setCurrentIndex && (
        <ProgressFields
          steps={steps}
          setCurrentIndex={setCurrentIndex}
          type={progressType}
          currentStepIndex={currentIndex}
        />
      )}
    </OverlayScreen>
  )
}

export { FormContainer }
