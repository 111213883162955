import { ResolveNameUserType } from './types'

export const resolveName = (user?: ResolveNameUserType) => {
  if (!user) {
    return ''
  }
  const { firstName, lastName } = user
  if (!firstName && !lastName) {
    return ''
  } else {
    return `${firstName ?? ''} ${lastName ?? ''}`
  }
}
