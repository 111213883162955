import { axiosIdentity } from './axiosInstances'
import { axiosNoAuth, axiosAuth } from './axiosInstances'
import { IUser } from '../../models/IUser'
import { AxiosResponse } from 'axios'
import { IOrganisation } from '../../models/IOrganisation'

export const getUser = (id: string) => {
  return axiosIdentity
    .get(`/users/${id}`)
    .then((response: AxiosResponse<IUser>) => response.data)
}

export const validateSession = () => {
  return axiosAuth
    .post(`/auth/validatesession`)
    .then((response) => response.data)
}

export const validatePassword = ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  return axiosAuth
    .put(`/2fa/validateUser`, { email, password })
    .then((response) => response.data)
}

export const checkEmail = (email: string) => {
  return axiosNoAuth
    .post(`/auth/checkEmail`, { email })
    .then(
      (response: AxiosResponse<{ resetPassword: boolean }>) => response.data,
    )
}

export const checkPassword = (id: string, password: string) => {
  return axiosIdentity
    .post(`/users/${id}/checkPassword`, { password })
    .then((response) => response.data)
}

export const updateUser = (
  id: string,
  user: { [key: string]: string | number | string[] },
) => {
  return axiosIdentity
    .patch(`/users/${id}`, user)
    .then((response: AxiosResponse<IUser>) => response.data)
}

export const uploadPhoto = (id: string, payload: File) => {
  const formData = new window.FormData()
  formData.append('profilePicture', payload)
  return axiosIdentity
    .post(`/users/${id}/picture`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
}

export const deleteUser = (id: string) => {
  return axiosIdentity.delete(`/users/${id}`).then((response) => response.data)
}

export const getOrganisations = (id: string) => {
  return axiosIdentity
    .get(`/users/${id}/organisations`)
    .then((response: AxiosResponse<IOrganisation[]>) => response.data)
}

export const changeEmailRequest = (id: string, email: string) => {
  return axiosIdentity
    .post(`/users/${id}/changeEmailRequest`, { email })
    .then((res: AxiosResponse<{ token: string; expiry: number }>) => res.data)
}

export const changeEmail = (
  id: string,
  token: string,
  verificationCode: string,
) => {
  return axiosIdentity
    .post(`/users/${id}/changeEmail`, {
      token,
      verificationCode,
    })
    .then((res) => res.data)
}

export const getOtp = ({
  email,
  password,
}: {
  email: string
  password: string
}) => {
  return axiosNoAuth
    .post(`/tfa/enable`, { email, password })
    .then((response) => {
      if (response && response.data) {
        return response.data
      }
    })
}

export const disableTfa = ({
  password,
  token,
}: {
  password: string
  token: string
}) => {
  return axiosAuth
    .post(`/tfa/disable`, { password, token })
    .then((response) => {
      if (response && response.data) {
        return response.data
      }
    })
}

export const getQr = (otp: string) => {
  return axiosNoAuth
    .get(`/tfa/qrcode?qr_url=${otp}&qr_type=svg`, {
      responseType: 'arraybuffer',
    })
    .then((response) => {
      if (response && response.data) {
        return Buffer.from(response.data, 'binary').toString('base64')
      }
    })
}

export const validateTwoFactorQr = ({
  email,
  token,
}: {
  email: string
  token: string
}) => {
  return axiosNoAuth
    .post(`/tfa/verify`, { email, token })
    .then((response: AxiosResponse<{ backupCode: string }>) => response.data)
}

export const removePendingRequest = (id: string, requestId: string) => {
  return axiosIdentity
    .delete(`/users/${id}/pendingRequests/${requestId}`)
    .then((res) => res.data)
}

export const pendingRequests = (id: string) => {
  return axiosIdentity
    .get(`/users/${id}/pendingRequests`)
    .then(
      (res: AxiosResponse<{ id: string; name: string; createdAt: string }[]>) =>
        res.data,
    )
}
