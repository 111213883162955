import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CloseButton } from '../CloseButton/CloseButton'
import {
  ButtonContainer,
  Container,
  Question,
  StyledButton,
  Title,
} from './styles'
import { IConfirmProps } from './types'

const ConfirmContent = ({
  onClose,
  onConfirm,
  title,
  confirmTitle,
  denyTitle,
  question,
  direction = 'vertical',
  hideClose,
}: IConfirmProps) => {
  const intl = useIntl()
  const rtl = intl.locale === 'ar'
  return (
    <Container>
      {!hideClose && <CloseButton rtl={rtl} onClose={onClose} />}
      <Title>{title}</Title>
      <Question>{question}</Question>
      <ButtonContainer direction={direction}>
        <StyledButton
          fluid={direction === 'vertical'}
          direction={direction}
          btnType='primary'
          onClick={onConfirm}
        >
          {confirmTitle || (
            <FormattedMessage id='components.confirmOverlay.yes' />
          )}
        </StyledButton>
        <StyledButton
          fluid={direction === 'vertical'}
          direction={direction}
          onClick={onClose}
        >
          {denyTitle || <FormattedMessage id='components.confirmOverlay.no' />}
        </StyledButton>
      </ButtonContainer>
    </Container>
  )
}

export default ConfirmContent
