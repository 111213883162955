import styled from 'styled-components'

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const SectionItem = styled.div`
  flex-basis: 17rem;

  @media only screen and (max-width: 960px) {
    flex-basis: 13rem;
  }

  @media only screen and (max-width: 700px) {
    flex-basis: 33%;
  }
`

export const SectionTitle = styled.div<{ clickable?: boolean }>`
  padding: 2rem 4.5rem;
  color: ${({ clickable }) => (clickable ? 'var(--grey)' : 'var(--primary)')};
  height: var(--font-medium);
  vertical-align: middle;
  font-family: var(--headerFont);
  font-weight: bold;
  display: flex;
  user-select: none;
  align-items: center;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'initial')};

  svg {
    &:first-child {
      margin-right: 2rem;
    }
    &:last-child {
      margin-left: 2rem;
    }
    width: 2.8rem;
    height: 2.8rem;

    path,
    circle {
      fill: ${({ clickable }) =>
        clickable ? 'var(--grey)' : 'var(--primary)'};
    }
  }

  &:hover {
    color: var(--primary);
    path,
    circle {
      fill: var(--primary);
    }
  }

  @media only screen and (max-width: 960px) {
    padding: 2rem 2.5rem;
  }

  @media only screen and (max-width: 700px) {
    padding: 1rem 2rem;
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
  @media (max-width: 480px) {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`
