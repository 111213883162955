import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createMockServer } from './mockServer'
import { getMockApi } from './service/localStorage/userStorage'
// @ts-ignore
import TagManager from 'react-gtm-module'

/*
import { addLocaleData } from 'react-intl'
import locale_en from 'react-intl/locale-data/en'

addLocaleData([...locale_en, ...locale_ar])
*/

if (process.env.NODE_ENV !== 'production') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const axe = require('react-axe')
  axe(React, ReactDOM, 1000)
}

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_GTM_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  })
}

if (process.env.NODE_ENV === 'development' && getMockApi() === 'true') {
  console.warn(
    'Mock API is enabled. To disable, delete the MOCK_API key in your localstorage.',
  )
  createMockServer({ environment: 'development' })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
