import React from 'react'
import { Image, ImageReplacement, ImageTag } from './styles'
import { MemberImageProps } from './types'

const MemberImage = ({
  imageSrc,
  title,
  description,
  isAdmin,
}: MemberImageProps) => {
  return imageSrc ? (
    <Image src={imageSrc} alt={title} />
  ) : (
    <ImageReplacement>
      <ImageTag isAdmin={isAdmin}>{description.charAt(0)}</ImageTag>
    </ImageReplacement>
  )
}

export default MemberImage
