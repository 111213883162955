import React, { useContext, useEffect, useState } from 'react'
import addSrc from '../../assets/icons/other/icon_plus.svg'
import InlineSVG from 'react-inlinesvg'
import { FormContainer } from '../'
import OrganisationSteps from '../../assets/data/organizationData.json'
import queryString from 'query-string'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { OrganisationSearch } from './OrganisationSearch'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../ToastMessage/ToastService'
import useSWR, { trigger } from 'swr'
import { SectionTitle } from '../../pages/dashboard/Section'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { TutorialSteps } from '../Tutorial/tutorialTypes'
import { OverlayContext } from '../../utils/ContextWrapper'
import { AddHeader, Title } from './styles'
import { SectionHeaderInterface } from './types'

const AddOrganisationHeader = ({ title }: SectionHeaderInterface) => {
  const location = useLocation()
  const intl = useIntl()
  const history = useHistory()
  const [isOverlayOpen] = useContext(OverlayContext)
  const [isAddOverlayOpen, setIsAddOverlayOpen] = useState(false)
  const [createdName, setCreatedNames] = useState<string[]>([])
  const [organisation, setOrganisation] = useState<{
    name: string
    id: string
  } | null>(null)
  const { newOrganisation, appId, redirectUrl } = queryString.parse(
    location.search,
  )

  const [user] = useLocalStorage<IUser>('user')

  const { data, mutate } = useSWR(
    !!user ? ['organisations', user.id] : null,
    (key: string, userId: string) =>
      user ? apiService.user.organisations(userId) : null,
  )

  const { data: pendingRequests, mutate: mutatePendingRequests } = useSWR(
    !!user ? ['pendingRequests', user.id] : null,
    (key: string, userId) => apiService.user.pendingRequests(userId),
  )

  useEffect(() => {
    if (newOrganisation) {
      setIsAddOverlayOpen(true)
    }
  }, [newOrganisation, setIsAddOverlayOpen])

  const handleClick = () => {
    setIsAddOverlayOpen(true)
  }

  const handleChange = (name: string, id: string) => {
    setOrganisation({ name, id })
  }

  const isCreate = () => {
    if (createdName.length && organisation) {
      return createdName.includes(organisation?.name)
    } else if (organisation) {
      return false
    } else {
      return false
    }
  }

  const handleNextStep = async () => {
    const created = isCreate()
    if (organisation?.name === '') {
      return
    } else {
      if (created && organisation?.name) {
        try {
          const org = await apiService.organisation.create(organisation.name)
          if (data) {
            mutate([...data, { ...org, newOrganisation: true }], false)
          }
          if (newOrganisation && appId) {
            history.replace(
              `/dashboard?newOrgAppId=${appId}&redirectUrl=${
                redirectUrl ?? ''
              }`,
            )
          }
        } catch (e) {
          const { response } = e
          openToast({
            variant: 'failure',
            message: intl.formatMessage({
              id:
                response?.status === 409
                  ? 'errors.organisationExistsFail'
                  : 'errors.addOrganisationFail',
            }),
          })
        } finally {
          setIsAddOverlayOpen(false)
          setOrganisation(null)
        }
      } else {
        if (pendingRequests) {
          mutatePendingRequests(
            [
              ...pendingRequests,
              { id: '', name: organisation?.name ?? '', createdAt: '' },
            ],
            false,
          )
        }
        try {
          await apiService.organisation.joinRequest(organisation?.id ?? '')
          trigger('pendingRequests')
          openToast({
            variant: 'success',
            message: intl.formatMessage({
              id: 'components.joinRequest.successRequest',
            }),
          })
        } catch (e) {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.genericFail' }),
          })
        } finally {
          setIsAddOverlayOpen(false)
          setOrganisation(null)
        }
      }
    }
  }

  const handleClose = () => {
    setIsAddOverlayOpen(false)
  }

  const getStepName = () => {
    const created = isCreate()
    if (created !== undefined) {
      return created
        ? 'dashboard.labels.create'
        : 'dashboard.labels.joinRequest'
    } else {
      return ''
    }
  }

  const handleCreateOption = (optionLabel: string) => {
    setCreatedNames((createdNames) => [...createdNames, optionLabel])
  }

  return (
    <>
      <AddHeader
        tabIndex={isOverlayOpen ? -1 : 0}
        aria-hidden={isOverlayOpen}
        onClick={handleClick}
      >
        <SectionTitle clickable>
          <InlineSVG
            src={addSrc}
            title={intl.formatMessage({ id: 'components.sectionHeader.add' })}
          />
          <Title
            id={TutorialSteps.ORGANISATION}
            className={TutorialSteps.ORGANISATION}
          >
            {title}
          </Title>
        </SectionTitle>
      </AddHeader>
      <FormContainer
        isOpen={isAddOverlayOpen}
        title={intl.formatMessage({
          id: 'components.sectionHeader.createOrganisation',
        })}
        onNextStep={handleNextStep}
        isValid={organisation !== null}
        onClose={handleClose}
        stepLabels={['', getStepName()]}
        progressType='organisation'
        steps={OrganisationSteps}
        currentIndex={0}
      >
        <OrganisationSearch
          value={organisation?.name}
          onCreateOption={handleCreateOption}
          change={handleChange}
        />
      </FormContainer>
    </>
  )
}

export { AddOrganisationHeader }
