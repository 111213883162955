import React, { useEffect, useState } from 'react'
import Joyride, { CallBackProps, STATUS } from 'react-joyride'
import { useMediaQuery } from '@material-ui/core'
import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import { desktopSteps, mobileSteps } from './constants'
import { Props } from './types'
import { Tooltip } from './Tooltip'

const Tutorial = ({ run, setRun }: Props) => {
  const [render, setRender] = useState(false)
  const isMobile = useMediaQuery('(max-width: 480px)')
  const [steps, setSteps] = useState(desktopSteps)
  const { y } = useScrollPosition()
  const isScrolled = y > 0

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRender(true)
    }, 1000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    setSteps((isMobile ? mobileSteps : desktopSteps) as any)
  }, [isMobile])

  const handleJoyrideCallback = (props: CallBackProps) => {
    const { status } = props
    const options: string[] = [STATUS.FINISHED, STATUS.SKIPPED]

    if (options.includes(status)) {
      setRun(false)
    }
  }

  return render ? (
    <Joyride
      run={run}
      steps={steps}
      callback={handleJoyrideCallback}
      // scrollToFirstStep
      showSkipButton
      floaterProps={{ disableAnimation: true }}
      tooltipComponent={Tooltip}
      styles={{
        spotlight: {
          transform: !isScrolled ? 'translateY(25px)' : '',
        },
        options: {
          arrowColor: 'var(--primary)',
          zIndex: 2000000,
        },
        overlay: {
          backgroundColor: 'var(--faded)',
        },
      }}
    />
  ) : null
}

export { Tutorial }
