import React, { useEffect, useState } from 'react'
import TextInput from '../TextInput/TextInput'
import { isEmailValid } from '../../utils/validation'
import { IUser } from '../../models/IUser'
import Member from './Member'
import AddMember from './AddMember'
import { ConfirmOverlay } from '../ConfirmOverlay/ConfirmOverlay'
import { openToast } from '../ToastMessage/ToastService'
import { FormattedMessage, useIntl } from 'react-intl'
import { apiService } from '../../service/api/apiService'
import useSWR from 'swr'
import { ListContainer } from './StyledComponents'
import { useLocalStorage } from '@rehooks/local-storage'
import { IMembersProps } from './types'
import { InputContainer, OuterContainer } from './styles'

const Members = ({
  name,
  type,
  value = [],
  organisationId,
  editable,
}: IMembersProps) => {
  const intl = useIntl()
  const [isAdding, setIsAdding] = useState(false)
  const [userToRemove, setUserToRemove] = useState<IUser | undefined>()
  const [user] = useLocalStorage<IUser>('user')
  const { data, mutate } = useSWR(
    !!user ? ['organisations', user.id] : null,
    (key: string, userId: string) => apiService.user.organisations(userId),
  )
  const [email, setEmail] = useState('')
  const [filteredMembers, setFilteredMembers] = useState(value)

  useEffect(() => {
    const filteredMembers = value.filter((user) => user?.email?.includes(email))
    setIsAdding(filteredMembers.length === 0)
    setFilteredMembers(filteredMembers)
  }, [email, value])

  const handleEmailChange = (value: string) => {
    setEmail(value)
  }

  const removeMember = async (userId: string, isAdmin: boolean) => {
    // Remove member
    try {
      const organisation = isAdmin
        ? await apiService.organisation.removeAdmin(organisationId, userId)
        : await apiService.organisation.removeMember(organisationId, userId)
      if (data) {
        mutate(
          data.map((org) => (org.id === organisation.id ? organisation : org)),
          false,
        )
      }
      if (organisation.members.length + organisation.admins.length === 0) {
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'success.deleteOrganisationLastMember',
          }),
        })
      } else if (userId === user?.id) {
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'success.deleteOrganisationSelf',
          }),
        })
      } else {
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'success.deleteOrganisationMember',
          }),
        })
      }
    } catch (e) {
      openToast({
        variant: 'failure',
        message: intl.formatMessage({
          id: 'errors.removeMemberFail',
        }),
      })
    }
  }

  const addMember = async (email: string, isAdmin: boolean) => {
    // Add member
    try {
      const organisation = isAdmin
        ? await apiService.organisation.addAdmin(organisationId, email)
        : await apiService.organisation.addMember(organisationId, email)
      if (data) {
        mutate(
          data.map((org) => (org.id === organisation.id ? organisation : org)),
          false,
        )
      }
    } catch (e) {
      const { response } = e
      const getResponseMessage = (status?: number) => {
        if (status === 403) return 'errors.errors.notAuthorizedAddMembers'
        else if (status === 404) return 'errors.userNotFound'
        else return 'errors.genericFail'
      }
      const messageKey = getResponseMessage(response?.status)
      openToast({
        variant: 'failure',
        message: intl.formatMessage({ id: messageKey }),
      })
    }
  }

  const handleRemoveConfirm = () => {
    setEmail('')
    removeMember(userToRemove?.id ?? '', userToRemove?.isAdmin ?? false)
    setUserToRemove(undefined)
  }

  const handleConfirmClose = () => setUserToRemove(undefined)

  const handleRemove = (user: IUser) => () => {
    setUserToRemove(user)
  }
  const placeholder = intl.formatMessage({
    id: 'components.members.typeEmail',
  })

  return (
    <OuterContainer>
      {editable && (
        <InputContainer>
          <TextInput
            fluid
            aria-label={placeholder}
            editable
            valid={isEmailValid(email)}
            placeholder={placeholder}
            name={name}
            type={type}
            value={email}
            change={handleEmailChange}
          />
        </InputContainer>
      )}
      {isAdding ? (
        <AddMember
          organisationId={organisationId}
          setEmail={setEmail}
          setIsAdding={setIsAdding}
          unknownEmail={filteredMembers.length === 0}
          email={email}
          onSubmit={addMember}
        />
      ) : (
        <ListContainer>
          {filteredMembers
            .sort((a, b) => (a.isAdmin ? -1 : 1))
            .map((user) => {
              return (
                <Member
                  addMember={addMember}
                  editable={editable}
                  onRemove={handleRemove(user)}
                  key={user.id}
                  name={
                    user.firstName && user.lastName
                      ? `${user.firstName} ${user.lastName}`
                      : user.displayName
                  }
                  email={user.email}
                  imageSrc={user.profilePicture}
                  isAdmin={user.isAdmin as any}
                />
              )
            })}
        </ListContainer>
      )}
      <ConfirmOverlay
        open={!!userToRemove}
        onConfirm={handleRemoveConfirm}
        title={<FormattedMessage id='components.members.removeTitle' />}
        question={
          <FormattedMessage
            id='components.members.removeQuestion'
            values={{ email }}
          />
        }
        onClose={handleConfirmClose}
      />
    </OuterContainer>
  )
}

export { Members }
