import zxcvbn from 'zxcvbn'
import React from 'react'
import { PasswordScaleContainer, ScaleItem } from './styles'
import { PasswordScaleProps } from './types'

const PasswordScale = ({ password }: PasswordScaleProps) => {
  const { score } = zxcvbn(password)

  return (
    <PasswordScaleContainer>
      <ScaleItem hasColor={score >= 1} score={score} />
      <ScaleItem hasColor={score >= 2} score={score} />
      <ScaleItem hasColor={score >= 3} score={score} />
      <ScaleItem hasColor={score >= 4} score={score} />
    </PasswordScaleContainer>
  )
}

export default PasswordScale
