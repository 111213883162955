import React, { useMemo } from 'react'
import { OverlayScreen } from '..'
import { FormattedMessage, useIntl } from 'react-intl'
import platforms from './platforms'
import { IProps } from './types'
import {
  Container,
  Description,
  DesktopVisitButton,
  Image,
  MobileButton,
  Panel,
  PanelSeparator,
  Title,
} from './styles'

const PlatformsOverlay = ({ open, onClose, platform }: IProps) => {
  const intl = useIntl()
  const { uniqueID } = platform

  const getPlatformLink = () => platforms.find((p) => p.id === uniqueID)
  const handleVisit = () => {
    const link = getPlatformLink()
    if (link) {
      window.open(link.website)
    }
  }

  const buttonLabel = useMemo(() => {
    return intl.formatMessage({
      id: `settings.platformButtons.${uniqueID}`,
      defaultMessage: intl.formatMessage({
        id: 'settings.platformButtons.visitWebsite',
      }),
    })
  }, [intl, uniqueID])

  const imageName = useMemo(() => uniqueID, [uniqueID])

  return (
    <OverlayScreen isOpen={open} onClose={onClose}>
      <Container>
        <Panel>
          <Title>
            {uniqueID && (
              <FormattedMessage id={`settings.platforms.${uniqueID}`} />
            )}
          </Title>
          <Description>
            <FormattedMessage
              id={`settings.platformDescriptions.${uniqueID}`}
            />
          </Description>
          <div>
            <DesktopVisitButton onClick={handleVisit}>
              {buttonLabel}
            </DesktopVisitButton>
          </div>
        </Panel>
        <PanelSeparator />
        <Panel>
          <Image src={`/assets/images/${imageName}.png`} alt={platform.name} />
        </Panel>
        <MobileButton onClick={handleVisit}>{buttonLabel}</MobileButton>
      </Container>
    </OverlayScreen>
  )
}

export { PlatformsOverlay }
