import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { openToast } from '../../../../components/ToastMessage/ToastService'
import { FilledButton } from '../../../../components'
import CopyCode from './CopyCode'
import { TfaContext } from './TwoFactorAuth'
import { ITokenProps } from './types'
import { Steps } from './constants'
import { Caption, Container, Title } from './styles'

const BackupCode = ({ isRegister, onClose }: ITokenProps) => {
  const intl = useIntl()
  const [copied, setCopied] = useState(false)
  const [{ backupCode }, setTfaState] = useContext(TfaContext)

  useEffect(() => {
    if (copied) {
      openToast({
        message: intl.formatMessage({ id: 'success.copyCode' }),
        variant: 'success',
      })
    }
  }, [copied, intl])

  const handleCopyClick = () => setCopied(true)

  const handleClose = () => {
    setTfaState((state) => ({
      ...state,
      otp: '',
      password: '',
      qr: '',
      step: Steps.GENERAL,
    }))
    onClose && onClose()
  }

  return (
    <Container>
      {isRegister && (
        <Title>
          <FormattedMessage id='settings.twofactor.backupCodeTitle' />
        </Title>
      )}
      <Caption>
        <FormattedMessage id='settings.twofactor.backupCodeInstruction' />
      </Caption>
      <CopyCode code={backupCode ?? ''} onCopy={handleCopyClick} />
      <FilledButton onClick={handleClose}>
        <FormattedMessage id='common.close' />
      </FilledButton>
    </Container>
  )
}

export default BackupCode
