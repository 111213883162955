import React, { useEffect, useState } from 'react'
import { ConfirmOverlay, IconLabelBtn } from '../../../components'
import { Collapse } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { IProfileObject } from '../../../models/IProfileField'
import organisationData from '../../../assets/data/organizationData.json'
import FormWrapper from './FormWrapper'
import { IUser } from '../../../models/IUser'
import { Section, SectionItem } from '../Section'
import { useLocalStorage } from '@rehooks/local-storage'
import queryString from 'query-string'
import { useLocation } from 'react-router'
import { IOrganisationSectionProps } from './types'

const OrganisationSection = ({
  expanded,
  organisation,
}: IOrganisationSectionProps) => {
  const intl = useIntl()
  const location = useLocation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const { newOrgAppId, redirectUrl } = queryString.parse(location.search)
  const [isReturnOverlayOpen, setIsReturnOverlayOpen] = useState(false)
  const [allowReturnToast, setAllowReturnToast] = useState(false)
  useEffect(() => {
    if (organisation.newOrganisation) {
      setIsOverlayOpen(true)
      setAllowReturnToast(true)
    }
  }, [organisation])

  useEffect(() => {
    if (allowReturnToast && !isOverlayOpen && !!newOrgAppId) {
      setIsReturnOverlayOpen(true)
    }
  }, [organisation, allowReturnToast, isOverlayOpen, newOrgAppId])

  const [user] = useLocalStorage<IUser>('user')
  const currentUserEmail = user?.email
  const isAdmin = !!organisation?.admins?.find(
    (admin) => admin.email === currentUserEmail,
  )

  const handleReturnOverlayClose = () => {
    setIsReturnOverlayOpen(false)
  }

  const redirect = () => {
    const appId = (newOrgAppId as string).toUpperCase()
    const url = (redirectUrl as string) || process.env[`REACT_APP_${appId}_URL`]

    if (url) {
      window.location.assign(url)
    }
  }

  const organisationSteps: IProfileObject[] = organisationData
    .map((field) => {
      if (field.name === 'members') {
        const value = organisation.members.concat(
          organisation.admins.map((admin) => ({ ...admin, isAdmin: true })),
        )
        return {
          ...field,
          value,
          completed: Array.isArray(value) ? !!value?.length : true,
        }
      } else if (
        field.name === 'pendingRequests' ||
        field.name === 'pendingInvitations'
      ) {
        const value = organisation[field.name]
        return {
          ...field,
          value,
          completed: true,
        }
      }
      const value = organisation[field.name]
      return {
        ...field,
        value,
        completed: Array.isArray(value) ? value.length > 0 : !!value,
      }
    })
    .filter((step) => {
      if (
        step.name === 'pendingRequests' ||
        step.name === 'pendingInvitations'
      ) {
        return isAdmin && (step.value as IUser[])?.length > 0
      } else {
        return true
      }
    })

  useEffect(() => {
    const index = organisationSteps.findIndex((step) => !step.value)
    setCurrentIndex(index)
    // After initial render, the current index follows more complex rules, rerunning the
    // effect would confuse that
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const makeCurrentField = (currentIndex: number) => () => {
    setCurrentIndex(currentIndex)
    setIsOverlayOpen(true)
  }

  const allCompleted =
    organisationSteps?.filter((step: IProfileObject) => step.completed)
      ?.length === organisationSteps?.length

  return (
    <Collapse in={expanded} timeout='auto'>
      <Section aria-hidden={isOverlayOpen}>
        {organisationSteps.map((step: IProfileObject, index: number) => {
          const { label, iconName, completed, name, value } = step

          return (
            <SectionItem key={index}>
              <IconLabelBtn
                iconFileName={iconName}
                label={intl.formatMessage({ id: `${label}` })}
                active={index === currentIndex}
                completed={completed}
                notifications={
                  name === 'pendingRequests' || name === 'pendingInvitations'
                    ? value.length
                    : 0
                }
                hasCheckIcon={!allCompleted && completed}
                hasNotificationIcon={
                  name === 'pendingRequests' || name === 'pendingInvitations'
                }
                onClick={makeCurrentField(index)}
              />
            </SectionItem>
          )
        })}
      </Section>
      <FormWrapper
        id={organisation.id}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
        organisationSteps={organisationSteps}
        setIsOverlayOpen={setIsOverlayOpen}
        isOverlayOpen={isOverlayOpen}
        isUserAdmin={isAdmin}
      />
      <ConfirmOverlay
        title={<FormattedMessage id={`returnToast.${newOrgAppId}`} />}
        question=''
        open={isReturnOverlayOpen}
        onConfirm={redirect}
        onClose={handleReturnOverlayClose}
      />
    </Collapse>
  )
}

export default OrganisationSection
