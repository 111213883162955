import React, { useEffect } from 'react'
import ConfirmContent from './ConfirmContent'
import { Modal, Paper } from '@material-ui/core'
import { IConfirmOverlayProps } from './types'

const ConfirmOverlay = ({
  open,
  onClose,
  onConfirm,
  title,
  confirmTitle,
  denyTitle,
  question,
}: IConfirmOverlayProps) => {
  useEffect(() => {
    const escHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        //ESC
        onClose()
      }
    }
    document.addEventListener('keydown', escHandler, false)
    return () => document.removeEventListener('keydown', escHandler, false)
  }, [onClose])
  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={onClose}
    >
      <Paper variant='outlined' square>
        <ConfirmContent
          title={title}
          onClose={onClose}
          onConfirm={onConfirm}
          confirmTitle={confirmTitle}
          denyTitle={denyTitle}
          question={question}
        />
      </Paper>
    </Modal>
  )
}

export { ConfirmOverlay }
