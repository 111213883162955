import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { useParams, useLocation, Redirect } from 'react-router'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../../components/ToastMessage/ToastService'
import { useIntl } from 'react-intl'

const JoinRequest = () => {
  const { id } = useParams()
  const [redirect, setRedirect] = useState('')
  const location = useLocation()
  const { accept } = queryString.parse(location.search)
  const intl = useIntl()

  useEffect(() => {
    if (!!id && !!accept) {
      const successKey =
        accept === 'true'
          ? 'components.joinRequest.successAccept'
          : 'components.joinRequest.successDeny'
      apiService.organisation
        .decideRequest(id, accept === 'true')
        .then((res) => {
          openToast({
            variant: 'success',
            message: intl.formatMessage({ id: successKey }),
          })
        })
        .catch((e) => {
          openToast({
            variant: 'failure',
            message: intl.formatMessage({ id: 'errors.invalidJoinRequest' }),
          })
        })
        .finally(() => {
          setRedirect('/dashboard')
        })
    }
  }, [accept, id, intl])

  if (redirect) {
    return <Redirect to={redirect} />
  }
  return <div></div>
}

export default JoinRequest
