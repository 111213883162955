import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
`

export const GeneralContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 800px) {
    width: 350px;
    margin: 0 5px;
  }
`

export const Caption = styled.div`
  font-size: ${({ theme }) => theme.fontSize.md};
  max-width: 40rem;
  padding: 2rem 0;
  width: 100%;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.xl};
`
