import React, { useContext, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput, FilledButton } from '../../../../components'
import { openToast } from '../../../../components/ToastMessage/ToastService'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import CopyCode from './CopyCode'
import useTheme from '../../../../utils/hooks/useTheme'
import { detectColorScheme } from '../../../../utils/detectColorScheme'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../../models/IUser'
import { TfaContext } from './TwoFactorAuth'
import { apiService } from '../../../../service/api/apiService'
import { IActivationContentProps } from './types'
import {
  Caption,
  CodeInputContainer,
  Container,
  Link,
  LinkBtn,
  QRImage,
  Title,
} from './styles'
import { Steps } from './constants'

const ActivationContent = ({
  onCancel,
  isRegister,
}: IActivationContentProps) => {
  const intl = useIntl()
  const isDesktop = useMediaQuery('(min-width:701px)')
  const [qrVisible, setQrVisible] = useState(isDesktop)
  const { theme } = useTheme()
  const actualTheme = detectColorScheme(theme)
  const [user] = useLocalStorage<IUser>('user')
  const [{ qr, otp }, setTfaState] = useContext(TfaContext)

  useEffect(() => {
    setQrVisible(isDesktop)
  }, [isDesktop])

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      openToast({
        message: intl.formatMessage({ id: 'success.copyCode' }),
        variant: 'success',
      })
    }
  }, [copied, intl])

  const onChange = async (token: string, status: boolean) => {
    if (status && user && user.email) {
      try {
        const { backupCode } =
          (await apiService.user.validateTwoFactorQr({
            email: user.email,
            token,
          })) ?? ''
        setTfaState((state) => ({
          ...state,
          backupCode,
          isEnabled: true,
          step: Steps.TOKEN,
        }))
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'settings.twofactor.validateSuccess',
          }),
        })
      } catch (e) {
        openToast({
          variant: 'failure',
          message: intl.formatMessage({
            id: 'settings.twofactor.validateFailed',
          }),
        })
      }
    }
  }

  const handleCopyClick = () => setCopied(true)

  const toggleQR = () => setQrVisible((visible) => !visible)

  if (!qr || !otp) return null
  return (
    <Container>
      <Caption>
        {isRegister && (
          <Title>
            <FormattedMessage id='settings.twofactor.qrTitle' />
          </Title>
        )}
        {qrVisible ? (
          <>
            <FormattedMessage id='settings.twofactor.qrInstruction' /> <br />
            <Link
              rel='noreferrer noopener'
              target='_blank'
              href='https://www.microsoft.com/de-de/account/authenticator'
            >
              Microsoft Authenticator
            </Link>{' '}
            <FormattedMessage id='settings.twofactor.or' />{' '}
            <Link
              rel='noreferrer noopener'
              target='_blank'
              href='https://authy.com/'
            >
              Authy
            </Link>
            .
          </>
        ) : isDesktop ? (
          <FormattedMessage id='settings.twofactor.codeInstruction' />
        ) : (
          <FormattedMessage id='settings.twofactor.mobileCodeInstruction' />
        )}
      </Caption>
      {qrVisible ? (
        <QRImage
          invert={actualTheme === 'dark'}
          alt='QR code'
          src={`data:image/svg+xml;base64,${qr}`}
          width={140}
          height={140}
        />
      ) : (
        <CopyCode code={otp} onCopy={handleCopyClick} />
      )}
      {isDesktop && (
        <Caption>
          {qrVisible ? (
            <FormattedMessage id='settings.twofactor.cantScan' />
          ) : (
            <FormattedMessage id='settings.twofactor.wantScan' />
          )}{' '}
          <LinkBtn onClick={toggleQR}>
            <FormattedMessage id='settings.twofactor.clickHere' />
          </LinkBtn>
        </Caption>
      )}
      <Caption>
        <FormattedMessage id='settings.twofactor.enterCode' />
      </Caption>
      <CodeInputContainer>
        <CodeInput name='twoFactorVerify' length={6} change={onChange} />
      </CodeInputContainer>
      {isRegister && (
        <FilledButton onClick={onCancel}>
          <FormattedMessage id='common.cancel' />
        </FilledButton>
      )}
    </Container>
  )
}

export default ActivationContent
