import Cookies from 'js-cookie'

export const setLoggedInCookie = () => {
  Cookies.set('fid-is-loggedin', 'true', {
    domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
    expires: 60,
  })
}

export const setLanguageCookie = (code: string) => {
  Cookies.set('dff-lang', code, {
    domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
    expires: 60,
  })
}

export const getLanguageCookie = () => {
  return Cookies.get('dff-lang')
}
