import React from 'react'
import { useIntl } from 'react-intl'
import { IProgressBarProps } from './types'
import { ProgressInner, ProgressOuter, ProgressTooltip } from './styles'

const ProgressBar = ({ percent, displayValue }: IProgressBarProps) => {
  const intl = useIntl()
  const rtl = intl.locale === 'ar'
  return (
    <ProgressOuter>
      <ProgressInner percent={percent}>
        <ProgressTooltip rtl={rtl}>{displayValue}</ProgressTooltip>
      </ProgressInner>
    </ProgressOuter>
  )
}

export { ProgressBar }
