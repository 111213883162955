import React from 'react'
import { FormattedMessage } from 'react-intl'
import PendingMember from './PendingMember'
import { ListContainer } from './StyledComponents'
import { OuterContainer, PendingMembersDescription } from './styles'
import { IPendingMembersProps } from './types'
import { resolveName } from './utils'

const PendingMembers = ({
  value = [],
  editable,
  isInvitation,
  organisationId,
}: IPendingMembersProps) => {
  return editable ? (
    <OuterContainer>
      <PendingMembersDescription>
        <FormattedMessage id='components.members.awarenessWarning' />
      </PendingMembersDescription>
      <ListContainer>
        {value.map((request) => {
          const { user, invites, id } = request
          // only read out first invites object. Invitations can contain multiple invites, but not for invitations which are created for an orgaisation, as they will always only contain one user
          const invite = invites?.length ? invites[0] : undefined
          return !isInvitation || invite ? (
            <PendingMember
              key={id}
              organisationId={organisationId}
              isInvitation={isInvitation}
              requestId={id}
              name={isInvitation ? resolveName(invite) : resolveName(user)}
              email={isInvitation ? invite?.email ?? '' : user.email}
              imageSrc={isInvitation ? '' : user.profilePicture}
              editable={editable ?? false}
            />
          ) : null
        })}
      </ListContainer>
    </OuterContainer>
  ) : null
}

export { PendingMembers }
