import { MOCK_API, TOKEN, USER_KEY } from './constants'

export const removeLoginData = () => {
  window.localStorage.removeItem(USER_KEY)
  window.localStorage.removeItem(TOKEN)
  // leave the collapse value in the local storage
  // on logout (see ID-473)
}

export const getMockApi = () => window.localStorage.getItem(MOCK_API)

const enableMockApi = () => window.localStorage.setItem(MOCK_API, 'true')

const disableMockApi = () => window.localStorage.removeItem(MOCK_API)

export const toggleMockApi = () =>
  getMockApi() === 'true' ? disableMockApi() : enableMockApi()
