import React, { useState } from 'react'
import { LoginRegisterForm } from '../../components'
import TwoFactorAuth from '../settings/SettingsMenu/TwoFactorAuth/TwoFactorAuth'
import VerificationCode from '../../components/VerificationCode/VerificationCode'
import Intro from './Intro'
import ConfirmContent from '../../components/ConfirmOverlay/ConfirmContent'
import { FormattedMessage } from 'react-intl'
import { steps } from './constants'
import { IContentProps } from './types'

const Content = ({
  step,
  onChangeEmail,
  onVerificationSubmit,
  onChangePassword,
  onEnter,
  isEmailDisabled,
  emailExists,
  isValid,
  currentIndex,
  stepLabels,
  onNextStep,
}: IContentProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false)

  if (step === steps.REGISTER) {
    return (
      <LoginRegisterForm
        emailExists={emailExists}
        onChangeEmail={onChangeEmail}
        onChangePassword={onChangePassword}
        disabled={!isValid}
        stepLabels={stepLabels}
        currentIndex={currentIndex}
        onNextStep={onNextStep}
        isEmailDisabled={isEmailDisabled}
      />
    )
  } else if (step === steps.CODE) {
    return <VerificationCode onSubmit={onVerificationSubmit} />
  } else if (step === steps.TFA) {
    return isConfirmed ? (
      <TwoFactorAuth open isRegister onClose={onEnter} />
    ) : (
      <ConfirmContent
        title={<FormattedMessage id='settings.twofactor.title' />}
        onClose={onEnter}
        onConfirm={() => setIsConfirmed(true)}
        confirmTitle={<FormattedMessage id='settings.twofactor.confirm' />}
        denyTitle={<FormattedMessage id='settings.twofactor.skip' />}
        question={<FormattedMessage id='settings.twofactor.registerCaption' />}
        hideClose
      />
    )
  } else if (step === steps.INTRO) {
    return <Intro onClose={onEnter} />
  }
  return null
}

export default Content
