import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { AppContext } from '../../utils/ContextWrapper'
import { Checkbox, LinksContainer, TermsLink } from './styles'

const Links = () => {
  const intl = useIntl()
  const [appState, setAppState] = useContext(AppContext)
  const { terms } = appState

  const handleCheckboxClick = () =>
    setAppState((state) => ({
      ...state,
      terms: { ...state.terms, accepted: !state.terms.accepted },
    }))

  const isRtl = intl.locale === 'ar'

  return (
    <LinksContainer>
      <Checkbox
        aria-label='Agree to Terms and Conditions'
        checked={terms.accepted}
        onChange={handleCheckboxClick}
        isRtl={isRtl}
        type='checkbox'
      />
      <span>
        <FormattedMessage id='auth.labels.terms1' />
      </span>
      <TermsLink
        href='https://www.dubaifuture.ae/terms-of-service/'
        target='_blank'
      >
        <FormattedMessage id='auth.labels.terms2' />
      </TermsLink>
      {'& '} <br />
      <TermsLink
        href='https://www.dubaifuture.ae/privacy-policy/'
        target='_blank'
      >
        <FormattedMessage id='auth.labels.terms3' />
      </TermsLink>
      {'.'}
    </LinksContainer>
  )
}

export default Links
