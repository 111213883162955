import React from 'react'
import ActionButton from './ActionButton'
import { ReactComponent as QuestionMark } from '../../assets/icons/other/icon_questionmark.svg'
import { SectionTitle } from '../../pages/dashboard/Section'
import { Header, Title } from './styles'
import { SectionHeaderProps } from './types'

const SectionHeader = ({
  title,
  onAction,
  pending,
  hideMobile,
  id,
}: SectionHeaderProps) => {
  return (
    <Header hideMobile={hideMobile}>
      <SectionTitle>
        <Title id={id} className={id}>
          {title}
        </Title>
        {pending && <QuestionMark title='Pending' />}
      </SectionTitle>
      {onAction && (
        <ActionButton
          onAction={
            onAction as (event: React.MouseEvent<HTMLButtonElement>) => void
          }
        />
      )}
    </Header>
  )
}

export { SectionHeader }
