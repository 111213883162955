import { useEffect, useContext } from 'react'
import { detectColorScheme } from '../detectColorScheme'
import Cookies from 'js-cookie'
import { AppContext } from '../ContextWrapper'

const useTheme = () => {
  const [{ theme }, setAppState] = useContext(AppContext)

  const setTheme = (theme: 'light' | 'dark' | 'auto') =>
    setAppState((state) => ({ ...state, theme }))

  useEffect(() => {
    if (theme) {
      Cookies.set('color-scheme', theme, {
        domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
      })

      const detectedTheme = detectColorScheme(theme)
      if (theme === 'dark' || detectedTheme === 'dark') {
        document.documentElement.setAttribute('data-theme', 'dark')
      } else if (theme === 'light' || detectedTheme === 'light') {
        document.documentElement.setAttribute('data-theme', 'light')
      }
    }
  }, [theme])

  const toggleTheme = () => {
    const detectedTheme = detectColorScheme(theme)
    setTheme(detectedTheme === 'light' ? 'dark' : 'light')
  }

  return { theme, setTheme, toggleTheme }
}

export default useTheme
