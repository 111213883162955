import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    justify-content: space-between;
    width: 100%;
  }
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.spacing.xs};
`

export const Input = styled.input<{ small?: boolean }>`
  background: var(--background);
  color: var(--primary);
  max-width: ${({ small }) => (small ? '4rem' : '5rem')};
  height: ${({ small }) => (small ? '4rem' : '5rem')};
  border: 1px solid var(--grey);
  border-radius: 0;
  text-align: center;
  margin: 0 0.5rem;
  @media only screen and (max-width: 480px) {
    margin: 0;
    height: ${({ small }) => (small ? '3rem' : '3.5rem')};
    max-width: ${({ small }) => (small ? '3rem' : '3.5rem')};
  }
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.lg};
  outline: unset;
`

export const Caption = styled.div`
  margin: 5rem 0;
  font-size: ${({ theme }) => theme.fontSize.md};
  flex-basis: 100%;
  color: var(--primary);

  button {
    cursor: pointer;
    border: none;
    background: transparent;
    color: var(--color);
    font-weight: bold;
  }
`

export const TryAgainButton = styled.button`
  font-size: ${({ theme }) => theme.fontSize.md};
`
