import React, { useEffect, useRef, useContext } from 'react'
import LoadingBar, { TopLoadingBarRef } from 'react-top-loading-bar'
import { TopLoadingBarContext } from '../../utils/ContextWrapper'

function TopLoadingBar() {
  const ref = useRef<TopLoadingBarRef | undefined>()
  const [loadingBar, setLoadingBar] = useContext(TopLoadingBarContext)

  useEffect(() => {
    if (ref.current) {
      if (loadingBar.start) {
        ref.current.continuousStart()
      } else if (loadingBar.end) {
        ref.current.complete()
      }
    }
  }, [loadingBar.start, loadingBar.end, setLoadingBar])

  return <LoadingBar ref={ref} height={3} color='var(--primary)' />
}

export { TopLoadingBar }
