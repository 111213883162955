import React, { createContext, useState } from 'react'
import Cookies from 'js-cookie'
import { tabs } from '../components/AppHeader/constants'

export const ArrowNavigationContext = React.createContext<any>(null)

export interface AuthData {
  email: string
  password: string
}

export const TabContext = createContext<
  [tabs, React.Dispatch<React.SetStateAction<tabs>>]
>([tabs.PROFILE, (tab) => tabs.PROFILE])

export const AuthDataContext = createContext<
  [AuthData, React.Dispatch<React.SetStateAction<AuthData>>]
>([{ email: '', password: '' }, (str) => ({ email: '', password: '' })])

export const OverlayContext = React.createContext<
  [boolean, React.Dispatch<React.SetStateAction<boolean>>]
>([false, (b) => false])

export const UrlContext = createContext<
  [string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]
>(['', (str) => ''])

interface TopLoadingBarState {
  start: boolean
  end: boolean
}
export const TopLoadingBarContext = createContext<
  [TopLoadingBarState, React.Dispatch<React.SetStateAction<TopLoadingBarState>>]
>([
  {
    start: false,
    end: false,
  },
  (str) => ({
    start: false,
    end: false,
  }),
])

interface AppState {
  theme: 'light' | 'dark' | 'auto'
  terms: {
    accepted: boolean
    tocVisible: boolean
    privacyVisible: boolean
  }
  authorizeData: {
    appId: string
    next: string
    resetToken: boolean
    isRegister?: boolean
  }
  mandatoryFields: string[]
  missingFields: string[]
}

export const AppContext = createContext<
  [AppState, React.Dispatch<React.SetStateAction<AppState>>]
>([
  {
    theme: 'auto',
    authorizeData: {
      appId: '',
      next: '',
      resetToken: false,
    },
    terms: { accepted: false, tocVisible: false, privacyVisible: false },
    mandatoryFields: [],
    missingFields: [],
  },
  (str) => '',
])

const ContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [url, setUrl] = useState<string | undefined>()
  const [loadingBar, setTopLoadingBar] = useState({
    start: false,
    end: false,
  })
  const [isOverlayOpen, setIsOverlayOpen] = useState<boolean>(false)
  const [tab, setTab] = useState<tabs>(tabs.PROFILE)
  const [arrowNavigationEnabled, setArrowNavigationEnabled] = useState(true)
  const storedTheme = Cookies.get('color-scheme') as 'light' | 'dark' | 'auto'

  const [authData, setAuthData] = useState<AuthData>({
    email: '',
    password: '',
  })

  const [appState, setAppState] = useState<AppState>({
    authorizeData: {
      appId: '',
      next: '',
      resetToken: false,
    },
    theme: storedTheme ?? 'auto',
    terms: { accepted: false, tocVisible: false, privacyVisible: false },
    mandatoryFields: [],
    missingFields: [],
  })

  return (
    <OverlayContext.Provider value={[isOverlayOpen, setIsOverlayOpen]}>
      <AuthDataContext.Provider value={[authData, setAuthData]}>
        <ArrowNavigationContext.Provider
          value={[arrowNavigationEnabled, setArrowNavigationEnabled]}
        >
          <UrlContext.Provider value={[url, setUrl]}>
            <TabContext.Provider value={[tab, setTab]}>
              <AppContext.Provider value={[appState, setAppState]}>
                <TopLoadingBarContext.Provider
                  value={[loadingBar, setTopLoadingBar]}
                >
                  {children}
                </TopLoadingBarContext.Provider>
              </AppContext.Provider>
            </TabContext.Provider>
          </UrlContext.Provider>
        </ArrowNavigationContext.Provider>
      </AuthDataContext.Provider>
    </OverlayContext.Provider>
  )
}

export default ContextWrapper
