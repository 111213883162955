export const PLACEHOLDERS = ['year', 'month', 'day']

const currentYear = new Date().getFullYear()

const YEARS: { value: string; label: string }[] = Array(500)
  .fill(0)
  .map((_, index) => ({
    value: currentYear - index + '',
    label: currentYear - index + '',
  }))

const selectMap = (_: number, index: number) => {
  const stringDay = (index + 1 < 10 ? '0' + (index + 1) : index + 1).toString()
  return {
    value: stringDay,
    label: stringDay,
  }
}
const MONTHS = Array(12).fill(0).map(selectMap)

const DAYS = Array(31).fill(0).map(selectMap)

export const OPTIONS = [YEARS, MONTHS, DAYS]
