import React from 'react'
import { Circle, ProgressLine, RegisterProgressContainer } from './styles'
import { RegisterProgressProps } from './types'

const RegisterProgress = ({ step }: RegisterProgressProps) => {
  return (
    <RegisterProgressContainer>
      <Circle done={step >= 0} />
      <ProgressLine done={step > 0} />
      <Circle done={step >= 1} />
      <ProgressLine done={step > 1} />
      <Circle done={step >= 2} />
    </RegisterProgressContainer>
  )
}

export default RegisterProgress
