import React, { useContext } from 'react'
import { OverlayContext } from '../../../utils/ContextWrapper'
import { FormattedMessage } from 'react-intl'
import { ButtonLink } from './styles'

const ReachUsButton = () => {
  const [isOverlayOpen] = useContext(OverlayContext)
  return (
    <ButtonLink
      tabIndex={isOverlayOpen ? -1 : 0}
      aria-label='Link to DFF Reach Us Page'
      href={`${process.env.REACT_APP_DFF_REDIRECT_URI}/reach-us/`}
    >
      <FormattedMessage id='header.reachUs' />
    </ButtonLink>
  )
}

export default ReachUsButton
