import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { OverlayScreen } from '..'
import TextInput from '../TextInput/TextInput'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../ToastMessage/ToastService'
import { IInviteProps } from './types'
import { Form, StyledButton, Title } from './styles'

const InviteOverlay = ({ open, onClose, title }: IInviteProps) => {
  const [email, setEmail] = useState('')
  const intl = useIntl()
  const [valid, setValid] = useState(false)

  const handleChange = (value: string, valid: boolean) => {
    setEmail(value)
    setValid(valid)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    apiService.invite
      .inviteMember(email)
      .then((res) => {
        openToast({
          message: intl.formatMessage({
            id: 'components.inviteOverlay.success',
          }),
          variant: 'success',
        })
        setEmail('')
        onClose()
      })
      .catch((e) => {
        openToast({
          message: intl.formatMessage({ id: 'errors.genericFail' }),
          variant: 'failure',
        })
        setEmail('')
        onClose()
      })
  }

  return (
    <OverlayScreen isOpen={open} onClose={onClose}>
      <Form onSubmit={handleSubmit}>
        <Title>{title}</Title>
        <TextInput
          fluid
          disableDebounce
          disableRtl
          editable
          key='email-login'
          name='email'
          placeholder={intl
            .formatMessage({ id: 'auth.placeholder.email' })
            .toString()}
          type='email'
          value={email}
          defaultValue={''}
          change={handleChange}
        />
        <StyledButton type='submit' disabled={!valid}>
          <FormattedMessage id='components.inviteOverlay.invite' />
        </StyledButton>
      </Form>
    </OverlayScreen>
  )
}

export { InviteOverlay }
