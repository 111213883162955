import React, { useContext, useEffect, useState } from 'react'
import { OverlayContext } from '../../../utils/ContextWrapper'
import { DropdownMenu, ProfileBtn } from './styles'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/other/icon_profile.svg'
import { ProfileButtonProps } from './types'

const ProfileButton = ({ children }: ProfileButtonProps) => {
  const [isOverlayOpen] = useContext(OverlayContext)
  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen((open) => !open)

  useEffect(() => {
    const escHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
      }
    }
    document.addEventListener('keydown', escHandler, false)
    return () => document.removeEventListener('keydown', escHandler, false)
  }, [])

  return (
    <>
      <ProfileBtn
        onClick={handleToggle}
        tabIndex={isOverlayOpen ? -1 : 0}
        aria-label='Hover to show Dropdown Menu'
      >
        <ProfileIcon />
      </ProfileBtn>
      <DropdownMenu
        isOpen={isOpen}
        dir='ltr'
        style={{ direction: 'ltr !important' as any }}
      >
        {children}
      </DropdownMenu>
    </>
  )
}

export default ProfileButton
