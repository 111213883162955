import React from 'react'
import { apiService } from '../../../service/api/apiService'
import useSWR from 'swr'
import { openToast } from '../../../components/ToastMessage/ToastService'
import OverlayField from '../OverlayField'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { useIntl } from 'react-intl'
import { IOverlayFieldProps } from './types'

const OverlayFieldWrapper = ({
  step,
  performSecondary,
  updateUserData,
}: IOverlayFieldProps) => {
  const intl = useIntl()
  const [user] = useLocalStorage<IUser>('user')
  const { data, mutate } = useSWR(
    !!user ? ['user', user.id] : null,
    (key: string, userId: string) => apiService.user.get(userId),
  )

  const updateFieldsData = async (
    value: string | File | string[],
    status: boolean,
  ) => {
    const updatedStep = {
      ...step,
      value,
      completed: status,
    }

    if (status) {
      updateUserData(updatedStep)
    }

    if (
      data &&
      user &&
      step?.field === 'photo-upload' &&
      value instanceof window.File
    ) {
      try {
        const { profilePicture } = await apiService.user.uploadPhoto(
          user.id,
          value,
        )
        mutate({ ...data, profilePicture }, false)
        openToast({
          message: intl.formatMessage({
            id: 'components.photoUpload.photoUploadSuccess',
          }),
          variant: 'success',
        })
      } catch (e) {
        openToast({
          message: intl.formatMessage({
            id: 'components.photoUpload.photoUploadFailed',
          }),
          variant: 'failure',
        })
      }
    }
  }

  return (
    <OverlayField
      editable
      step={step}
      updateFieldsData={updateFieldsData}
      performSecondary={performSecondary}
    />
  )
}
export default OverlayFieldWrapper
