import { Server } from 'miragejs'

const members = Array.from(Array(10).keys()).map((val, index) => ({
  id: 'id' + index,
  email: 'felix.wohnhaas@outlook.com' + index,
  firstName: 'Felix' + index,
  lastName: 'Wohnhaas' + index,
}))
function seed(server: Server) {
  // ORGANISATION SEED
  server.create('organisation', {
    id: 'aölsdkalsödkaslödjkaslödjaklsödkalösd',
    name: 'Orga1',
    socialMedia: [{ type: 'Facebook', url: 'facebook.com/asd' }],
    admins: [
      {
        id: 'id1',
        email: 'felix.wohnhaas@outlook.com',
        firstName: 'Felix',
        lastName: 'Wohnhaas',
      },
    ],
    pendingRequests: [
      {
        id: 'id3',
        user: {
          email: 'felix.wohnhaas@tum.de',
          firstName: 'Felix',
          lastName: 'Tum',
        },
        createdAt: '',
      },
    ],
    pendingInvitations: [
      {
        id: 'id3',
        invites: [
          {
            email: 'felix.wohnhaas@tum.de',
            firstName: 'Felix',
            lastName: 'Tum',
            status: 'invited',
          },
        ],
      },
    ],
    members,
    briefDescription: 'Test',
    fullDescription: 'Full Description',
    website: 'https://test.de',
    location: 'Munich',
  })
  // USER SEED
  server.create('user', {
    id: 'id1',
    email: 'felix.wohnhaas@outlook.com',
    password: 'FutureID123!',
    firstName: 'Felix',
    lastName: 'Wohnhaas',
    mobileNumber: '+49123123312',
  })
  server.create('user', {
    id: 'id2',
    email: 'verena.lang@motius.de',
    password: 'FutureID123!',
    firstName: 'Verena',
    lastName: 'Lang',
    mobileNumber: '+49123123312',
  })
  server.create('user', {
    id: 'id3',
    email: 'felix.wohnhaas@tum.de',
    password: 'FutureID123!',
    firstName: 'Felix',
    lastName: 'Tum',
    mobileNumber: '+49123123312',
  })
  // SESSION SEED
  server.create('session', {
    id: Math.floor(Math.random() * 100) + '',
    userAgent:
      '{"os":"OS X","source":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:74.0) Gecko/20100101 Firefox/74.0","browser":"Firefox"}',
    valid: true,
    ipinfo: '127.0.0.1',
    createdAt: '2020-04-06T07:15:29.042Z',
    lastSeenAt: '2020-04-06T08:11:24.605Z',
    updatedAt: '2020-04-06T08:11:24.605Z',
    user: {
      id: 'id',
      email: 'felix.wohnhaas@outlook.com',
      password: 'FutureID123!',
      firstName: 'Felix',
      lastName: 'Wohnhaas',
    },
  })
}

export default seed
