import React, { useEffect, useState } from 'react'
import vCardsJS, { IVCard } from 'vcards-js'
import QRCode from 'qrcode.react'
import { FormattedMessage } from 'react-intl'
import { VCardQrCodeProps } from './types'

const VCardQrCode = ({ user, size }: VCardQrCodeProps) => {
  const [vCard, setVCard] = useState<IVCard>()

  useEffect(() => {
    const card: IVCard = vCardsJS()
    card.firstName = user.firstName
    card.lastName = user.lastName
    card.cellPhone = user.mobileNumber
    card.email = user.email
    if (user.location && card.homeAddress) {
      card.homeAddress.city = user.location
    }
    card.title = user.title as string
    if (user.dob) {
      const date = user.dob.split('-')
      card.birthday = new Date(+date[0], +date[1] - 1, +date[2])
    }
    setVCard(card)
  }, [user])

  if (!vCard) {
    return <FormattedMessage id='common.loading' />
  }

  return <QRCode value={vCard.getFormattedString()} size={160} />
}

export default VCardQrCode
