import React from 'react'
import { FormattedMessage } from 'react-intl'
import { MobileTutorialSteps } from '../Tutorial/tutorialTypes'
import { tabs } from './constants'
import { TabsProps } from './types'
import { Tab, Tabs } from './styles'

const _Tabs = ({ activeTab, onSelect }: TabsProps) => {
  const handleSelect = (tab: tabs) => () => {
    onSelect(tab)
    window.scrollTo(0, 0)
  }
  return (
    <Tabs>
      <Tab
        className={MobileTutorialSteps.PROFILE}
        active={activeTab === tabs.PROFILE}
        onClick={handleSelect(tabs.PROFILE)}
      >
        <FormattedMessage id={tabs.PROFILE} />
      </Tab>
      <Tab
        className={MobileTutorialSteps.PLATFORMS}
        active={activeTab === tabs.PLATFORMS}
        onClick={handleSelect(tabs.PLATFORMS)}
      >
        <FormattedMessage id={tabs.PLATFORMS} />
      </Tab>
      <Tab
        className={MobileTutorialSteps.SETTINGS}
        active={activeTab === tabs.SETTINGS}
        onClick={handleSelect(tabs.SETTINGS)}
      >
        <FormattedMessage id={tabs.SETTINGS} />
      </Tab>
    </Tabs>
  )
}

export default _Tabs
