import styled from 'styled-components'
import { FilledButton } from '../../../components'

export const StyledButton = styled(FilledButton)`
  margin: 0 ${({ theme }) => theme.spacing.md};
  min-width: 140px;
  @media screen and (max-width: 480px) {
    margin: ${({ theme }) => theme.spacing.md} 0;
  }
`
export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`
