import React from 'react'
import TextInput from '../TextInput/TextInput'
import { FormattedMessage } from 'react-intl'
import { Container, Description } from './styles'
import { TextInputProps } from '../TextInput/types'

const LinkedIn = ({ ...props }: TextInputProps) => {
  return (
    <Container>
      <Description>
        <FormattedMessage
          id='components.linkedIn.description'
          values={{
            linkedIn: (
              <a
                aria-label='Go to LinkedIn'
                rel='noreferrer noopener'
                target='_blank'
                href='https://www.linkedin.com'
              >
                LinkedIn
              </a>
            ),
          }}
        />
      </Description>
      <TextInput autoFocus showStatusHints fluid {...props} />
    </Container>
  )
}

export { LinkedIn }
