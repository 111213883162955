import styled from 'styled-components'
import { getBackground, getBorder, getColor } from './utils'
import { FilledButtonInterface } from './types'

export const Button = styled.button<FilledButtonInterface>`
  background-color: ${({ btnType, disabled }) =>
    getBackground(btnType, disabled)};
  color: ${({ btnType, disabled }) => getColor(btnType, disabled)};
  font-size: 12px;
  font-weight: bold;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 5rem;
  min-height: 5rem;
  text-transform: uppercase;
  width: ${({ fluid }) => fluid && '100%'};
  max-width: ${({ fluid }) => (fluid ? '' : '20rem')};
  min-width: ${({ fluid }) => (fluid ? '' : '10rem')};
  padding: 0 ${({ theme }) => theme.spacing.sm};
  margin: ${({ theme }) => theme.spacing.sm} 0;
  border: 1px solid;
  border-color: ${({ btnType, disabled }) => getBorder(btnType, disabled)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.2s ease-in-out;
  svg,
  path,
  g {
    fill: ${({ btnType, disabled }) => getColor(btnType, disabled)};
  }
  @media (hover: hover) {
    &:hover {
      border-color: ${({ btnType, disabled }) =>
        getBorder(btnType, disabled, true)};

      background-color: ${({ btnType, disabled }) =>
        getBackground(btnType, disabled, true)};
      color: ${({ btnType, disabled }) => getColor(btnType, disabled, true)};
      svg,
      path,
      g {
        fill: ${({ btnType, disabled }) => getColor(btnType, disabled, true)};
      }
    }
  }
`
