import { openToast } from '../components/ToastMessage/ToastService'
import { IntlFormatters } from 'react-intl'

export function* renderErrorToast(
  response: any,
  messageKey: string,
  intl: IntlFormatters,
  callback?: (message: string) => any,
) {
  if (response && response.status !== 401) {
    const message = intl.formatMessage({ id: messageKey }).toString()
    yield callback && callback(message)
    openToast({
      message,
      variant: 'failure',
    })
  } else {
    const message = intl
      .formatMessage({ id: 'errors.invalidSession' })
      .toString()
    openToast({
      message,
      variant: 'failure',
    })
  }
}
