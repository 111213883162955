import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import MenuLinks from './MenuLinks'
import { AuthDataContext } from '../../utils/ContextWrapper'
import HeaderContainer from './HeaderContainer'
import HeaderLogo from './HeaderLogo'
import { InviteOverlay } from '../InviteOverlay/InviteOverlay'
import ShareCard from '../../pages/share-card/ShareCard'
import { apiService } from '../../service/api/apiService'
import { removeLoginData } from '../../service/localStorage/userStorage'
import { Redirect, useLocation } from 'react-router'
import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import Cookies from 'js-cookie'
import { DrawerBackground, LinksContainer, Menu, ModeButtons } from './styles'
import {
  ReachUsButton,
  ThemeButton,
  ProfileButton,
  LanguageButton,
  MenuTriggerButton,
} from './HeaderButtons'

const AppHeader = () => {
  const { y } = useScrollPosition()
  const isScrolled = y > 0
  const intl = useIntl()
  const [redirect, setRedirect] = useState('')
  const location = useLocation()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isInvite, setIsInvite] = useState(false)
  const [isShare, setIsShare] = useState(false)
  const [, setAuthData] = useContext(AuthDataContext)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const handleLogout = async () => {
    setAuthData({ email: '', password: '' })
    try {
      await apiService.auth.logout()
      Cookies.remove('fid-is-loggedin', {
        domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
      })
    } finally {
      removeLoginData()
      setRedirect('/login')
    }
  }

  const startInvite = () => {
    setIsInvite(true)
  }

  const startShare = () => {
    setIsShare(true)
  }

  if (redirect) return <Redirect to={redirect} />

  return (
    <>
      <ShareCard isOpen={isShare} onClose={() => setIsShare(false)} />
      <InviteOverlay
        title={intl.formatMessage({ id: 'components.inviteOverlay.title' })}
        open={isInvite}
        onClose={() => setIsInvite(false)}
      />
      <DrawerBackground isOpen={isMenuOpen} />
      <HeaderContainer
        isNavbarScrolledDown={isScrolled}
        viewTabs={
          location?.pathname === '/dashboard' ||
          location?.pathname === '/welcome'
        }
      >
        <>
          <MenuTriggerButton isOpen={isMenuOpen} onClick={toggleMenu} />
          <ModeButtons isOpen={isMenuOpen}>
            <LanguageButton />
            <ThemeButton />
          </ModeButtons>
          <HeaderLogo isOpen={isMenuOpen} isNavbarScrolledDown={isScrolled} />

          <Menu>
            <ReachUsButton />
            <LanguageButton />
            <ProfileButton>
              <MenuLinks
                onInvite={startInvite}
                onLogout={handleLogout}
                onShare={startShare}
              />
            </ProfileButton>
            <ThemeButton />
          </Menu>
          <LinksContainer
            isNavbarScrolledDown={isScrolled}
            isOpen={isMenuOpen}
            dir='ltr'
            style={{ direction: 'ltr !important' as any }}
          >
            <MenuLinks
              onLogout={handleLogout}
              onInvite={startInvite}
              onShare={startShare}
            />
            <ReachUsButton />
          </LinksContainer>
        </>
      </HeaderContainer>
    </>
  )
}

export default React.memo(AppHeader)
