import styled from 'styled-components'

export const CloseBtn = styled.button<{
  variant?: 'success' | 'failure' | 'info'
}>`
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  cursor: pointer;
  svg {
    margin: 0;
    transform: rotate(45deg);
    width: 2rem;
    height: 2rem;
    g {
      fill: ${({ variant }) => {
        if (variant === 'failure') {
          return '#fff'
        } else if (variant === 'success') {
          return '#111'
        } else {
          return 'var(--primary)'
        }
      }};
    }
  }
`
export const ToastContainer = styled.div<{
  variant?: 'success' | 'failure' | 'info'
}>`
  padding: 0rem 2rem;
  width: calc(100% - 4rem);
  height: 7rem;
  color: ${({ variant }) => {
    if (variant === 'failure') {
      return '#fff'
    } else if (variant === 'success') {
      return '#111'
    } else {
      return 'var(--primary)'
    }
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  border-bottom: 1px solid var(--primary);
  background: ${({ variant }) => {
    if (variant === 'failure') {
      return 'var(--red)'
    } else if (variant === 'success') {
      return 'var(--green)'
    } else {
      return 'var(--background)'
    }
  }};
  svg {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    padding: 0rem 1rem;
    width: calc(100% - 2rem);
    text-align: center;
  }
`
export const Content = styled.div`
  max-width: 80%;
  margin: 0 1rem;
`
