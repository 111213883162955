import React from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import { memberStyles } from './styles'
import { IRoleSelect } from './types'

const RoleSelect = ({ role, changeRole }: IRoleSelect) => {
  const intl = useIntl()
  return (
    <Select
      aria-label='Select a role for the member'
      value={role}
      onChange={changeRole as any}
      styles={memberStyles}
      options={[
        {
          value: 'admin',
          label: intl.formatMessage({
            id: 'components.members.admin',
          }),
        },
        {
          value: 'member',
          label: intl.formatMessage({
            id: 'components.members.member',
          }),
        },
      ]}
      menuPortalTarget={document.body}
    />
  )
}

export default RoleSelect
