import { RouteProps } from 'react-router'
import React, { useContext, useEffect, useState } from 'react'
import { UrlContext } from '../utils/ContextWrapper'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../models/IUser'
import { Redirect, Route } from 'react-router-dom'

export const AuthRoute = (props: RouteProps) => {
  const { location } = props
  const [, setUrl] = useContext(UrlContext)
  const [user] = useLocalStorage<IUser>('user')
  const [redirect, setRedirect] = useState('')
  const [token] = useLocalStorage('token')
  useEffect(() => {
    if (!user || !token) {
      setUrl(`${location?.pathname}${location?.search}`)
      setRedirect('/login')
    }
    // change in location will rerender this indefinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])
  if (redirect) return <Redirect to={redirect} />

  return user && token ? <Route {...props} /> : null
}
