import { customStyles } from '../Dropdown/styles'

export const phoneSelectStyles = {
  ...customStyles,
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    width: 200,
  }),
  control: (provided: React.CSSProperties, { isSelected }: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid var(--primary)',
    boxShadow: isSelected ? '0 0 0 1px var(--primary)' : '',
    height: 50,
    minWidth: 100,
    background: 'var(--background)',
  }),
}
