import React, { useEffect, useState } from 'react'
import { ReactComponent as Remove } from '../../assets/icons/other/icon_plus.svg'
import RoleSelect from './RoleSelect'
import { useIntl } from 'react-intl'
import { useMediaQuery } from '@material-ui/core'
import { ActionContainer, Container, DeleteButton } from './StyledComponents'
import ListItem from './ListItem'
import MemberImage from './MemberImage'
import { RoleDisplay } from './RoleDisplay'
import { IMemberProps } from './types'

const Member = ({
  addMember,
  name,
  email,
  isAdmin,
  onRemove,
  imageSrc,
  editable,
}: IMemberProps) => {
  const [isSelected, setIsSelected] = useState(false)
  const isMobile = useMediaQuery('(max-width:701px)')
  const intl = useIntl()

  const [role, setRole] = useState({
    value: 'member',
    label: intl.formatMessage({ id: 'components.members.member' }),
  })

  useEffect(() => {
    setRole(
      isAdmin
        ? {
            value: 'admin',
            label: intl.formatMessage({
              id: 'components.members.admin',
            }),
          }
        : {
            value: 'member',
            label: intl.formatMessage({
              id: 'components.members.member',
            }),
          },
    )
  }, [isAdmin, intl])

  const handleRoleChange = (newRole: { value: string; label: string }) => {
    setRole(newRole)
    if (newRole.value !== role.value) {
      addMember(email, newRole.value === 'admin')
    }
  }

  return (
    <Container onClick={() => setIsSelected((selected) => !selected)}>
      <ListItem
        title={name}
        Image={
          <MemberImage
            imageSrc={imageSrc}
            title={name}
            description={email}
            isAdmin={isAdmin}
          />
        }
        isAdmin={isAdmin}
        description={email}
      />
      {(!isMobile || isSelected) && (
        <ActionContainer>
          {editable ? (
            <RoleSelect role={role} changeRole={handleRoleChange} />
          ) : (
            <RoleDisplay isAdmin={isAdmin} />
          )}

          {editable && (
            <DeleteButton
              aria-label={`Remove ${name ?? 'member'}`}
              type='button'
              title='Remove'
              onClick={onRemove}
            >
              <Remove title='Remove' />
            </DeleteButton>
          )}
        </ActionContainer>
      )}
    </Container>
  )
}

export default Member
