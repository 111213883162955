import React, { useContext } from 'react'
import GeneralTFA from './GeneralTFA'
import DisableContent from './DisableContent'
import ActivationContent from './ActivationContent'
import BackupCode from './BackupCode'
import { TfaContext } from './TwoFactorAuth'
import { Steps } from './constants'
import { IContentProps } from './types'

const Content = ({ isRegister, onEnter, onCancel, isValid }: IContentProps) => {
  const [tfaState] = useContext(TfaContext)
  const { step } = tfaState
  if (step === Steps.GENERAL) {
    return (
      <GeneralTFA
        onEnter={onEnter}
        onCancel={onCancel}
        isRegister={isRegister}
      />
    )
  } else if (step === Steps.ENABLE) {
    return <ActivationContent onCancel={onCancel} isRegister={isRegister} />
  } else if (step === Steps.DISABLE) {
    return <DisableContent onCancel={onCancel} />
  } else if (step === Steps.TOKEN) {
    return <BackupCode isRegister={isRegister} onClose={onCancel} />
  }
  return <></>
}

export default Content
