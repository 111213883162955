import styled from 'styled-components'

export const Btn = styled.button<{ transparent?: boolean; rtl?: boolean }>`
  position: absolute;
  top: ${({ transparent }) => (transparent ? '-7.5rem' : '1.5rem')};
  right: ${({ transparent, rtl }) =>
    rtl ? 'unset' : transparent ? '10rem' : '1.5rem'};
  left: ${({ transparent, rtl }) =>
    rtl ? (transparent ? '10rem' : '1rem') : 'unset'};
  border: none;
  background: transparent;

  cursor: pointer;
  padding: 1rem;
  z-index: 1000;
  svg {
    transform: rotate(45deg);
    width: 2rem;
    height: 2rem;
    g {
      fill: var(--primary);
    }
  }
`
