import React, { useContext, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput } from '../../components'
import { openToast } from '../../components/ToastMessage/ToastService'
import { apiService } from '../../service/api/apiService'
import { useAuthorize } from '../authorize/useAuthorize'
import {
  AuthDataContext,
  UrlContext,
  TopLoadingBarContext,
} from '../../utils/ContextWrapper'
import { useLocation, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import { writeStorage } from '@rehooks/local-storage'
import queryString from 'query-string'
import { setLoggedInCookie } from '../../utils/cookies'
import { Link, LinkButton, LinkContainer } from './styles'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  @media only screen and (max-width: 480px) {
    width: 350px;
  }
`

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.lg};
  margin-bottom: 4rem;
  max-width: 40rem;
  text-align: center;
`

const InputCodeContainer = styled.div`
  @media only screen and (max-width: 480px) {
    width: 100%;
  }
`

const TfaLogin = () => {
  const intl = useIntl()
  const location = useLocation()
  const { oauth2_authorize_url: oauth2Url } = queryString.parse(location.search)
  const { isAuthorizeFlow, authorize } = useAuthorize()
  const [redirect, setRedirect] = useState('')
  const [url] = useContext(UrlContext)
  const [{ email, password }, setLoginData] = useContext(AuthDataContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  const redirectToResetTFA = () => {
    setLoginData({ email, password })
    setRedirect(`/reset-tfa`)
  }

  const handleTwoFactor = async (code: string, status: boolean) => {
    if (email && password && status) {
      setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
      try {
        const { token, user } = await apiService.auth.login(
          email,
          password,
          code,
        )
        setLoggedInCookie()
        writeStorage('token', token)
        writeStorage('user', user)
        setLoginData({ email, password })
        if (isAuthorizeFlow) {
          authorize()
        } else {
          if (oauth2Url) {
            const oauthRedirectUrl =
              (process.env.REACT_APP_BACKEND_AUTH_URL ?? '') + oauth2Url
            window.location.href = oauthRedirectUrl
          } else {
            setRedirect(url ?? '/dashboard')
          }
        }
      } catch (e) {
        if (e.status === 499) {
          openToast({
            message: intl.formatMessage({ id: 'errors.invalid2FCode' }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.genericFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
    }
  }
  if (redirect) return <Redirect to={redirect} />

  return (
    <Container>
      <Title>
        <FormattedMessage id='settings.twofactor.loginEnterCode' />
      </Title>
      <InputCodeContainer>
        <CodeInput name='twoFactorVerify' length={6} change={handleTwoFactor} />
      </InputCodeContainer>
      <LinkContainer>
        <LinkButton>
          <FormattedMessage id='auth.labels.backupCodeStart' />
          <Link onClick={redirectToResetTFA}>
            <FormattedMessage id='auth.labels.backupCodeLink' />
          </Link>
          <FormattedMessage id='auth.labels.backupCodeEnd' />
        </LinkButton>
      </LinkContainer>
    </Container>
  )
}

export default TfaLogin
