import React, { useContext, useEffect, useState } from 'react'
import Profile from './Profile/Profile'
import Organisations from './Organisations/Organisations'
import { SectionHeader, Tutorial } from '../../components'
import { TabContext } from '../../utils/ContextWrapper'
import SettingsMenu from '../settings/SettingsMenu/SettingsMenu'
import Platforms from '../settings/Platforms'
import { useScrollPosition } from '../../utils/hooks/useScrollPosition'
import { useIntl } from 'react-intl'
import { TutorialSteps } from '../../components/Tutorial/tutorialTypes'
import { useAuthorize } from '../authorize/useAuthorize'
import { tabs } from '../../components/AppHeader/constants'
import {
  OuterContainer,
  PlatformsSection,
  ProfileSection,
  SettingsSection,
} from './styles'
import { DashboardProps } from './types'

const Dashboard = ({ isWelcome }: DashboardProps) => {
  const intl = useIntl()
  const { isAuthorizeFlow } = useAuthorize()
  const [run, setRun] = useState((isWelcome && !isAuthorizeFlow) ?? false)
  const [activeTab] = useContext(TabContext)
  const { y } = useScrollPosition()
  const isScrolled = y > 0
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Tutorial run={run} setRun={setRun} />
      <OuterContainer isNavbarScrolledDown={isScrolled}>
        <ProfileSection visible={activeTab === tabs.PROFILE}>
          <SectionHeader
            hideMobile
            title={intl.formatMessage({ id: 'dashboard.profileHeader' })}
            id={TutorialSteps.PROFILE}
          />
          <Profile />

          <Organisations />
        </ProfileSection>
        <PlatformsSection visible={activeTab === tabs.PLATFORMS}>
          <Platforms />
        </PlatformsSection>
        <SettingsSection visible={activeTab === tabs.SETTINGS}>
          <SettingsMenu restartTutorial={() => setRun(true)} />
        </SettingsSection>
      </OuterContainer>
    </div>
  )
}

export default Dashboard
