import { axiosIdentity } from './axiosInstances'
import { AxiosResponse } from 'axios'

export const getOrganisation = (organisationId: string) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}`)
    .then((response) => response.data)
}

export const updateOrganisation = (
  organisationId: string,
  field: string,
  value: any,
) => {
  return axiosIdentity
    .patch(`/organisations/${organisationId}`, { [field]: value })
    .then((response) => response.data)
}

export const createOrganisation = (name: string) => {
  return axiosIdentity
    .post('/organisations', { name })
    .then((response) => response.data)
}

export const deleteOrganisation = (organisationId: string) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}`)
    .then((response) => response.data)
}

export const addOrganisationLogo = (organisationId: string, logo: File) => {
  const formData = new window.FormData()
  formData.append('logo', logo)

  return axiosIdentity
    .post(`/organisations/${organisationId}/logo`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => response.data)
}

export const addOrganisationMember = (
  organisationId: string,
  email: string,
) => {
  return axiosIdentity
    .post(`/organisations/${organisationId}/members`, { email })
    .then((response) => response.data)
}
export const removeOrganisationMember = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}/members/${userId}`)
    .then((response) => response.data)
}

export const getOrganisationAdmins = (organisationId: string) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/admins`)
    .then((response) => response.data)
}
export const getOrganisationAdmin = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .get(`/organisations/${organisationId}/admins/${userId}`)
    .then((response) => response.data)
}
export const addOrganisationAdmin = (organisationId: string, email: string) => {
  return axiosIdentity
    .post(`/organisations/${organisationId}/admins`, { email })
    .then((response) => response.data)
}
export const removeOrganisationAdmin = (
  organisationId: string,
  userId: string,
) => {
  return axiosIdentity
    .delete(`/organisations/${organisationId}/admins/${userId}`)
    .then((response) => response.data)
}

export const joinRequest = (organisationId: string) => {
  return axiosIdentity
    .post(`/organisations/${organisationId}/joinRequest`)
    .then((response) => response.data)
}

export const names = (filter: string) => {
  return axiosIdentity
    .get(`/organisations/names?name=${filter}`)
    .then(
      (response: AxiosResponse<{ data: { name: string; id: string }[] }>) =>
        response.data,
    )
}
export const decideRequest = (
  requestId: string,
  accept: boolean,
  isAdmin?: boolean,
) => {
  return axiosIdentity
    .post(`/organisations/decideRequest/${requestId}`, {
      accept,
      isAdmin: isAdmin ?? false,
    })
    .then((response) => response.data)
}

export const invite = (id: string, email: string) => {
  return axiosIdentity
    .post(`/organisations/${id}/invite`, {
      email,
    })
    .then((response) => response.data)
}

export const deleteInvitation = (id: string, invitationId: string) => {
  return axiosIdentity
    .delete(`/organisations/${id}/invite/${invitationId}`)
    .then((response) => response.data)
}
