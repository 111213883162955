import { RouteProps } from 'react-router'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../models/IUser'
import React, { useEffect, useState } from 'react'

export const NoAuthRoute = (props: RouteProps) => {
  const location = useLocation()
  const [token] = useLocalStorage('token', undefined)
  const [user] = useLocalStorage<IUser>('user')
  const [redirect, setRedirect] = useState('')
  useEffect(() => {
    // dont navigate on these routes because they handle it internally
    if (
      token &&
      user &&
      !location.pathname.includes('register') &&
      !location.pathname.includes('login') &&
      !location.pathname.includes('emailverify') &&
      !location.pathname.includes('verification-code') &&
      !location.pathname.includes('welcome')
    ) {
      setRedirect('/dashboard')
    }
    // change in location will rerender this indefinitely
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user])
  if (redirect) return <Redirect to={redirect} />
  return <Route {...props} />
}
