import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'

export const TooltipFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  padding: 5px;
`
export const Button = styled.button`
  color: var(--background);
  background: none;
  font-weight: 700;
  border: none;
  font-size: 14px;
  cursor: pointer;
`
export const ProgressButton = styled(FilledButton)`
  border-color: var(--background);
  margin-bottom: 30px;
`
export const TooltipBody = styled.div`
  background-color: var(--primary);
  color: var(--background);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 35px;
  width: 300px;
  position: relative;
`
export const TooltipContent = styled.div`
  color: var(--background);
  text-align: center;
  margin-bottom: 3rem;
`
