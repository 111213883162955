import styled from 'styled-components'
import { FooterLinksProps, LogoProps } from './types'

export const Container = styled.footer`
  color: #fff;
  background: var(--footer-background);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 1.4rem;
  padding: 4.5rem 4rem;
  box-sizing: border-box;
  @media (max-width: 960px) {
    padding: 2rem;
    flex-direction: column;
    align-items: flex-start;
  }
`
export const FooterLinks = styled.div<FooterLinksProps>`
  @media (max-width: 960px) {
    display: flex;
    flex-direction: row;
  }
`
export const FooterLink = styled.a`
  color: #aaa;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.sm} !important;
  text-decoration: none;
  padding: 0 1.5rem;
  border: none;
  background: none;
  &:nth-of-type(2n) {
    border-left: 1px solid #fff;
  }
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 960px) {
    order: 2;
    margin: 1.5rem 0;
    padding: 0;
    &:nth-of-type(2n) {
      border: none;
    }
    &:last-child {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid var(--lightgrey);
    }
  }
`
export const DffContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 960px) {
    flex-direction: column-reverse;
    align-items: flex-start;
  }
`
export const Logo = styled.img<LogoProps>`
  width: 26rem;
  /* filter: ${({ colorScheme }) =>
    colorScheme === 'light' ? ` invert(1)` : ''}; */
  filter: invert(1);
  @media (max-width: 700px) {
    order: 1;
  }
`
export const Copyright = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm} !important;
  margin: 0 2rem;
  color: #ffffff;
  @media (max-width: 960px) {
    margin: 1.5rem 0;
    order: 3;
  }
`
