import React, { useContext } from 'react'
import InlineSvg from 'react-inlinesvg'
import { ReactComponent as Check } from '../../assets/icons/other/icon_check.svg'
import { OverlayContext } from '../../utils/ContextWrapper'
import { IconLabelBtnProps } from './types'
import {
  CheckIcon,
  IconButton,
  IconContainer,
  Label,
  Notifications,
} from './styles'

const _IconLabelBtn = ({
  iconFileName,
  label,
  url,
  onClick,
  className,
  active,
  completed,
  hasCheckIcon,
  hasNotificationIcon,
  notifications,
  isImage,
  disabled,
}: IconLabelBtnProps) => {
  const [isOverlayOpen] = useContext(OverlayContext)
  let imgSrc = url || ''
  if (!isImage) {
    try {
      imgSrc = require(`../../assets/icons/${iconFileName}.svg`)
    } catch (e) {
      imgSrc = require(`../../assets/icons/settings/settings_admin.svg`)
    }
  }
  const getState = () => {
    if (completed) {
      return 'completed'
    }
    return disabled ? 'disabled' : 'default'
  }

  return (
    <IconButton
      tabIndex={isOverlayOpen ? -1 : 0}
      disabled={disabled}
      aria-disabled={disabled}
      className={className}
      onClick={onClick}
    >
      <IconContainer state={getState()}>
        {isImage ? (
          <img title={label} src={imgSrc} alt={label} />
        ) : (
          <InlineSvg src={imgSrc} />
        )}
        {hasCheckIcon && (
          <CheckIcon>
            <Check />
          </CheckIcon>
        )}
        {hasNotificationIcon && (
          <CheckIcon>
            <Notifications>{notifications}</Notifications>
          </CheckIcon>
        )}
      </IconContainer>
      {label && <Label state={getState()}>{label}</Label>}
    </IconButton>
  )
}

const IconLabelBtn = React.memo(_IconLabelBtn)
export { IconLabelBtn }
