import React from 'react'
import SocialMedium from './SocialMedium'
import { ListContainer } from '../Members/StyledComponents'
import { ReadOnly } from '../ReadOnly/ReadOnly'
import { ISocialMediaProps } from './types'
import { OuterContainer } from './styles'
import { SOCIAL_MEDIA_LIST } from './constants'

const SocialMedia = ({
  label,
  type,
  value = [],
  change,
  editable,
}: ISocialMediaProps) => {
  const handleRemove = (type: string) => () => {
    change(
      value.filter((medium, index) => medium.type !== type),
      true,
    )
  }

  const onSubmit = (socialMedium: { type: string; url: string }) => {
    change([...value, socialMedium], true)
  }

  if (!value.length && !editable) {
    return <ReadOnly value={null} fieldName={label} />
  }

  return (
    <OuterContainer>
      <ListContainer>
        {Array.isArray(value) &&
          SOCIAL_MEDIA_LIST.map((socialMedium, index) => {
            const existingSocialMedia = value.find(
              (existing) => existing.type === socialMedium.type,
            )
            return editable || existingSocialMedia ? (
              <SocialMedium
                onSubmit={onSubmit}
                onRemove={handleRemove(socialMedium.type)}
                key={index}
                network={socialMedium.type}
                url={existingSocialMedia && existingSocialMedia.url}
                editable={editable}
              />
            ) : null
          })}
      </ListContainer>
    </OuterContainer>
  )
}

export { SocialMedia }
