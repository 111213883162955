import React, { useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { CloseButton } from '../CloseButton/CloseButton'
import { OverlayContext } from '../../utils/ContextWrapper'
import { OverlayScreenProps } from './types'
import { Container, ContentContainer, Overlay } from './styles'

const _OverlayScreen = ({
  children,
  isOpen,
  onClose,
  zIndex = 300,
  transparent,
}: OverlayScreenProps) => {
  const [, setIsOverlayOpen] = useContext(OverlayContext)
  const intl = useIntl()
  const rtl = intl.locale === 'ar'

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    setIsOverlayOpen(isOpen)
  }, [isOpen, setIsOverlayOpen])

  useEffect(() => {
    if (isOpen && onClose) {
      const keyPressHandler = (ev: KeyboardEvent) => {
        if (ev.key === 'Escape') {
          onClose()
        }
      }
      window.addEventListener('keydown', keyPressHandler)

      return () => {
        window.removeEventListener('keydown', keyPressHandler)
      }
    }
  }, [onClose, isOpen])

  return (
    <Overlay
      zIndex={zIndex}
      transparent={transparent}
      isOpen={isOpen}
      aria-live='polite'
    >
      <Container transparent={transparent}>
        {onClose && (
          <CloseButton onClose={onClose} transparent={transparent} rtl={rtl} />
        )}
        <ContentContainer>{isOpen && children}</ContentContainer>
      </Container>
    </Overlay>
  )
}

const OverlayScreen = React.memo(_OverlayScreen)

export { OverlayScreen }
