import 'rc-notification/assets/index.css'
import Notification, { NotificationType } from 'rc-notification'
import React from 'react'
import { ToastContent } from './ToastContent'
import { ToastParams } from './types'

let notification: NotificationType | null = null
const getContainer = () => document.getElementById('toastContainer')

const openToast = ({
  variant = 'info',
  onClose,
  message,
  autoClose = true,
  children = undefined,
}: ToastParams) => {
  Notification.newInstance(
    {
      style: {
        left: 0,
        right: 0,
        top: -10,
        bottom: 'auto',
        width: '100%',
      },
    },
    (n: any) => {
      notification = n

      notification &&
        notification.notice({
          content: (
            <ToastContent
              onClose={() => notification?.destroy()}
              variant={variant}
              message={message}
            >
              {children}
            </ToastContent>
          ),
          onClose() {
            onClose && onClose()
          },
          duration: autoClose ? 3 : Number.MAX_SAFE_INTEGER / 10000,
          style: { padding: 0 },
        })
    },
    getContainer,
  )
}

const closeToast = () => {
  notification && notification.destroy()
}

export { openToast, closeToast }
