import styled from 'styled-components'
import { ReactComponent as CompanyProfile } from '../../assets/icons/decision/company_profile.svg'
import { FilledButton } from '../../components'
import { ReactComponent as Profile } from '../../assets/icons/decision/profile.svg'
import { StyleProps } from './types'
import InfoIcon from '@material-ui/icons/Info'

export const StyledButton = styled(FilledButton)`
  width: 200px;
  margin: 0 1rem;
`
export const Container = styled.div<StyleProps>`
  width: 80%;
  height: 100%;
  min-height: calc(100vh - 75px);
  margin: auto;
  margin-top: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '50px' : '75px'};
  padding: 2rem;
  max-width: 600px;
  @media (max-width: 400px) {
    width: calc(100% - 2rem);
    padding: 1rem;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: var(--primary);
  margin: 1rem 0rem;
`
export const Title = styled.h3`
  font-size: 3.5rem;
  color: var(--primary);
  text-align: center;
  @media screen and (max-width: 480px) {
    margin: 0;
  }
`
export const BtnContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`
const StyledIcon = (Icon: any) => styled(Icon)`
  width: 30px;
  height: 30px;
  fill: var(--primary);
`
export const Description = styled.p`
  margin: 4rem 0rem;
  text-align: center;
`
export const ProfileIcon = StyledIcon(Profile)
export const CompanyProfileIcon = StyledIcon(CompanyProfile)
export const DecisionItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: ${({ expanded }: { expanded: boolean }) =>
    expanded ? '120px' : '50px'};
  transition: height 0.2s ease-in-out;
`
export const IconButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 0;
  background-color: var(--background);
  cursor: pointer;

  &:hover {
    background: var(--darkgrey);
  }

  display: flex;
  justify-content: center;
  align-items: center;
`
export const DecisionItemDescription = styled.p``
export const DetailedDescription = styled.p`
  color: var(--grey);
  font-size: 14px;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ expanded }: { expanded: boolean }) => (expanded ? '1' : '0')};
`
export const Info = styled(InfoIcon)`
  width: 20px !important;
  height: 20px !important;
  fill: var(--primary) !important;
`
