import { FormattedMessage } from 'react-intl'
import { MobileTutorialSteps, TutorialSteps } from './tutorialTypes'
import React from 'react'

export const desktopSteps = [
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${1}`}
        values={{ counter: '1/5' }}
      />
    ),
    placement: 'bottom-start' as const,
    target: `.${TutorialSteps.PROFILE}`,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${2}`}
        values={{ counter: '2/5' }}
      />
    ),
    target: `.${TutorialSteps.ORGANISATION}`,
    placement: 'top' as const,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${3}`}
        values={{ counter: '3/5' }}
      />
    ),
    placement: 'bottom-start' as const,
    target: `.${TutorialSteps.PLATFORMS}`,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${4}`}
        values={{ counter: '4/5' }}
      />
    ),
    placement: 'top-start' as const,
    target: `.${TutorialSteps.SETTINGS}`,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${5}`}
        values={{ counter: '5/5' }}
      />
    ),
    target: `.${TutorialSteps.HOWTO}`,
    placement: 'top' as const,
    disableBeacon: true,
  },
]
export const mobileSteps = [
  {
    content: (
      <FormattedMessage
        id={`dashboard.mobileTutorial.step${1}`}
        values={{ counter: '1/3' }}
      />
    ),
    target: `.${MobileTutorialSteps.PROFILE}`,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${3}`}
        values={{ counter: '2/3' }}
      />
    ),
    target: `.${MobileTutorialSteps.PLATFORMS}`,
    disableBeacon: true,
  },
  {
    content: (
      <FormattedMessage
        id={`dashboard.tutorial.step${4}`}
        values={{ counter: '3/3' }}
      />
    ),
    target: `.${MobileTutorialSteps.SETTINGS}`,
    disableBeacon: true,
  },
]
