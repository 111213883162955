export enum steps {
  EMAILPASSWORD = 'auth.login.steps.step1',
  LOGIN = 'auth.login.steps.step2',
  TWOFACTORTOKEN = 'auth.login.steps.step3',
  AUTHENTICATE = 'auth.login.steps.step4',
}

export enum stepNumbers {
  EMAILPASSWORD = 0,
  LOGIN = 1,
  TWOFACTORTOKEN = 2,
  AUTHENTICATE = 3,
}
