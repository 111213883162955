import React from 'react'
import { ReactComponent as Add } from '../../assets/icons/other/icon_plus.svg'
import { Btn } from './styles'
import { ICloseButtonProps } from './types'

export const CloseButton = ({
  transparent,
  rtl,
  onClose,
}: ICloseButtonProps) => {
  return (
    <Btn
      aria-label='Press to close'
      transparent={transparent}
      rtl={rtl}
      onClick={onClose}
      data-cy='close-button'
    >
      <Add title='Close' />
    </Btn>
  )
}
