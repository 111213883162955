import styled from 'styled-components'

export const ProgressOuter = styled.div`
  width: 100vw;
  height: 10px;
  position: fixed;
  bottom: 0;
  display: flex;
  background: var(--darkgrey);
  z-index: 200;
`
export const ProgressInner = styled.div<{ percent: number }>`
  height: 100%;
  position: relative;
  background: var(--primary);
  width: ${({ percent }) => percent}%;
`
export const ProgressTooltip = styled.div<{ rtl: boolean }>`
  position: absolute;
  color: var(--primary);
  top: -20px;
  right: ${({ rtl }) => (rtl ? '' : '5px')};
  left: ${({ rtl }) => (rtl ? '0px' : '')};
`
