import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput } from '../CodeInput/CodeInput'
import { Email } from '../TextInput/Email'
import {
  CurrentEmail,
  EmailContainer,
  EmailSpan,
  Link,
  Text,
} from './StyledComponents'
import { steps } from './constants'
import { IContentProps } from './types'

const Content = ({
  step,
  newEmail,
  currentEmail,
  onChange,
  onChangeCode,
  onResend,
  onEnter,
}: IContentProps) => {
  const intl = useIntl()
  const handleChange = (value: string, status: boolean) => {
    onChange(value, status)
  }

  const handleResend = () => {
    onResend()
  }

  if (step === steps.CHANGE) {
    return (
      <EmailContainer>
        <CurrentEmail>
          <FormattedMessage id='auth.updateEmail.current' /> {currentEmail}
        </CurrentEmail>
        <Text>
          <FormattedMessage id='auth.updateEmail.text' />
        </Text>
        <Email
          fluid
          email={newEmail}
          disableDebounce
          placeholder={intl.formatMessage({ id: 'auth.placeholder.email' })}
          handleEmailChange={handleChange}
        />
      </EmailContainer>
    )
  } else if (step === steps.CONFIRM) {
    return (
      <EmailContainer>
        <Text>
          <FormattedMessage id='auth.updateEmail.verificationCodeText' />{' '}
          <EmailSpan>{newEmail}</EmailSpan>
          {'. '}
          <FormattedMessage id='auth.updateEmail.verificationCodeTextEnd' />
        </Text>
        <p>
          <FormattedMessage id='auth.updateEmail.resendEmail' />
          <Link onClick={handleResend}>
            <FormattedMessage id='auth.updateEmail.here' />
          </Link>
          .
        </p>
        <CodeInput change={onChangeCode} />
      </EmailContainer>
    )
  }

  return null
}

export default Content
