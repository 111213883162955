import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Caption, Container, Input, TryAgainButton } from './styles'
import { ICodeInputProps } from './types'

const CodeInput = ({
  name,
  change,
  secondaryAction,
  length = 4,
  onEnter,
  email = '',
}: ICodeInputProps) => {
  const [valArr, setValArr] = useState(Array(length).fill(''))
  const inputReferences = useRef<(HTMLInputElement | null)[]>([])
  useEffect(() => {
    if (
      inputReferences &&
      inputReferences.current.length &&
      inputReferences.current[0]
    ) {
      // eslint-disable-next-line
      // @ts-ignore
      inputReferences.current[0].focus()
    }
    // eslint-disable-next-line
  }, [])

  const handleChange = (index: number) => (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const val = event.target.value
    const valuesArray = [...valArr]
    valuesArray[index] = val
    setValArr(valuesArray)
    if (val !== '') {
      if (
        inputReferences &&
        inputReferences.current &&
        inputReferences.current.length > index + 1 &&
        inputReferences.current[index + 1]
      ) {
        // eslint-disable-next-line
        // @ts-ignore
        inputReferences.current[index + 1].focus()
      }
    }
    const valSend = valuesArray.join('')
    change(valSend, valSend.length === length)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.which === 13 && onEnter) {
      onEnter()
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault()
    const pasteValue = event.clipboardData.getData('Text')
    if (pasteValue) {
      const pasteArray = pasteValue.split('')
      const slicedArray =
        pasteArray.length > length
          ? pasteArray.slice(0, length)
          : [...pasteArray, ...Array(length - pasteArray.length).fill('')]
      setValArr(slicedArray)
      const computedLength =
        pasteArray.length >= length ? length - 1 : pasteArray.length
      if (
        inputReferences &&
        inputReferences.current &&
        inputReferences.current.length > computedLength
      ) {
        // eslint-disable-next-line
        // @ts-ignore
        inputReferences.current[computedLength].focus()
      }
      const valSend = slicedArray.join('')
      change(valSend, valSend.length === length)
    }
  }

  const handleKeyDown = (index: number) => (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    // Handle Delete-Actions Respectively
    if (event.keyCode === 8) {
      const valuesArray = [...valArr]
      // Field is already empty. Move to last field
      if (valuesArray[index] === '') {
        if (
          inputReferences &&
          inputReferences.current &&
          inputReferences.current.length > 0 &&
          index > 0 &&
          inputReferences.current[index - 1]
        ) {
          // eslint-disable-next-line
          // @ts-ignore
          inputReferences.current[index - 1].focus()
        }
      }
      // Field has content. Clear before moving
      else {
        valuesArray[index] = ''
        setValArr(valuesArray)
        const valSend = valuesArray.join('')
        change(valSend, valSend.length === length)
      }
    }
  }

  return (
    <Container
      aria-label={`Use the next ${length} input fields to add the code`}
      dir='ltr'
      style={{ direction: 'ltr !important' as any }}
    >
      {valArr.map((box, i) => (
        <Input
          ref={(ref) => inputReferences.current.push(ref)}
          value={box}
          onPaste={handlePaste}
          key={i}
          name={`${name}-${i}`}
          type='tel'
          onChange={handleChange(i)}
          onKeyPress={handleKeyPress}
          onKeyDown={handleKeyDown(i)}
          maxLength={1}
          small={length === 6}
        />
      ))}

      {secondaryAction && (
        <Caption>
          <FormattedMessage
            id='components.code.enterVerification'
            values={{ email }}
          />
          <br />
          <FormattedMessage id='components.code.error' />
          <TryAgainButton onClick={secondaryAction}>
            <FormattedMessage id='components.code.tryAgain' />.
          </TryAgainButton>
        </Caption>
      )}
    </Container>
  )
}

export { CodeInput }
