import React, { useEffect, useContext } from 'react'
import { AddOrganisationHeader } from '../../../components'
import Organisation from './Organisation'
import PendingRequest from './PendingRequest'
import useSWR from 'swr'
import { apiService } from '../../../service/api/apiService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { useIntl } from 'react-intl'
import { TopLoadingBarContext } from '../../../utils/ContextWrapper'

const Organizations = () => {
  const intl = useIntl()
  const [user] = useLocalStorage<IUser>('user')
  const { data, error } = useSWR(
    !!user ? ['organisations', user.id] : null,
    (key: string, userId: string) =>
      user ? apiService.user.organisations(userId) : null,
  )
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)

  const { data: pendingRequests } = useSWR(
    !!user ? ['pendingRequests', user.id] : null,
    (key: string, userId) => apiService.user.pendingRequests(userId),
  )

  useEffect(() => {
    if (!data || !pendingRequests) {
      setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
    } else if ((data && pendingRequests) || error) {
      setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
    }
  }, [data, error, setTopLoadingBar, pendingRequests])

  if (error) {
    return null
  }
  if (!data || !pendingRequests) {
    return null
  }

  return (
    <>
      {data.map((organisation) => {
        return (
          <Organisation key={organisation.id} organisation={organisation} />
        )
      })}
      {pendingRequests.map((pendingRequest) => {
        return (
          <PendingRequest
            pendingRequest={pendingRequest}
            key={pendingRequest.id}
          />
        )
      })}
      <AddOrganisationHeader
        title={intl.formatMessage({ id: 'dashboard.labels.addOrg' })}
      />
    </>
  )
}

export default React.memo(Organizations)
