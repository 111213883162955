import React, { useState } from 'react'
import { Slide } from '@material-ui/core'
import styled from 'styled-components'
import { IconButton } from '../IconButton/IconButton'
import { ReactComponent as Remove } from '../../assets/icons/other/icon_plus.svg'
import { ReadOnly } from '../ReadOnly/ReadOnly'

const ChipSlideContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media (max-width: 700px), (max-height: 520px) {
    flex-wrap: nowrap;
    overflow: auto;
    width: 300px;
  }
`

const StyledIconButton = styled(IconButton)`
  svg,
  path {
    @media (max-width: 700px), (max-height: 520px) {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  transform: rotate(45deg);
  @media (max-width: 700px), (max-height: 520px) {
    margin: 0;
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    min-height: 2rem;
  }
`

const Chip = styled.div`
  height: 3rem;
  background: var(--background);
  border: 1px solid var(--primary);
  color: var(--primary);
  padding: ${({ theme }) => theme.spacing.sm};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > button,
  > span {
    margin: 0 ${({ theme }) => theme.spacing.sm};
  }
  margin: ${({ theme }) => theme.spacing.sm};
`

interface IChipSlideProps {
  label?: string
  chips: string[]
  change: (value: string[], status: boolean) => void
  editable?: boolean
}
const ChipSlide = ({ chips, change, editable, label }: IChipSlideProps) => {
  const [deletedChip, setDeletedChip] = useState('')

  const onDeleteStart = (index: number) => {
    setDeletedChip(chips ? chips[index] : '')
  }

  const onDelete = (index: number) => {
    const valArr = chips ? [...chips] : []
    valArr.splice(index, 1)
    change(valArr, true)
    setDeletedChip('')
  }

  if (!chips.length && !editable) {
    return <ReadOnly value={null} fieldName={label} />
  }
  return (
    <ChipSlideContainer>
      {chips &&
        chips.map((chip, i) => (
          <Slide
            data-cy={chip}
            direction='left'
            in={chip !== deletedChip}
            onExited={() => onDelete(i)}
            key={chip}
            timeout={{ enter: 500, exit: 500 }}
          >
            {editable ? (
              <Chip>
                <span>{chip}</span>
                <StyledIconButton
                  type='button'
                  title='Remove'
                  onClick={() => onDeleteStart(i)}
                >
                  <Remove title='Remove' />
                </StyledIconButton>
              </Chip>
            ) : (
              <Chip>{chip}</Chip>
            )}
          </Slide>
        ))}
    </ChipSlideContainer>
  )
}

export default ChipSlide
