import React, { useEffect, useContext } from 'react'
import { LocaleContext } from '../App'
import { useLocation } from 'react-router'

interface Props {
  children: React.ReactNode
}
const TranslationWrapper = ({ children }: Props) => {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const lang = params.get('lang')

  const [, setLocale] = useContext(LocaleContext)

  useEffect(() => {
    if (lang === 'ar' || lang === 'en') setLocale(lang)
  }, [lang, setLocale])

  return <>{children}</>
}

export default TranslationWrapper
