import styled from 'styled-components'

export const TextContainer = styled.div`
  width: 100%;
`

export const EmailContainer = styled.div`
  @media (max-width: $mobileRes) {
    margin: 0rem 1rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CommonText = styled.p`
  @media (max-width: $mobileRes) {
    margin: 0rem 1rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const Text = styled(CommonText)`
  max-width: 480px;
  margin-bottom: 4rem;
`

export const CurrentEmail = styled(CommonText)`
  margin-top: 4rem;
`

export const EmailSpan = styled.span`
  font-weight: bold;
`

export const Link = styled.span`
  text-decoration: underline;
  color: var(--accent);
  font-weight: bold;
  cursor: pointer;
`
