import { axiosIdentity } from './axiosInstances'
import { AxiosResponse } from 'axios'
import { ICity } from '../../models/ICity'

interface ICitiesParameters {
  searchString: string
  matchStart?: boolean
  resultSize?: number
  sortType?: 'population' | 'name'
}
export const getCities = ({
  searchString,
  matchStart = true,
  resultSize = 10,
  sortType = 'population',
}: ICitiesParameters): Promise<AxiosResponse<ICity[]>> => {
  return axiosIdentity.get(
    `/cities?city=${searchString}&matchStart=${matchStart}&limit=${resultSize}&sortType=${sortType}`,
  )
}

export interface INationality {
  name: string
}

export const getNationalities = (): Promise<AxiosResponse<INationality[]>> => {
  return axiosIdentity.get(`/nationality`)
}
