import React from 'react'
import { Button } from './styles'
import { FilledButtonInterface } from './types'

const FilledButton = ({
  children,
  btnType = 'default',
  ...props
}: FilledButtonInterface) => (
  <Button btnType={btnType} {...props}>
    {children}
  </Button>
)

export { FilledButton }
