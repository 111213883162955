import React, { lazy, Suspense, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FilledButton } from '../../../../components'
import { TfaContext } from './TwoFactorAuth'
import { Caption, Container, PasswordContainer } from './styles'
import { IGeneralTFAProps } from './types'

const Password = lazy(() => import('../../../../components/TextInput/Password'))

const GeneralTFA = ({ isRegister, onEnter, onCancel }: IGeneralTFAProps) => {
  const intl = useIntl()
  const [{ isEnabled, password }, setTfaState] = useContext(TfaContext)
  const handleChange = (value: string) => {
    setTfaState((state) => ({ ...state, password: value }))
  }

  return (
    <Container>
      <Caption>
        {isEnabled ? (
          <FormattedMessage id='settings.twofactor.enabledCaption' />
        ) : (
          <FormattedMessage id='settings.twofactor.caption' />
        )}
      </Caption>
      <PasswordContainer>
        <Suspense fallback='Loading...'>
          <Password
            placeholder={intl.formatMessage({
              id: 'auth.placeholder.password',
            })}
            password={password}
            handlePasswordChange={handleChange}
          />
        </Suspense>
      </PasswordContainer>
      {isRegister && (
        <FilledButton onClick={onCancel}>
          <FormattedMessage id='settings.twofactor.skip' />
        </FilledButton>
      )}
    </Container>
  )
}

export default GeneralTFA
