import 'rc-notification/assets/index.css'
import React from 'react'
import Info from '@material-ui/icons/InfoOutlined'
import Check from '@material-ui/icons/CheckCircleOutline'
import Cancel from '@material-ui/icons/CancelOutlined'
import { ReactComponent as Add } from '../../assets/icons/other/icon_plus.svg'
import { CloseBtn, Content, ToastContainer } from './styles'
import { ToastContentProps } from './types'

const ToastContent = ({
  variant,
  message,
  children,
  onClose,
}: ToastContentProps) => {
  const Logo =
    variant === 'success' ? Check : variant === 'failure' ? Cancel : Info

  return (
    <ToastContainer variant={variant}>
      <Logo />
      <Content>{children || message}</Content>
      <div>
        {onClose && (
          <CloseBtn variant={variant} onClick={onClose}>
            <Add title='Close' />
          </CloseBtn>
        )}
      </div>
    </ToastContainer>
  )
}

export { ToastContent }
