const platforms = [
  {
    id: 'area-app',
    website:
      process.env.REACT_APP_AREA_2071_FRONTEND_URL ||
      'https://www.area2071.ae/app',
  },
  {
    id: 'area-web',
    website:
      process.env.REACT_APP_AREA_ADMIN_FRONTEND_URL ||
      'https://admin.app.area2071.ae/#/login',
  },
  {
    id: 'programs',
    website: `${
      process.env.REACT_APP_DFF_REDIRECT_URI ||
      'https://www.dubaifuture.ae'
    }/programs/`,
  },
  {
    id: 'admin',
    website:
      process.env.REACT_ADMIN_FRONTEND_URL ||
      'https://admin.id.dubaifuture.gov.ae/',
  },
]

export default platforms
