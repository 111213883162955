import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CodeInput } from '../../../../components'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../../models/IUser'
import { TfaContext } from './TwoFactorAuth'
import { apiService } from '../../../../service/api/apiService'
import { openToast } from '../../../../components/ToastMessage/ToastService'
import { Steps } from './constants'
import { DisableContentProps } from './types'
import { Caption, CodeInputContainer, Container } from './styles'

const DisableContent = ({ onCancel }: DisableContentProps) => {
  const [user] = useLocalStorage<IUser>('user')
  const intl = useIntl()
  const [{ password }, setTfaState] = useContext(TfaContext)

  const onChange = async (token: string, status: boolean) => {
    if (status && user && user.email) {
      try {
        await apiService.user.disableTfa({ password, token })
        openToast({
          variant: 'success',
          message: intl.formatMessage({
            id: 'settings.twofactor.disableSuccess',
          }),
        })
        setTfaState((state) => ({
          ...state,
          isEnabled: false,
          step: Steps.GENERAL,
          otp: '',
          password: '',
          qr: '',
        }))
      } catch (e) {
        openToast({
          variant: 'failure',
          message: intl.formatMessage({
            id: 'settings.twofactor.disableFailure',
          }),
        })
      }
      onCancel && onCancel()
    }
  }

  return (
    <Container>
      <Caption>
        <FormattedMessage id='settings.twofactor.enterCodeDisable' />
      </Caption>
      <CodeInputContainer>
        <CodeInput name='twoFactorVerify' length={6} change={onChange} />
      </CodeInputContainer>
    </Container>
  )
}

export default DisableContent
