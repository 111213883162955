import { TooltipRenderProps } from 'react-joyride'
import {
  Button,
  ProgressButton,
  TooltipBody,
  TooltipContent,
  TooltipFooter,
} from './styles'
import { FormattedMessage } from 'react-intl'
import React from 'react'

export const Tooltip = ({
  continuous,
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
}: TooltipRenderProps) => {
  return (
    <TooltipBody {...tooltipProps}>
      {step.content && <TooltipContent>{step.content}</TooltipContent>}
      <TooltipFooter>
        <ProgressButton btnType='primary' {...primaryProps}>
          <FormattedMessage id='dashboard.tutorial.nextStepButton' />
        </ProgressButton>
        {!isLastStep && (
          <Button {...skipProps}>
            <FormattedMessage id='dashboard.tutorial.skipTutorialButton' />
          </Button>
        )}
      </TooltipFooter>
    </TooltipBody>
  )
}
