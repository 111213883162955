import styled from 'styled-components'
import { IconContainerProps } from './types'
import { colors } from './constants'

export const IconButton = styled.button`
  display: flex;
  padding: 5px;
  background: none;
  border: none;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto;
  & * {
    user-select: none !important;
  }
`
export const Notifications = styled.div`
  color: var(--background);
  padding: 0 ${({ theme }) => theme.spacing.xs};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.md};
  @media only screen and (max-width: 700px), (max-height: 520px) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`
export const Label = styled.div<{ state?: string }>`
  color: var(--grey);
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  margin-top: 1.5rem;
  text-align: center;
  color: ${({ state }) => (state ? colors[state] : colors.default)};
  @media only screen and (max-width: 700px), (max-height: 520px) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`
export const IconContainer = styled.div<IconContainerProps>`
  border: ${({ state }) => `3px solid ${colors[state]}`};
  border-radius: 50%;
  background-color: var(--background);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  width: 8rem;
  height: 8rem;

  ${({ state }) => {
    if (state === 'disabled') {
      return `
        cursor: not-allowed;
        pointer-events: none;
      `
    }
  }}

  svg {
    width: 5rem;
    height: 5rem;
    fill: ${({ state }) => colors[state]};
    path,
    g {
      fill: ${({ state }) => colors[state]};
      stroke: ${({ state }) => colors[state]};
    }
  }
  @media only screen and (max-width: 700px), (max-height: 520px) {
    border-width: 2px;
    width: 6rem;
    height: 6rem;
    svg {
      width: 2.5rem;
    }
  }
`
export const CheckIcon = styled.div`
  min-width: 2.6rem;
  height: 2.6rem;
  background: var(--primary);
  position: absolute;
  left: 0;
  top: -5px;
  border-radius: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    filter: none;
    width: 1.6rem;
    height: 1.6rem;
    path {
      stroke-width: 5px;
      stroke: var(--background);
    }
  }
  @media only screen and (max-width: 700px), (max-height: 520px) {
    min-width: 1.8rem;
    height: 1.8rem;
    svg {
      width: 1rem;
    }
  }
`
