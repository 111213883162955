import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const Description = styled.p`
  margin-bottom: ${({ theme }) => theme.spacing.lg};
  color: var(--primary);
  a {
    font-weight: bold;
    color: var(--primary);
  }
  text-align: center;
`
