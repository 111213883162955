import React, { useContext } from 'react'
import { LocaleContext } from '../../../App'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import { OverlayContext } from '../../../utils/ContextWrapper'
import { apiService } from '../../../service/api/apiService'
import { mutate } from 'swr'
import { LanguageBtn } from './styles'

const LanguageButton = () => {
  const [locale, setLocale] = useContext(LocaleContext)
  const [user] = useLocalStorage<IUser>('user')
  const [isOverlayOpen] = useContext(OverlayContext)

  const updateLanguage = async () => {
    const nextLanguageCode = locale === 'en' ? 'ar' : 'en'

    if (user) {
      const data = await apiService.user.update(user?.id ?? '', {
        preferredLanguage: nextLanguageCode ?? '',
      })
      mutate('user', data, false)
    }
    setLocale(nextLanguageCode || 'en')
  }

  return (
    <LanguageBtn tabIndex={isOverlayOpen ? -1 : 0} onClick={updateLanguage}>
      {locale === 'ar' ? 'EN' : 'ع'}
    </LanguageBtn>
  )
}

export default LanguageButton
