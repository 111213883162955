import {
  axiosIdentity,
  axiosCookieAuth,
  axiosNoAuth,
  axiosAuth,
} from './axiosInstances'
import { AxiosResponse } from 'axios'
import { IUser } from '../../models/IUser'

export const authorize = (appId?: any, next?: any) => {
  return axiosAuth
    .post(`/auth/authorize?app_id=${appId}&next=${next}&redirectObject=true`)
    .then((response: AxiosResponse<{ redirectUri: string }>) => {
      const { data } = response
      return data.redirectUri
    })
}

export const getMandatoryProfileFields = (appId: string) => {
  return axiosIdentity
    .get(`/clientapps/${appId}/userFields?constraint=mandatory`)
    .then((response) =>
      response.data.map((f: any) => f.name).filter((f: string) => f !== 'id'),
    )
}

export const submitBackupCode = (
  backupCode: string,
  password: string,
  email: string,
) => {
  return axiosNoAuth.post('/backup/disable', { backupCode, password, email })
}

export const login = (email: string, password: string, token?: string) => {
  const payload: { email: string; password: string; token?: string } = {
    email,
    password,
  }
  if (token) payload.token = token
  // Needs to set cookie
  return axiosCookieAuth
    .post('/auth/login', { ...payload })
    .then((response: AxiosResponse<{ token: string; user: IUser }>) => {
      return response.data
    })
}

export const logout = async () => {
  return axiosAuth.post('/auth/logout')
}

export const register = (
  email: string,
  password: string,
  appId?: string,
  next?: string,
) => {
  return axiosNoAuth
    .post('/auth/register', {
      email,
      password,
      app_id: appId || undefined,
      next: next || undefined,
    })
    .then((response) => {
      return response.data
    })
}

export const resetPassword = (token: string, newPassword: string) => {
  return axiosNoAuth.post(
    '/auth/resetPassword',
    { newPassword },
    {
      headers: {
        token,
      },
    },
  )
}

export const sendResetPasswordEmail = (email: string) => {
  return axiosNoAuth.post('/auth/sendPasswordResetMail', { email })
}

export const updatePassword = (
  currentPassword: string,
  newPassword: string,
) => {
  return axiosAuth.patch(`/auth/password`, { currentPassword, newPassword })
}

export const verifyCode = (email: string, verifyCode: string) => {
  // Needs to set cookie
  return axiosCookieAuth
    .post('/auth/verifyCode', {
      email,
      verificationCode: +verifyCode,
    })
    .then((response: AxiosResponse<{ token: string; user: IUser }>) => {
      return response.data
    })
}

export const verifyEmail = (token: string) => {
  return axiosNoAuth
    .get('/auth/verifyEmail', {
      headers: {
        token,
      },
    })
    .then(
      (response: AxiosResponse<{ token: string; user: IUser }>) =>
        response.data,
    )
}

export const resendVerifyEmail = (email: string) => {
  return axiosNoAuth
    .post('/auth/sendEmailVerificationEmail', { email })
    .then((response) => {
      if (response && response.data) {
        return response.data
      }
    })
}
