import styled from 'styled-components'

export const LinkContainer = styled.div`
  color: var(--primary);
  height: 7rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;
`
export const LinkButton = styled.button`
  border: none;
  color: var(--primary);
  background: transparent;

  font-size: $font-mini;
  margin-top: 1.1rem;
`
export const Link = styled.span`
  text-decoration: underline;
  color: var(--accent);
  font-weight: bold;
  cursor: pointer;
`
