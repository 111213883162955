import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useMediaQuery } from '@material-ui/core'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { MenuLinksProps } from './types'
import { ExternalLink, MenuButton, MenuLink } from './styles'

const MenuLinks = ({ onLogout, onInvite, onShare }: MenuLinksProps) => {
  const [user] = useLocalStorage<IUser>('user')
  const isMobile = useMediaQuery('only screen and (max-width: 480px)')

  return (
    <>
      {user && (
        <>
          <ExternalLink
            href={`${
              process.env.REACT_APP_ID_FRONTEND_URL ||
              'https://id.dubaifuture.ae'
            } `}
            style={{ textAlign: 'left !important' as any }}
          >
            <FormattedMessage id='header.myProfile' />
          </ExternalLink>
          <ExternalLink
            href={`${
              process.env.REACT_APP_PROGRAMS_FRONTEND_URL ||
              'https://programs.dubaifuture.ae/'
            }`}
            style={{ textAlign: 'left !important' as any }}
          >
            <FormattedMessage id='header.programs' />
          </ExternalLink>
        </>
      )}

      <ExternalLink
        style={{ textAlign: 'left !important' as any }}
        href={`${process.env.REACT_APP_DFF_REDIRECT_URI}/future-id`}
      >
        <FormattedMessage id='header.about' />
      </ExternalLink>
      {!user ? (
        <MenuLink to='/login' style={{ textAlign: 'left !important' as any }}>
          <FormattedMessage id='header.auth' />
        </MenuLink>
      ) : (
        <>
          <MenuButton
            onClick={onInvite}
            style={{ textAlign: 'left !important' as any }}
          >
            <FormattedMessage id='header.invite' />
          </MenuButton>
          {isMobile && (
            <MenuButton
              onClick={onShare}
              style={{ textAlign: 'left !important' as any }}
            >
              <FormattedMessage id='header.share' />
            </MenuButton>
          )}
          <MenuButton
            onClick={onLogout}
            style={{ textAlign: 'left !important' as any }}
          >
            <FormattedMessage id='header.logout' />
          </MenuButton>
        </>
      )}
    </>
  )
}

export default MenuLinks
