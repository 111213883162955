import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { IDecisionItemProps } from './types'
import {
  DecisionItemContainer,
  DecisionItemDescription,
  DetailedDescription,
  IconButton,
  Info,
  OuterContainer,
} from './styles'

const DecisionItem = ({ Icon, description, claims }: IDecisionItemProps) => {
  const [showDetail, setShowDetail] = useState(false)
  const intl = useIntl()

  const handleClick = () => setShowDetail((detail) => !detail)
  return (
    <OuterContainer expanded={showDetail && !!claims?.length}>
      <DecisionItemContainer>
        <Icon />
        <DecisionItemDescription>{description}</DecisionItemDescription>
        <IconButton onClick={handleClick}>
          <Info />
        </IconButton>
      </DecisionItemContainer>
      <DetailedDescription expanded={showDetail && !!claims?.length}>
        {intl
          .formatMessage({ id: 'components.decision.accessDescription' })
          .toString()}
        {claims?.reduce(
          (prev, curr) => prev + (prev !== '' ? ', ' : '') + curr,
          '',
        )}
      </DetailedDescription>
    </OuterContainer>
  )
}

export default DecisionItem
