import React, { useEffect, useState } from 'react'
import RoleSelect from './RoleSelect'
import { ReactComponent as Cross } from '../../assets/icons/other/icon_plus.svg'
import { ReactComponent as Check } from '../../assets/icons/other/icon_check.svg'
import { FormattedMessage, useIntl } from 'react-intl'
import { isEmailValid } from '../../utils/validation'
import { FilledButton } from '../FilledButton/FilledButton'
import { openToast } from '../ToastMessage/ToastService'
import { apiService } from '../../service/api/apiService'
import { AcceptButton, DeleteButton } from './StyledComponents'
import {
  ActionIconContainer,
  AddContainer,
  Description,
  OuterAddContainer,
} from './styles'
import { IAddMemberProps } from './types'

const AddMember = ({
  organisationId,
  email,
  setEmail,
  setIsAdding,
  unknownEmail,
  onSubmit,
}: IAddMemberProps) => {
  const intl = useIntl()
  const [role, setRole] = useState({
    value: 'member',
    label: intl.formatMessage({ id: 'components.members.member' }),
  })
  const [existingEmail, setExistingEmail] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleRoleChange = (newRole: { value: string; label: string }) => {
    setRole(newRole)
  }

  const handleSubmit = (isAdmin: boolean) => () => {
    onSubmit(email, isAdmin)
    setEmail('')
  }

  const handleCancel = () => {
    setIsAdding(false)
    setEmail('')
  }

  useEffect(() => {
    if (unknownEmail) {
      setLoading(true)
      apiService.auth
        .checkEmail(email)
        .then((res) => {
          setExistingEmail(true)
        })
        .catch((e) => {
          setExistingEmail(false)
        })
        .finally(() => setLoading(false))
    }
  }, [unknownEmail, email])

  const handleInvite = async () => {
    try {
      await apiService.organisation.invite(organisationId, email)
      openToast({
        variant: 'success',
        message: intl.formatMessage({
          id: 'components.members.inviteSuccess',
        }),
      })
    } catch (e) {
      openToast({
        variant: 'failure',
        message: intl.formatMessage({ id: 'errors.genericFail' }),
      })
    } finally {
      setEmail('')
    }
  }

  if (loading) return null

  const isValid = isEmailValid(email)

  return isValid ? (
    <OuterAddContainer>
      {existingEmail ? (
        <>
          <Description>
            <FormattedMessage id='components.members.newMember' />
          </Description>
          <Description>
            <FormattedMessage id='components.members.awarenessWarning' />
          </Description>

          <AddContainer>
            <RoleSelect role={role} changeRole={handleRoleChange} />
            <ActionIconContainer>
              <DeleteButton
                aria-label={`Delete member (${email})`}
                onClick={handleCancel}
              >
                <Cross title='Cancel' />
              </DeleteButton>
              <AcceptButton
                aria-label={`Add member (${email})`}
                disabled={!isEmailValid(email)}
                onClick={handleSubmit(role.value === 'admin')}
              >
                <Check title='Add' />
              </AcceptButton>
            </ActionIconContainer>
          </AddContainer>
        </>
      ) : (
        <>
          <FilledButton onClick={handleInvite}>
            <FormattedMessage id='components.members.invite' />
          </FilledButton>
        </>
      )}
    </OuterAddContainer>
  ) : (
    <FormattedMessage id='components.members.invalid' />
  )
}

export default AddMember
