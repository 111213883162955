import styled from 'styled-components'
import { OuterContainer } from '../../../components/Members/styles'

export const ImageContainer = styled.div`
  min-width: 8rem;
  min-height: 8rem;
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 50%;
  margin: 0.5rem;
  box-sizing: border-box;
  border: 2px solid var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  svg,
  path {
    fill: var(--primary);
    width: 5rem;
    height: 5rem;
  }
  @media (max-width: 480px) {
    min-width: 40px;
    min-height: 40px;
    width: 40px;
    height: 40px;
  }
`
export const DevicesOuterContainer = styled(OuterContainer)`
  margin: ${({ theme }) => theme.spacing.lg} 0;
`
