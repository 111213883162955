import styled from 'styled-components'
import React from 'react'

export const customStyles: any = {
  group: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
    height: 50,
    borderColor: 'var(--primary)',
    border: '1px solid var(--primary)',
    fontSize: 16,
    fontFamily: 'Neo-Sans',
  }),
  clearIndicator: (provided: React.CSSProperties) => ({
    ...provided,
    padding: 0,
    color: 'var(--primary)',
  }),
  dropdownIndicator: (provided: React.CSSProperties, { isRtl }: any) => ({
    ...provided,
    padding: 0,
    paddingRight: isRtl ? 0 : 5,
    paddingLeft: isRtl ? 5 : 0,
    color: 'var(--primary)',
  }),
  indicatorSeparator: (provided: React.CSSProperties) => ({
    ...provided,
    display: 'none',
  }),
  singleValue: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--primary)',
  }),
  option: (provided: React.CSSProperties, { isFocused, isSelected }: any) => ({
    ...provided,
    background: isSelected ? 'var(--primary)' : 'var(--background)',
    padding: 10,
    color: isSelected
      ? 'var(--background)'
      : isFocused
      ? 'var(--primary)'
      : 'var(--grey)',
  }),
  input: (provided: React.CSSProperties) => ({
    ...provided,
    color: 'var(--primary)',
  }),
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    borderRadius: 0,
  }),
  menuList: (provided: React.CSSProperties) => ({
    ...provided,
    background: 'var(--background)',
  }),
  menuPortal: (provided: React.CSSProperties) => ({
    ...provided,
    zIndex: 9999,
  }),

  control: (provided: React.CSSProperties, { isSelected }: any) => ({
    ...provided,
    borderRadius: 0,
    border: '1px solid var(--primary)',
    boxShadow: isSelected ? '0 0 0 1px var(--primary)' : '',
    height: 50,
    minWidth: 300,
    background: 'var(--background)',
  }),
}
export const DropdownContainer = styled.div`
  min-width: 200px !important;
`
