import React, { forwardRef, Ref, useContext } from 'react'
import Tabs from './Tabs'
import { TabContext } from '../../utils/ContextWrapper'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../models/IUser'
import { Container, InnerContainer } from './styles'
import { HeaderContainerProps } from './types'

const HeaderContainer = (
  { children, isNavbarScrolledDown, viewTabs }: HeaderContainerProps,
  ref: Ref<any>,
) => {
  const [activeTab, setActiveTab] = useContext(TabContext)
  const [user] = useLocalStorage<IUser>('user')
  const [token] = useLocalStorage('token')
  return (
    <Container ref={ref} isNavbarScrolledDown={isNavbarScrolledDown}>
      <InnerContainer isNavbarScrolledDown={isNavbarScrolledDown}>
        {children}
      </InnerContainer>
      {user && token && viewTabs && (
        <Tabs onSelect={setActiveTab} activeTab={activeTab} />
      )}
    </Container>
  )
}

export default forwardRef(HeaderContainer)
