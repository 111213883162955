import zxcvbn from 'zxcvbn'

// eslint-disable-next-line
const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const isEmailValid = (str: string) => emailRegexp.test(str)
export const isPasswordValid = (val: string) => val.indexOf(' ') < 0
export const checkPassword = (val: string) => {
  const { score } = zxcvbn(val)

  return score >= 1 && isPasswordValid(val)
}
