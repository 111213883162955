import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 90%;
  height: 100%;
  padding-bottom: ${({ theme }) => theme.spacing.md};

  @media only screen and (min-width: 800px) {
    flex-direction: row-reverse;
    align-items: center;
  }

  @media only screen and (min-width: 1300px) {
    width: 1300px;
  }
`
export const Panel = styled.div`
  @media only screen and (min-width: 800px) {
    flex: 1;
  }
`
export const PanelSeparator = styled.div`
  display: none;

  @media only screen and (min-width: 800px) {
    display: block;
    width: 50px;
  }
`
export const Title = styled.h1`
  font-family: 'NeoSans';
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 3rem;
  @media only screen and (min-width: 800px) {
    margin-top: 0;
  }

  @media only screen and (min-width: 1100px) {
    font-size: 40px;
    line-height: 60px;
  }
`
export const Description = styled.p`
  font-family: 'NeoSans';
  font-size: 14px;
  line-height: 22px;

  @media only screen and (min-width: 1100px) {
    margin-bottom: 3rem;
    font-size: 16px;
    line-height: 24px;
  }
`
export const Image = styled.img`
  width: 100%;
  height: 100%;
`
const Button = styled(FilledButton)`
  max-width: 100%;
  @media only screen and (min-width: 800px) {
    max-width: 20rem;
  }
`
export const MobileButton = styled(Button)`
  margin-top: 4rem;

  @media only screen and (min-width: 800px) {
    display: none;
  }
`
const DesktopButton = styled(Button)`
  display: none;
  min-width: 200px;

  @media only screen and (min-width: 800px) {
    display: inline-block;
  }
`
export const DesktopVisitButton = styled(DesktopButton)`
  margin-inline-end: 4rem;
`
