import React, { useContext } from 'react'
import { OverlayContext } from '../../../utils/ContextWrapper'
import useTheme from '../../../utils/hooks/useTheme'
import { ThemeBtn } from './styles'
import { detectColorScheme } from '../../../utils/detectColorScheme'
import { ReactComponent as SunIcon } from '../../../assets/icons/other/sun.svg'
import { ReactComponent as MoonIcon } from '../../../assets/icons/other/moon.svg'

const ThemeButton = () => {
  const [isOverlayOpen] = useContext(OverlayContext)
  const { toggleTheme, theme } = useTheme()
  return (
    <ThemeBtn
      tabIndex={isOverlayOpen ? -1 : 0}
      aria-label='Toggle Theme'
      onClick={toggleTheme}
    >
      {detectColorScheme(theme) === 'dark' ? <SunIcon /> : <MoonIcon />}
    </ThemeBtn>
  )
}

export default ThemeButton
