import styled from 'styled-components'
import { FilledButton } from '../FilledButton/FilledButton'
import { StylesDirectionType } from './types'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary);
  padding: ${({ theme }) => theme.spacing.lg};
  background: var(--background);
  justify-content: center;
  max-width: 30rem;
  min-width: 25rem;
  @media (max-width: 480px) {
    padding: ${({ theme }) => theme.spacing.sm};
  }
`
export const Title = styled.h1`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: normal;
  margin-top: ${({ theme }) => theme.spacing.xxl};
  @media (max-width: 480px) {
    margin-bottom: 2rem;
  }
`
export const Question = styled.p`
  font-size: ${({ theme }) => theme.fontSize.md};
  text-align: center;
  margin-bottom: 2rem;
`
export const ButtonContainer = styled.div<StylesDirectionType>`
  display: flex;
  flex-direction: ${({ direction }) =>
    direction === 'horizontal' ? 'row' : 'column'};
  margin-top: 3rem;
  max-width: 36rem;
  /* justify-content: space-around; */
  width: 100%;
`
export const StyledButton = styled(FilledButton)<StylesDirectionType>`
  flex: 1;
  margin: ${({ theme, direction }) =>
    `${direction === 'horizontal' ? '0px' : theme.spacing.sm} ${
      direction === 'vertical' ? '0px' : theme.spacing.sm
    }`};
`
