import React, { useState, useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FilledButton } from '../../components'
import { apiService } from '../../service/api/apiService'
import { openToast } from '../../components/ToastMessage/ToastService'
import { AuthFormView } from '../../components'
import {
  AuthDataContext,
  TopLoadingBarContext,
} from '../../utils/ContextWrapper'
import { Caption, GeneralContainer } from './styles'
import styled from 'styled-components'
import { CustomInput } from '../../components/TextInput/Input'
import { writeStorage } from '@rehooks/local-storage'

const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.xl};
  text-align: center;
`

const Form = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.md};
`

const BackupCode = () => {
  const [redirect, setRedirect] = useState('')
  const intl = useIntl()
  const [backupCode, setBackupCode] = useState('')
  const [{ email, password }, setAuthData] = useContext(AuthDataContext)
  const [, setTopLoadingBar] = useContext(TopLoadingBarContext)
  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setBackupCode(e.target.value)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setTopLoadingBar((state) => ({ ...state, start: true, end: false }))
    try {
      await apiService.auth.submitBackupCode(backupCode, password ?? '', email)
      openToast({
        variant: 'success',
        message: intl.formatMessage({
          id: 'auth.backupCode.disabledMessage',
        }),
      })
      try {
        const { token, user } = await apiService.auth.login(
          email,
          password ?? '',
        )
        writeStorage('token', token)
        writeStorage('user', user)
      } catch (e) {
        if (e && e.status === 401) {
          // unauthorized
          openToast({
            message: intl.formatMessage({
              id: 'errors.invalidLogin',
            }),
            variant: 'failure',
          })
        } else {
          openToast({
            message: intl.formatMessage({ id: 'errors.loginFail' }),
            variant: 'failure',
          })
        }
      } finally {
        setTopLoadingBar((state) => ({ ...state, start: false, end: true }))
      }
      setRedirect('/')
      setAuthData((authData) => ({ ...authData, email: '', password: '' }))
    } catch (e) {
      openToast({
        variant: 'failure',
        message: intl.formatMessage({ id: 'errors.backupCodeFail' }),
      })
    }
  }

  if (redirect) return <Redirect to={redirect} />

  return (
    <AuthFormView>
      <GeneralContainer>
        <Title>
          <FormattedMessage id='auth.labels.resetTfa' />
        </Title>
        <Caption>
          <FormattedMessage id='auth.backupCode.backupCodeMessage' />
        </Caption>
        <Form onSubmit={handleSubmit}>
          <CustomInput
            autoFocus
            key='backup-code'
            name='backup-code'
            placeholder={intl
              .formatMessage({ id: 'auth.placeholder.backupCode' })
              .toString()}
            type='text'
            onChange={handleCodeChange}
          />
          <ButtonContainer>
            <FilledButton type='submit'>
              <FormattedMessage id='common.next' />
            </FilledButton>
          </ButtonContainer>
        </Form>
      </GeneralContainer>
    </AuthFormView>
  )
}

export default BackupCode
