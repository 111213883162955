import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { removeLoginData } from '../../../service/localStorage/userStorage'
import { Redirect } from 'react-router-dom'
import { ConfirmOverlay } from '../../../components'
import { apiService } from '../../../service/api/apiService'
import { openToast } from '../../../components/ToastMessage/ToastService'
import { useLocalStorage } from '@rehooks/local-storage'
import { IUser } from '../../../models/IUser'
import Cookies from 'js-cookie'
import { Props } from './types'

const DeleteAccount = ({ handleClose, open }: Props) => {
  const intl = useIntl()
  const [redirect, setRedirect] = useState('')
  const [user] = useLocalStorage<IUser>('user')

  const deleteAccount = async () => {
    if (user && user.id) {
      const confirmation = window.confirm(
        intl.formatMessage({ id: 'settings.deleteAccount.finalConfirmation' }),
      )
      if (confirmation) {
        try {
          await apiService.user.delete(user.id)
          removeLoginData()
          Cookies.remove('fid-is-loggedin', {
            domain: process.env.REACT_APP_COOKIE_TOP_LEVEL_DOMAIN,
          })
          setRedirect('/')
          window.location.reload()
        } catch (e) {
          openToast({
            message: intl.formatMessage({ id: 'errors.deleteUserFail' }),
            variant: 'failure',
          })
        }
      }
    } else {
      openToast({
        message: intl.formatMessage({ id: 'errors.deleteUserFail' }),
        variant: 'failure',
      })
    }
  }

  if (redirect) return <Redirect to={redirect} />

  return (
    <ConfirmOverlay
      open={open}
      title={<FormattedMessage id='settings.deleteAccount.title' />}
      question={<FormattedMessage id='settings.deleteAccount.info' />}
      confirmTitle={
        <FormattedMessage id='settings.deleteAccount.confirmation' />
      }
      denyTitle={<FormattedMessage id='common.cancel' />}
      onConfirm={deleteAccount}
      onClose={handleClose}
    />
  )
}

export default DeleteAccount
