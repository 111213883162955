import React, { Suspense, useCallback, useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { FilledButton } from '..'
import { useMediaQuery } from '@material-ui/core'
import { Email } from '../TextInput/Email'
import Links from './Links'
import { AppContext, AuthDataContext } from '../../utils/ContextWrapper'
import {
  ButtonContainer,
  ContentContainer,
  CustomLink,
  ForgotPassword,
  Form,
  FutureID,
  LoginLinksContainer,
  LoginRegisterContainer,
  Password,
  PlatformContainer,
} from './styles'
import { LoginRegisterFormProps } from './types'

const LoginRegisterForm = ({
  loginForm,
  onChangeEmail,
  onChangePassword,
  disabled,
  stepLabels = [],
  currentIndex,
  onNextStep,
  isEmailDisabled,
  emailExists,
}: LoginRegisterFormProps) => {
  const intl = useIntl()
  const [{ email, password }] = useContext(AuthDataContext)
  const [{ authorizeData }] = useContext(AppContext)
  const { search } = useLocation()
  const isMobile = useMediaQuery('(max-width: 480px)')

  const platform = authorizeData?.appId
    ? intl.formatMessage({ id: `settings.platforms.${authorizeData?.appId}` })
    : ''

  const hasNextStep = currentIndex < stepLabels.length - 1
  const nextStep = hasNextStep ? stepLabels[currentIndex + 1] : undefined

  const handleSubmit = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (!disabled && nextStep) {
        onNextStep && onNextStep(nextStep)
      } else if (!disabled && !nextStep) {
        onNextStep && onNextStep(null, true)
      }
    },
    [disabled, nextStep, onNextStep],
  )

  return (
    <LoginRegisterContainer>
      <LoginLinksContainer>
        <CustomLink active={!loginForm} to={`/register${search}`}>
          <FormattedMessage id='auth.labels.register' />
        </CustomLink>
        <CustomLink active={loginForm} to={`/login${search}`}>
          <FormattedMessage id='auth.labels.login' />
        </CustomLink>
      </LoginLinksContainer>
      <ContentContainer>
        {authorizeData?.appId && (
          <PlatformContainer>
            <FormattedMessage
              id='auth.labels.access'
              values={{ platform: <b>&nbsp;{platform}&nbsp;</b> }}
            />
          </PlatformContainer>
        )}
        <FutureID>
          <FormattedMessage id='settings.platforms.futureID' />
        </FutureID>
        <Form onSubmit={handleSubmit}>
          <Email
            error={
              !loginForm && emailExists
                ? intl.formatMessage({ id: 'errors.emailExists' }).toString()
                : ''
            }
            disableDebounce={loginForm}
            placeholder={intl
              .formatMessage({ id: 'auth.placeholder.email' })
              .toString()}
            email={email}
            handleEmailChange={onChangeEmail}
            fluid
            disabled={isEmailDisabled}
          />
          <Suspense fallback='Loading...'>
            <Password
              key='password-login'
              placeholder={intl
                .formatMessage({ id: 'auth.placeholder.password' })
                .toString()}
              handlePasswordChange={onChangePassword}
              password={password}
              showPasswordStrength={!loginForm}
              fluid
            />
          </Suspense>
          {loginForm ? (
            <ForgotPassword to='/reset-password'>
              <FormattedMessage id='auth.labels.forgottenPassword' />
            </ForgotPassword>
          ) : (
            <Links />
          )}
          <ButtonContainer>
            <FilledButton
              fluid={isMobile}
              style={{ marginTop: 48, alignSelf: 'center' }}
              disabled={disabled}
              type='submit'
            >
              {loginForm ? (
                <FormattedMessage id='auth.labels.login' />
              ) : (
                <FormattedMessage id='auth.labels.register' />
              )}
            </FilledButton>
          </ButtonContainer>
        </Form>
      </ContentContainer>
    </LoginRegisterContainer>
  )
}

export { LoginRegisterForm }
