import {
  parseSocialMediaUrl,
  validateUrl,
} from '../components/SocialMedia/util'

interface IValidatorObj {
  type: string
  value: string
}

// eslint-disable-next-line
const regex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const numberRegex = /^[0-9\b]+$/

type Status = 'success' | 'error' | 'info' | 'warning'

const validate = ({ type, value }: IValidatorObj): Status | undefined => {
  if (value === '') {
    return undefined
  }

  switch (type) {
    case 'email': {
      if (regex.test(`${value}`)) {
        return 'success'
      }
      return 'error'
    }
    case 'socialMedia': {
      if (value.length >= 1) {
        const socialMedia = parseSocialMediaUrl(value)
        const validUrl = validateUrl(value)
        const validUser = socialMedia.network ? socialMedia.user : false
        const isLinkedIn = socialMedia.network === 'LinkedIn'
        if (!validUrl || !isLinkedIn || !validUser) {
          return 'error'
        }
        return 'success'
      }
      return
    }

    case 'url': {
      const validUrl = validateUrl(value)
      if (!validUrl) {
        return 'error'
      }
      return 'success'
    }

    case 'number': {
      if (numberRegex.test(`${value}`)) {
        return 'success'
      }
      return 'error'
    }

    default:
      return value !== '' ? 'success' : 'error'
  }
}

export default validate
