import React, { useState } from 'react'
import useSWR from 'swr'
import { getAllApps } from '../../service/connections'
import { IconLabelBtn, PlatformsOverlay, SectionHeader } from '../../components'
import { Section, SectionItem } from '../dashboard/Section'
import { useIntl } from 'react-intl'
import { TutorialSteps } from '../../components/Tutorial/tutorialTypes'
import { platformWithOverlay } from './constants'
import { IClientAccess } from '../../service/types'

const Platforms = () => {
  const intl = useIntl()
  const [platformOverlay, setPlatformOverlay] = useState(false)
  const [selectedPlatform, setSelectedPlatform] = useState<IClientAccess>()

  const { data: platforms } = useSWR('platforms', () => getAllApps())

  const handlePlatformSelect = (platform: IClientAccess) => {
    setPlatformOverlay(true)
    setSelectedPlatform(platform)
  }

  const handleClosePlatformOverlay = () => {
    setPlatformOverlay(false)
  }

  return (
    <>
      <SectionHeader
        title={intl
          .formatMessage({ id: 'settings.platforms.title' })
          .toString()}
        hideMobile
        id={TutorialSteps.PLATFORMS}
      />
      <Section>
        {platforms?.map((platform: IClientAccess, index: number) => (
          <SectionItem key={platform.uniqueID || index}>
            <IconLabelBtn
              completed={!!platform.userAccess?.accessLevel}
              iconFileName={`platforms/${platform.uniqueID}`}
              label={
                platform.uniqueID
                  ? intl.formatMessage({
                      id: `settings.platforms.${platform.uniqueID}`,
                    })
                  : ''
              }
              onClick={() => handlePlatformSelect(platform)}
            />
          </SectionItem>
        ))}
      </Section>
      {selectedPlatform &&
        platformWithOverlay.includes(selectedPlatform.uniqueID) && (
          <PlatformsOverlay
            open={platformOverlay}
            platform={selectedPlatform}
            onClose={handleClosePlatformOverlay}
          />
        )}
    </>
  )
}

export default Platforms
