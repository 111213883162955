import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import initializeSentry from './configureSentry'
import './App.css'
import Routes from './routing/Routing'
import { ThemeProvider } from 'styled-components'
import TranslationWrapper from './utils/TranslationWrapper'
import { IntlProvider } from 'react-intl'
import Helmet from 'react-helmet'
import { setLanguageCookie } from './utils/cookies'
import { language, messages, styledComponentsTheme } from './constants'

export const LocaleContext = React.createContext<
  [string, React.Dispatch<React.SetStateAction<string>>]
>(['en', () => 'en'])

const App = () => {
  const [locale, setLocale] = useState(
    ['en', 'ar'].includes(language) ? language : 'en',
  )
  useEffect(() => {
    setLanguageCookie(locale)
  }, [locale])

  useEffect(() => {
    const environment = process.env.NODE_ENV
    if (environment === 'production') {
      initializeSentry()
    }
  }, [])

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      <Router>
        <ThemeProvider theme={{ ...styledComponentsTheme }}>
          <IntlProvider
            defaultLocale='en'
            locale={locale}
            messages={messages[locale]}
          >
            <TranslationWrapper>
              <Helmet htmlAttributes={{ lang: locale }} />

              <Routes />
            </TranslationWrapper>
          </IntlProvider>
        </ThemeProvider>
      </Router>
    </LocaleContext.Provider>
  )
}

export default App
