import styled, { css, keyframes } from 'styled-components'
import {
  DropdownMenuProps,
  InnerContainerStyleProps,
  IsOpenProps,
  LinksContainerStyleProps,
  LogoProps,
  LogoWrapperProps,
} from './types'
import { Link } from 'react-router-dom'

export const Menu = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`
export const LinksContainer = styled.div<LinksContainerStyleProps>`
  padding: 0 2rem 2rem 2rem;
  display: none;
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '50px' : '70px'};
  width: 100vw;
  background: var(--background);
  @media only screen and (max-width: 700px) {
    display: block;
  }
  transform: ${({ isOpen }) =>
    isOpen ? 'translateX(0)' : 'translateX(-100%)'};
  transition: all 0.25s ease-in-out;
  z-index: 10;
  border-top: 1px solid var(--border);
`
export const DrawerBackground = styled.div<IsOpenProps>`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: none;
  @media only screen and (max-width: 700px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  }
`
export const ModeButtons = styled.div<IsOpenProps>`
  display: none;
  @media only screen and (max-width: 700px) {
    display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  }
  margin: 0 auto;
`
export const HamburgerIconContainer = styled.button<DropdownMenuProps>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 16px;
  z-index: 15;
  padding: 0;
  border: none;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
`
const barStyle = () => css`
  display: block;
  width: 20px;
  height: 2px;
`
export const HamburgerIcon = styled.span<DropdownMenuProps>`
  position: relative;
  float: right;
  margin: 6px 0;
  transition: background-color 0s ease;
  transition-delay: 0.1s;
  pointer-events: none;
  z-index: 9;
  ${barStyle}
  background: ${({ isOpen }) => (isOpen ? 'transparent' : 'var(--primary)')};

  &:before,
  :after {
    ${barStyle}
    background-color: var(--primary);

    content: '';
    position: absolute;
    transition: background-color 0.2s ease;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }

  &:before {
    top: -6px;
    animation-name: ${({ isOpen }) => (isOpen ? burgertop : burgertopBack)};
  }

  &:after {
    top: 6px;
    animation-name: ${({ isOpen }) =>
      isOpen ? burgerbottom : burgerbottomBack};
  }
`
const burgertop = keyframes`
  0% {
    top: -6px;
  }

  50% {
    top: 0;
    transform: rotate(0);
  }

  100% {
    top: 0;
    transform: rotate(45deg);
  }
`
const burgerbottom = keyframes`
  0% {
    top: 6px;
  }

  50% {
    top: 0;
    transform: rotate(0);
  }

  100% {
    top: 0;
    transform: rotate(-45deg);
  }
`
const burgertopBack = keyframes`
  0% {
    top: 0;
    transform: rotate(45deg);
  }

  50% {
    top: 0;
    transform: rotate(0);
  }

  100% {
    top: 6px;
  }
`
const burgerbottomBack = keyframes`
  0% {
    top: 0;
    transform: rotate(-45deg);
  }

  50% {
    top: 0;
    transform: rotate(0);
  }

  100% {
    top: -6px;
  }
`
export const DropdownMenu = styled.div<DropdownMenuProps>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  padding: 20px;
  position: absolute;
  background: var(--background);
  top: 5rem;
  left: 0;
  right: 4.9rem;
  border: 1px solid var(--border);
  z-index: 10;
  border-top: none;
  &:hover {
    display: flex;
  }
`
const buttonStyle = () => css`
  height: 5rem;
  min-width: 5rem;
  padding: 0;
  margin: 0 0 -1px;
  border-radius: 0;
  cursor: pointer;
  border: 1px solid var(--border);
  background: var(--background);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  &:first-child {
    border-right: none;
  }
  &:hover {
    background-color: var(--primary-transparent);
  }
`
export const ButtonLink = styled.a`
  ${buttonStyle}
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: var(--primary);
  text-decoration: none;
  font-size: 1.3rem;
  text-transform: uppercase;
  padding: 2rem;
  font-weight: 600;
  @media only screen and (max-width: 700px) {
    width: 100%;
    margin-top: 2rem;
  }
`
const Button = styled.button`
  ${buttonStyle}
`
export const ThemeBtn = styled(Button)`
  padding: 0;
  background: var(--primary);
  border: none;
  svg {
    overflow: visible;
    stroke-width: 0.6px;
  }
  path {
    fill: var(--background);
    stroke: var(--background);
  }
  @media only screen and (max-width: 700px) {
    max-width: 4rem;
    min-width: 4rem;
    height: 4rem;
  }
  &:hover {
    background-color: var(--primary);
  }
`
export const LanguageBtn = styled(Button)`
  color: var(--primary);
  @media only screen and (max-width: 700px) {
    max-width: 4rem;
    min-width: 4rem;
    height: 4rem;
    border-left: 1px solid var(--border);
  }
`
export const ProfileBtn = styled(Button)`
  border-left: none;
  path {
    fill: var(--primary);
  }
  &:hover + ${DropdownMenu} {
    display: flex;
  }
`
export const MenuTrigger = styled.div`
  display: none;
  @media only screen and (max-width: 700px) {
    display: flex;
  }
`
export const Container = styled.header<InnerContainerStyleProps>`
  color: var(--primary);
  transition: background-color 0.5s ease;
  width: 100%;
  background: var(--background);
  padding: 0 5rem;
  padding-top: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '0' : '3rem'};
  box-sizing: border-box;
  position: fixed;
  top: 0;
  z-index: 10;
  @media only screen and (max-width: 960px) {
    padding: 0 2rem;
  }
  @media only screen and (max-width: 700px) {
    padding: 0;
  }
`
export const InnerContainer = styled.div<InnerContainerStyleProps>`
  width: 100%;
  background: var(--background);
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
  position: relative;
  height: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '51px' : '81px'};
  transition: height 0.5s ease, background-color 0.5s ease;
  box-sizing: border-box;
  @media only screen and (max-width: 700px) {
    border: none;
    justify-content: space-between;
    box-sizing: border-box;
    height: ${({ isNavbarScrolledDown }) =>
      isNavbarScrolledDown ? '50px' : '70px'};
    padding: ${({ isNavbarScrolledDown }) =>
      isNavbarScrolledDown ? '10px 20px 10px' : '18px 20px 18px'};
  }
`
export const LogoWrapper = styled.div<LogoWrapperProps>`
  display: flex;
  align-items: center;
  padding-left: 0;
  margin: 0rem ${({ isMobile }) => (isMobile ? 'auto' : '')};
  margin-bottom: ${({ applyOffset }) => (applyOffset ? '30px' : '0')};

  & svg {
    transform: ${({ isRtl }) => (isRtl ? '' : 'scaleX(-1)')};
    width: ${({ applyOffset }) => (applyOffset ? '5rem' : '4rem')};
    height: ${({ applyOffset }) => (applyOffset ? '4rem' : '3rem')};
    @media only screen and (max-width: 960px) {
      display: none;
    }
  }
`
export const Logo = styled.img<LogoProps>`
  height: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '3rem' : '5rem'};
  transition: 0.3s height ease;
  filter: ${({ colorScheme }) => (colorScheme === 'dark' ? ` invert(1)` : '')};
  @media only screen and (max-width: 960px) {
    max-height: 3.4rem;
  }
`
export const BlockAnchor = styled.a`
  display: block;
`
export const FlexAnchor = styled.a`
  color: var(--primary);
  text-decoration: none;
  display: flex;
  align-items: center;
`
export const ReturnLabel = styled.span<{
  rtl?: boolean
  isNavbarScrolledDown?: boolean
}>`
  color: var(--primary);
  text-decoration: none;
  font-size: ${({ isNavbarScrolledDown }) =>
    isNavbarScrolledDown ? '1rem' : '1.3rem'};
  text-transform: uppercase;
  padding: 2rem 2rem 2rem 0;
  position: relative;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  transition: 0.3s font-size ease;
  @media only screen and (max-width: 960px) {
    display: none;
  }
`
const linkStyle = css`
  padding: 0;
  margin-bottom: 10px;
  line-height: 1.5;
  &:last-child {
    margin-bottom: 0;
  }
  font-size: 12px;
  font-weight: 700;
  color: var(--primary);
  font-family: Roboto, Helvetica, Arial, sans-serif;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
  border: none;
  background: var(--background);
  @media only screen and (max-width: 700px) {
    display: flex;
    padding: 1.7rem 0 1.6rem;
    border-bottom: 1px solid var(--border);
  }
`
export const ExternalLink = styled.a`
  ${linkStyle}
  white-space: nowrap;
  text-transform: uppercase;
`
export const MenuLink = styled(Link)`
  ${linkStyle}
  text-transform: uppercase;
`
export const MenuButton = styled.button`
  ${linkStyle}
  text-align: start;
  text-transform: uppercase;
`
export const Tabs = styled.div`
  width: 100%;
  display: none;
  height: 40px;
  padding: 0 2rem;
  box-sizing: border-box;
  border-bottom: 2px solid var(--grey);
  background: var(--background);
  @media only screen and (max-width: 700px) {
    display: flex;
  }
`
export const Tab = styled.div<{ active?: boolean }>`
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.active ? 'var(--primary)' : 'var(--grey)')};
  font-size: var(--font-small);
  font-weight: bold;
  position: relative;
  cursor: pointer;
  &:after {
    content: '';
    display: ${(props) => (props.active ? 'block' : 'none')};
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--primary);
  }
`
