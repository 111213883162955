import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Props } from './types'
import { Text } from './styles'

const ReadOnly = ({ value, fieldName }: Props) => {
  return (
    <Text>
      {value ? (
        <>{value}</>
      ) : fieldName ? (
        <>
          <FormattedMessage
            id='components.readonly.empty'
            values={{
              field: <FormattedMessage id={fieldName} />,
            }}
          />
        </>
      ) : (
        <FormattedMessage id='components.readonly.generic' />
      )}
    </Text>
  )
}

export { ReadOnly }
